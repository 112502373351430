import React, { useEffect, useState } from 'react';
import './MedicalBillModal.css'; // Add styles for MedicalBillModal
import { urls } from './config.dev'; // Import the URLs

function MedicalBillModal({ fileId, billData, onClose, token }) {
    const [processedData, setProcessedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [confirmingBill, setConfirmingBill] = useState(false);
    const [additionalRows, setAdditionalRows] = useState([]);
    const [isEditing, setIsEditing] = useState(true); // Track editing state
    useEffect(() => {
        console.log('Token received:', token);
    }, [token]);

    useEffect(() => {
        const fetchProcessedBillData = async () => {
            try {
                const response = await fetch(urls.calculatebill, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileId, billData }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setProcessedData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProcessedBillData();
    }, [fileId, billData]);

    const handleDiscountChange = (e) => {
        setDiscount(parseFloat(e.target.value));
    };

    const handleAddRow = () => {
        const defaultRow = { drug: '', packaging: '', unitPrice: 0, quantity: 0, totalPrice: 0 };
        setAdditionalRows([...additionalRows, { ...defaultRow }]);
    };
    
    const handleTotalChange = (index, e) => {
        const newRows = [...additionalRows];
        newRows[index].totalPrice = parseFloat(e.target.value) || 0;
        setAdditionalRows(newRows);
        updateTotalBill();
    };

    const updateTotalBill = () => {
        let additionalTotal = additionalRows.reduce((acc, row) => acc + row.totalPrice, 0);
        let processedTotal = processedData && Array.isArray(processedData.bill) ? processedData.bill.reduce((acc, item) => acc + item.totalPrice, 0) : 0;
        let totalBill = processedTotal + additionalTotal - discount;
        return totalBill;
    };

    const handleConfirmBill = async () => {
        if (window.confirm("Make sure you and the patient have clearly agreed on the payment structure and both of you are on mutual grounds. By clicking continue you confirm that the patient is willing to foot this bill.")) {
            setConfirmingBill(true);
            try {
                const response = await fetch(urls.confirmbill, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fileId,
                        totalBill: updateTotalBill(),
                        discount,
                        token // Include the token in the payload
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const responseData = await response.json();
                console.log(responseData); // Log the payload sent to confirmbill endpoint
    
                if (responseData.message === "Credit information updated successfully.") {
                    alert("Bill confirmed. Patient successfully added to the billing section. Please don't confirm the bill again as this will cause double billing.");
                    onClose(); // Close the modal
                }
    
            } catch (error) {
                // Handle error scenarios here
            } finally {
                setConfirmingBill(false);
            }
        }
    };
    
    if (loading) {
        return <div className="medical-bill-modal">Processing patient bill...</div>;
    }

    if (error) {
        return <div className="medical-bill-modal">Error: {error}</div>;
    }

    let totalBill = updateTotalBill();
    return (
        <div className={`medical-bill-modal ${!isEditing ? 'print-view' : ''}`}>
            <div className="medical-bill-scrollable-content">
                <span className="close-modal" onClick={onClose}>&#10005;</span>
                <h2 className="modal-heading">Medical Bill for {processedData.firstName} {processedData.lastName} (File ID: {fileId})</h2>
                {processedData && Array.isArray(processedData.bill) && (
                    <div className="medical-bill-table-container">
                        <table className="medical-bill-table">
                            <thead>
                                <tr>
                                    <th>Drug</th>
                                    <th>Packaging</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedData.bill.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.drug}</td>
                                        <td>{item.packaging}</td>
                                        <td>{item.unitPrice}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.totalPrice}</td>
                                    </tr>
                                ))}
                                {additionalRows.map((row, index) => (
                                    <tr key={index + processedData.bill.length}>
                                        <td>{isEditing ? <input className="table-input" value={row.drug} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].drug = e.target.value;
                                            setAdditionalRows(newRows);
                                        }} /> : row.drug}</td>
                                        <td>{isEditing ? <input className="table-input" value={row.packaging} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].packaging = e.target.value;
                                            setAdditionalRows(newRows);
                                        }} /> : row.packaging}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.unitPrice} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].unitPrice = parseFloat(e.target.value) || 0;
                                            setAdditionalRows(newRows);
                                        }} /> : row.unitPrice}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.quantity} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].quantity = parseInt(e.target.value) || 0;
                                            setAdditionalRows(newRows);
                                        }} /> : row.quantity}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.totalPrice} onChange={(e) => handleTotalChange(index, e)} /> : row.totalPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <button onClick={handleAddRow}>+</button>
                <div className="discount-section">
                    <label htmlFor="discount">Discount:</label>
                    <input
                        type="number"
                        id="discount"
                        name="discount"
                        value={discount}
                        onChange={handleDiscountChange}
                    />
                </div>
                <div className="total-bill-section">
                    <p>Total Bill: {totalBill}</p>
                </div>
                <div className="buttons-section">
                    <button onClick={() => {
                        setIsEditing(false); // Disable editing before printing
                        window.print();
                        setIsEditing(true); // Re-enable editing after printing
                    }}>Print Bill</button>
                    <button onClick={handleConfirmBill}>{confirmingBill ? 'Please Wait...' : 'Confirm Bill'}</button>
                </div>
            </div>
        </div>
    );
}

export default MedicalBillModal;
