import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './viewfile.css';
import TreatmentChatModal from './TreatmentChatModal';
import Topbar from './Topbar'; // Import the Topbar component

function ViewFiles() {
    const [fileData, setFileData] = useState(null);
    const [editableFields, setEditableFields] = useState({});
    const [showTreatmentChatModal, setShowTreatmentChatModal] = useState(false);
    const [urlToken, setUrlToken] = useState('');
    const treatmentChatModalRef = useRef(null);
    const signsAndSymptomsRef = useRef(null);
    const clinicalNotesRef = useRef(null);
    const diagnosisRef = useRef(null);
    const treatmentNotesRef = useRef(null);
    const treatmentPlanRef = useRef(null);
    const pathParts = window.location.pathname.split('/');
    const tokenFromUrl = pathParts[2]; // Assuming the token is the 3rd part of the path
    
    
    
    
    const { token, fileId } = useParams();
    const navigate = useNavigate();
    const [employeeName, setEmployeeName] = useState('');
    useEffect(() => {
        // Function to extract the token from the URL
        const extractToken = () => {
          try {
            // Get the full URL
            const url = window.location.href;
    
            // Extract the token from the URL (second-to-last segment)
            const extractedToken = url.split('/').slice(-2, -1)[0];
    
            // Set the token in the state
            setUrlToken(extractedToken);
          } catch (error) {
            console.error("Error extracting token from URL:", error);
          }
        };
    
        // Call the function to extract the token
        extractToken();
      }, []); // Run this effect once on component mount
    // Fetch Employee Name and Security Check
    useEffect(() => {
        const fetchEmployeeName = async () => {
            try {
                const response = await fetch(urls.security, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.message === 'Session valid') {
                        setEmployeeName(data.employee_name);
                        localStorage.setItem('token', data.clinic_session_token);
                    } else if (data.error === 'Session expired') {
                        navigate(`/dashboard?token=${data.clinic_session_token}`);
                    } else {
                        navigate('/login');
                    }
                } else {
                    throw new Error('Failed to perform security check');
                }
            } catch (error) {
                console.error('Error performing security check:', error);
                navigate('/login');
            }
        };

        fetchEmployeeName();
    }, [navigate, token]);

    // Fetch File Data at intervals
    useEffect(() => {
        const fetchFileData = () => {
            fetch(`${urls.viewfile}?fileId=${fileId}`)
                .then(response => response.json())
                .then(data => {
                    setFileData(data);
                    setEditableFields({
                        blood_pressure: data.fileData?.blood_pressure,
                        temperature: data.fileData?.temperature,
                        spo2: data.fileData?.spo2,
                        body_weight: data.fileData?.body_weight,
                        signs_and_symptoms: data.fileData?.signs_and_symptoms,
                        clinical_notes: data.fileData?.clinical_notes,
                        diagnosis: data.fileData?.diagnosis,
                        treatment_plan: data.fileData?.treatment_plan,
                        treatment_notes: data.fileData?.treatment_notes || '',
                    });
                })
                .catch(error => {
                    console.error('Error fetching file data:', error);
                });
        };

        fetchFileData();
        const interval = setInterval(fetchFileData, 5000);

        return () => clearInterval(interval);
    }, [fileId]);

    // Handle opening and closing of Treatment Chat Modal
    const handleOpenTreatmentChatModal = () => {
        setShowTreatmentChatModal(true);
        
    };
    

    const handleCloseTreatmentChatModal = () => {
        setShowTreatmentChatModal(false);
    };

    useEffect(() => {
        console.log('fileData changed', fileData);
        const adjustHeight = (ref) => {
            if (ref.current) {
                ref.current.style.height = 'auto';
                ref.current.style.height = `${ref.current.scrollHeight}px`;
            }
        };
    
        adjustHeight(signsAndSymptomsRef);
        adjustHeight(clinicalNotesRef);
        adjustHeight(diagnosisRef);
        adjustHeight(treatmentNotesRef);
        adjustHeight(treatmentPlanRef);
    }, [fileData]);
    

    // Navigate to Discharge and Referral forms
    const navigateToDischargeForm = () => {
        navigate(`/discharge-form/${employeeName}/${fileId}`);
    };

    const navigateToReferralForm = () => {
        navigate(`/referral-form/${employeeName}/${fileId}`);
    };

    const textAreaStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '20px',
        resize: 'none', // Disable manual resizing
        overflow: 'hidden', // Hide overflow to prevent scrolling
    };

    const buttonStyle = {
        marginLeft: '10px',
        backgroundColor: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '5px 10px',
        cursor: 'pointer',
    };
    const treatmentNotesTextAreaStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px', // Smaller font size
        resize: 'none',
        overflow: 'hidden',
        height: 'auto', // Ensure it adjusts based on content
    };
    
    return (
        <div className="view-file-container">
            <Topbar token={urlToken} />
            {fileData ? (
                <div className="file-details">
                    <h1>Date Time: {fileData.fileData?.date_time}</h1>
                    <div className="patient-details">
                        <p>Name: {fileData.fileData?.first_name} {fileData.fileData?.last_name}</p>
                        <p>Phone Number: {fileData.fileData?.phone_number}</p>
                        <p>Age: {fileData.fileData?.age}</p>
                        <p>Sex: {fileData.fileData?.sex}</p>
                        <p>Address: {fileData.fileData?.address}</p>
                    </div>
                    <div className="vitals">
                        <p>Blood Pressure: {fileData.fileData?.blood_pressure}</p>
                        <p>Temperature: {fileData.fileData?.temperature}</p>
                        <p>SPO2: {fileData.fileData?.spo2}</p>
                        <p>Body Weight: {fileData.fileData?.body_weight}</p>
                    </div>
                    <div className="signs-symptoms">
                        <h2>Presenting Complaints:</h2>
                        <div className="editable-field">
                            <textarea
                                ref={signsAndSymptomsRef}
                                defaultValue={editableFields.signs_and_symptoms}
                                disabled={true}
                                style={textAreaStyle}
                            />
                        </div>
                    </div>
                    <div className="clinical-notes">
                        <h2>Clinical Notes:</h2>
                        <div className="editable-field">
                            <textarea
                                ref={clinicalNotesRef}
                                defaultValue={editableFields.clinical_notes}
                                disabled={true}
                                style={textAreaStyle} // Use textAreaStyle here
                            />
                        </div>
                    </div>
                    <div className="diagnosis">
                        <h2>Diagnosis:</h2>
                        <div className="editable-field">
                            <textarea
                                ref={diagnosisRef}
                                defaultValue={editableFields.diagnosis}
                                disabled={true}
                                style={textAreaStyle}
                            />
                        </div>
                    </div>
                    <div className="treatment-plan">
                        <h2>Treatment Plan:</h2>
                        <div className="editable-field">
                            <textarea
                                ref={treatmentPlanRef}
                                defaultValue={editableFields.treatment_plan}
                                disabled={true}
                                style={textAreaStyle} // Use the specific style here
                            />
                        </div>
                    </div>
                    <div className="treatment-notes">
    <h2>Treatment Chat:</h2>
    <div className="editable-field">
        <textarea
            ref={treatmentNotesRef}
            defaultValue={editableFields.treatment_notes}
            disabled={true}
            style={treatmentNotesTextAreaStyle} // Use the specific style here
        />
        <button
            onClick={handleOpenTreatmentChatModal}
            style={buttonStyle}
        >
            Edit Treatment Chat
        </button>
    </div>
</div>

                    {showTreatmentChatModal && (
                        <div ref={treatmentChatModalRef} className="treatment-chat-modal open">
                            <div className="treatment-chat-modal-content">
                                <TreatmentChatModal
                                    fileId={fileId}
                                    employeeName={employeeName}
                                    onClose={handleCloseTreatmentChatModal}
                                    token={token}
                                />
                            </div>
                        </div>
                    )}
                    <div className="status">
                        <p>Lab Status: {fileData.labStatusMessage}</p>
                        <p>Radiology Status: {fileData.radiologyStatusMessage || 'Not available'}</p>
                    </div>
                </div>
            ) : (
                <p>Loading file data...</p>
            )}
           {/* <button onClick={navigateToDischargeForm}>Discharge Form</button>
            <button onClick={navigateToReferralForm}>Referral Form</button>*/}
        </div>
    );
}

export default ViewFiles;
