import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingState = () => {
  return (
    <div style={styles.overlay}>
      <div style={styles.loaderContainer}>
        <ClipLoader color="#36d7b7" />
        <p style={styles.loadingText}>Sending message, please wait...</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
  },
  loadingText: {
    marginTop: '10px',
    fontSize: '16px',
  },
};

export default LoadingState;
