import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './ClinicRegistration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import medicalTeamImage1 from './images/jonah1.jpg';
import medicalTeamImage2 from './images/jonah2.jpg';
import medicalTeamImage3 from './images/jonah1.jpg';
import medicalTeamImage4 from './images/jonah2.jpg';
import medicalTeamImage5 from './images/jonah1.jpg';
import JSEncrypt from 'jsencrypt';

// Replace YOUR_PUBLIC_KEY_HERE with the actual content of your public key
const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAup3FU135mAvJT6OheYW3
pQyWf6jvS4duUMY4cXrlJXyGqu8HqvTU0ewPy6w2HhCPxWboNclkAkPhOCc4URNT
x1Grg+mCsWmfhVimP2wtfmlBCJ09cyDMYf93iGj8RFf3CshY5yhppT/pX+RgTuXw
ClpOXe24CLG2VF9suNylk+ReAMLyOxaekYofAMBvvrD4+GYPJgvkTMXCXCKp2PnO
8+OjiltNMnoyqPEZoXHTV4EXtTrjYnwzSe0WZSSuzgVMhmtdx+IS4eisSumHV1eI
wBeZwI0bYGxDCedPRassmSFgTFqkkcgIXmEP1n5w/08S/QPr2G+myKTeRqp5RJA5
PQIDAQAB
-----END PUBLIC KEY-----`;

function ClinicRegistration() {
  const [formData, setFormData] = useState({
    clinicName: '',
    district: '',
    county: '',
    subCounty: '',
    town: '',
    yearOfOpening: '',
    numberOfEmployees: '',
    ownersNames: '',
    ownersAddress: '',
    ownersContact: '',
    ownersWhatsapp: '',
    referrer: '',
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(medicalTeamImage1);
  const [currentSlogan, setCurrentSlogan] = useState('Comprehensive Clinic Management');
  const navigate = useNavigate();

  const slogans = [
    'Comprehensive Clinic Management',
    'Streamlining Clinic Operations',
  ];

  const images = [
    medicalTeamImage1,
    medicalTeamImage2,
    medicalTeamImage3,
    medicalTeamImage4,
    medicalTeamImage5,
  ];

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 15000); // Change image every 15 seconds

    const sloganInterval = setInterval(() => {
      setCurrentSlogan(slogans[Math.floor(Math.random() * slogans.length)]);
    }, 15000); // Change slogan every 15 seconds

    return () => {
      clearInterval(imageInterval);
      clearInterval(sloganInterval);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if all required fields are filled
    const requiredFields = [
      'clinicName',
      'district',
      'county',
      'town',
      'yearOfOpening',
      'numberOfEmployees',
      'ownersNames',
      'ownersAddress',
      'ownersContact',
      'ownersWhatsapp',
      'password',
      'confirmPassword'
    ];
  
    for (const field of requiredFields) {
      if (formData[field].trim() === '') {
        toast.error('Please fill in all required fields.');
        return;
      }
    }
  
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }
  
    // Encrypt the password
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptedPassword = encrypt.encrypt(formData.password);
  
    const encryptedFormData = { ...formData, password: encryptedPassword, confirmPassword: encryptedPassword };
  
    setLoading(true);
    try {
      const response = await fetch(urls.registerClinic, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(encryptedFormData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        toast.success('Registration successful! Please wait as we redirect you to the login page.');
        
        // Wait for 5 seconds before redirecting
        setTimeout(() => {
          navigate('/login');
        }, 5000);
        
      } else {
        const responseData = await response.json();
        if (responseData.error === "Clinic name already exists.") {
          toast.error('Clinic Name is already taken, please choose another Clinic name.');
        } else {
          toast.error('Registration failed!');
        }
      }
    } catch (error) {
      toast.error('Registration failed!');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-left">
        <div className="login-box">
          <h2 className="login-title">Welcome to Clinic-Pro</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Clinic Name</label>
              <input type="text" name="clinicName" value={formData.clinicName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Clinic Contact</label>
              <input type="tel" name="ownersContact" value={formData.ownersContact} onChange={handleChange} pattern="[0-9]+" required />
            </div>
            <div className="form-group">
              <label>District</label>
              <input type="text" name="district" value={formData.district} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Country</label>
              <input type="text" name="county" value={formData.county} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Town</label>
              <input type="text" name="town" value={formData.town} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Year of Opening</label>
              <input type="number" name="yearOfOpening" value={formData.yearOfOpening} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Range of Number of Employees</label>
              <input type="number" name="numberOfEmployees" value={formData.numberOfEmployees} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Owners' Names</label>
              <input type="text" name="ownersNames" value={formData.ownersNames} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Owners' Address</label>
              <input type="text" name="ownersAddress" value={formData.ownersAddress} onChange={handleChange} required />
            </div>
            
            <div className="form-group">
              <label>Owners' WhatsApp Number</label>
              <input type="tel" name="ownersWhatsapp" value={formData.ownersWhatsapp} onChange={handleChange} pattern="[0-9]+" required />
            </div>
            <div className="form-group">
              <label>Set Password</label>
              <div className="password-input">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEyeSlash : faEye}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <div className="password-input">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <FontAwesomeIcon
                  icon={confirmPasswordVisible ? faEyeSlash : faEye}
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                />
              </div>
            </div>
            <div className="form-buttons">
              <button type="submit" disabled={loading}>
                {loading ? 'Please wait...' : 'Register'}
              </button>
              <button type="button" onClick={() => navigate('/login')}>
                Back to Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="login-right">
        <img src={currentImage} alt="Medical Team" className="background-image" />
        <div className="slogan">{currentSlogan}</div>
      </div>
    </div>
  );
}

export default ClinicRegistration;
