import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './GenerateDrugOrder.css'; // Import the CSS file

function GenerateDrugOrder() {
  const [days, setDays] = useState(7); // Default number of days
  const [drugs, setDrugs] = useState([]);
  const [error, setError] = useState(null);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const fetchDrugs = async (token) => {
    try {
      const response = await fetch(urls.creatdrugorder, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, days }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch drugs');
      }
      const data = await response.json();
      data.sort((a, b) => b.quantityToBuy - a.quantityToBuy); // Sort by quantityToBuy initially
      setDrugs(data);
      setError(null); // Reset error state if successful
    } catch (error) {
      console.error('Error fetching drugs:', error);
      setError('Failed to fetch drugs. Please try again later.');
    }
  };

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        if (!tokenFromUrl) {
          console.error('Token not found in URL');
          navigate('/login');
          return;
        }

        setToken(tokenFromUrl); // Store the token in state

        // Fetching client's current location
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            setCurrentLocation(`${position.coords.latitude},${position.coords.longitude}`);
          }, error => {
            console.error('Error fetching location:', error);
          });
        }

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl, location: currentLocation }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            fetchDrugs(tokenFromUrl); // Fetch drugs after security check
          } else if (securityData.error === 'Session expired') {
            navigate('/login');
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate, currentLocation, days]);

  useEffect(() => {
    updateEstimatedCost();
  }, [drugs]);

  const handleChangeDays = (e) => {
    setDays(parseInt(e.target.value));
  };

  const handleNullValue = (value) => {
    return value || 0;
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedDrugs = [...drugs];
    updatedDrugs[index].quantityToBuy = quantity;
    setDrugs(updatedDrugs);
    updateEstimatedCost();
  };

  const handleCostPriceChange = (index, costPrice) => {
    const updatedDrugs = [...drugs];
    updatedDrugs[index].costPrice = costPrice;
    setDrugs(updatedDrugs);
    updateEstimatedCost();
  };

  const handleRemoveRow = (index) => {
    const updatedDrugs = drugs.filter((_, i) => i !== index);
    setDrugs(updatedDrugs);
    updateEstimatedCost();
  };

  const updateEstimatedCost = () => {
    let totalCost = 0;
    drugs.forEach((drug) => {
      totalCost += drug.quantityToBuy * drug.costPrice;
    });
    // Round to the nearest 1000
    totalCost = Math.round(totalCost / 1000) * 1000;
    setEstimatedCost(totalCost);
  };

  // Calculate total cost for each drug
  const calculateTotalCost = (drug) => {
    return drug.quantityToBuy * drug.costPrice;
  };

  // Filter out drugs with quantityToBuy === 0
  const filteredDrugs = drugs.filter((drug) => drug.quantityToBuy !== 0);

  return (
    <div className="generate-drug-order">
      <h1 className="generate-drug-order__title">Drugs Suggested to be on the next order for drugs</h1>
      <p className="generate-drug-order__estimated-cost" style={{ color: 'black' }}>
        This drug order might cost you approximately UGX: <span style={{ color: 'green', animation: 'heartbeat 1s infinite' }}>{estimatedCost}</span>
      </p>
      <div className="generate-drug-order__days">
        <label className="generate-drug-order__days-label">Number of days:</label>
        <input className="generate-drug-order__days-input" type="number" value={days} onChange={handleChangeDays} />
      </div>
      {error && <div className="generate-drug-order__error">{error}</div>}
     
      <table className="generate-drug-order__table" style={{ borderColor: '#ddd' }}>

  <thead>
    <tr>
      <th>Drug</th>
      <th style={{ width: '120px' }}>Packaging</th> {/* Reduced width */}
      <th style={{ width: '200px' }}>Quantity to Buy</th>
      <th style={{ width: '250px' }}>Cost Price</th> {/* Increased width */}
      <th>Total Cost</th>
      <th>Quantity Left in Store</th>
      <th>Quantity Left in Dispensary</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {filteredDrugs.map((drug, index) => (
      <tr key={drug.id} className="generate-drug-order__row">
        <td>{drug.name}</td>
        <td>{drug.packaging}</td>
        <td>
          <input
            className="generate-drug-order__quantity-input"
            type="number"
            value={handleNullValue(drug.quantityToBuy)}
            onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
          />
        </td>
        <td>
          <input
            className="generate-drug-order__cost-price-input"
            type="number"
            value={handleNullValue(drug.costPrice)}
            onChange={(e) => handleCostPriceChange(index, parseInt(e.target.value))}
          />
        </td>
        <td>{calculateTotalCost(drug)}</td>
        <td>{handleNullValue(drug.quantityLeftInStore)}</td>
        <td>{handleNullValue(drug.quantityLeftInDispensary)}</td>
        <td>
          <button className="generate-drug-order__remove-button" onClick={() => handleRemoveRow(index)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

    </div>
  );
}

export default GenerateDrugOrder;
