import React, { useState, useEffect } from 'react';

import './AdminDashboard.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faMoneyBillWave, faHandHoldingUsd, faUserPlus, faTrashAlt,
  faChartBar, faClipboardList, faAddressBook, faShoppingCart, faCalendar,
  faBirthdayCake, faMoneyBillAlt, faStore
} from '@fortawesome/free-solid-svg-icons';
import { urls } from './config.dev';
import JSEncrypt from 'jsencrypt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SettingsModal from './SettingsModal'; // Adjust the path as needed

function AdminDashboard() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [employees, setEmployees] = useState([]);
  const [birthdayCount, setBirthdayCount] = useState(0);
  const [showAddEmployeePrompt, setShowAddEmployeePrompt] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState('');
  const [newEmployeeRole, setNewEmployeeRole] = useState('');
  const [showSendMessagePrompt, setShowSendMessagePrompt] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [employeeName, setEmployeeName] = useState('');
  const [token, setToken] = useState(null);
  const [isLoadingAddEmployee, setIsLoadingAddEmployee] = useState(false);
  const [isLoadingUpdatePermissions, setIsLoadingUpdatePermissions] = useState(false);
  const [clinicSessionToken, setClinicSessionToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState({
    sendWhatsappMessages: false,
    Store: false,
    selldrugs: false,
    Sales: false,
    'access-laboratory': false,
    cashier: false,
    'access-radiographer': false,
  });
  const [loginCode, setLoginCode] = useState('');
  

  const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAup3FU135mAvJT6OheYW3
pQyWf6jvS4duUMY4cXrlJXyGqu8HqvTU0ewPy6w2HhCPxWboNclkAkPhOCc4URNT
x1Grg+mCsWmfhVimP2wtfmlBCJ09cyDMYf93iGj8RFf3CshY5yhppT/pX+RgTuXw
ClpOXe24CLG2VF9suNylk+ReAMLyOxaekYofAMBvvrD4+GYPJgvkTMXCXCKp2PnO
8+OjiltNMnoyqPEZoXHTV4EXtTrjYnwzSe0WZSSuzgVMhmtdx+IS4eisSumHV1eI
wBeZwI0bYGxDCedPRassmSFgTFqkkcgIXmEP1n5w/08S/QPr2G+myKTeRqp5RJA5
PQIDAQAB
-----END PUBLIC KEY-----`;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        console.log('URL Token:', tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            console.log('Clinic Session Token:', securityData.clinic_session_token);
            setClinicSessionToken(securityData.clinic_session_token);

            if (securityData.clinic_session_token) {
              setToken(securityData.clinic_session_token);
              console.log('New Token:', securityData.clinic_session_token);
              fetchBirthdayCount(securityData.clinic_session_token);
            }
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchBirthdayCount = async (token) => {
    try {
      console.log('Fetching Birthday Count with Token:', token);
      const response = await fetch(`${urls.birthdaycount}?token=${token}`);
      if (response.ok) {
        const data = await response.json();
        setBirthdayCount(data.birthday_count);
      } else {
        throw new Error('Failed to fetch birthday count');
      }
    } catch (error) {
      console.error('Error fetching birthday count:', error);
    }
  };

  const fetchEmployees = async () => {
    try {
      console.log('Fetching Employees with Token:', token);
      const response = await fetch(`${urls.fetchemployees2}?token=${token}`);
      if (response.ok) {
        const data = await response.json();
        setEmployees(data);
      } else {
        throw new Error('Failed to fetch employees');
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchEmployees();
      const interval = setInterval(fetchEmployees, 60000);
      return () => clearInterval(interval);
    }
  }, [token]);

  const handleDeleteEmployee = async (employeeName, employeeRole, index) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${employeeName} from this platform?`);

    if (!confirmDelete) {
      return;
    }

    try {
      setLoadingIndex(index); // Set loading state for this specific row

      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      const response = await fetch(urls.deleteEmployee, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: employeeName,
          role: employeeRole,
          token: token // Include token in the request body
        })
      });

      if (response.ok) {
        setEmployees(employees.filter(emp => emp.Name !== employeeName));
        alert(`${employeeName} deleted successfully.`);
      } else {
        throw new Error('Failed to delete employee');
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
      alert(`Error deleting ${employeeName}: ${error.message}`);
    } finally {
      setLoadingIndex(null); // Reset loading state after operation completes
    }
  };

  const handleAddEmployee = async () => {
    try {
      setIsLoadingAddEmployee(true); // Set loading state
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      const requestBody = {
        name: newEmployeeName,
        role: newEmployeeRole,
        token: token
      };

      const response = await fetch(urls.addemployee, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      const responseData = await response.json();

      if (response.ok) {
        setShowAddEmployeePrompt(false);
        fetchEmployees();
        alert('Employee added successfully');
      } else {
        throw new Error(responseData.error || 'Failed to add employee');
      }
    } catch (error) {
      console.error('Error adding employee:', error);
      alert('Error adding employee: ' + error.message);
    } finally {
      setIsLoadingAddEmployee(false); // Reset loading state
    }
  };

  const handleMenuButtonClick = (pageName) => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/${pageName}/token=${tokenFromUrl}`);
  };

  const handleBirthdaysButtonClick = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/birthdays/token=${tokenFromUrl}`);
  };

  const handleGrantPermissions = (employee) => {
    setSelectedEmployee(employee);
    setShowSendMessagePrompt(true);
  };

  const handleCancelPermissions = () => {
    setShowSendMessagePrompt(false);
  };

  const handleUpdatePermissions = async () => {
    try {
      setIsLoadingUpdatePermissions(true); // Set loading state
  
      // Extract token from URL
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
  
      if (!token) {
        throw new Error('Token not found in the URL');
      }
  
      // Encrypt the login code
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encryptedLoginCode = encrypt.encrypt(loginCode.toString());
  
      // Make API call to update permissions
      const response = await fetch(urls.updatepermissions, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          employeeName: selectedEmployee.Name,
          permissions: selectedPermissions,
          token: token, // Use the token from the URL
          loginCode: encryptedLoginCode
        })
      });
  
      if (response.ok) {
        setShowSendMessagePrompt(false);
        alert('Permissions updated successfully');
      } else {
        throw new Error('Failed to update permissions');
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
      alert('Error updating permissions: ' + error.message);
    } finally {
      setIsLoadingUpdatePermissions(false); // Reset loading state
    }
  };
  const handleCheckboxChange = (permission) => {
    setSelectedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: !prevPermissions[permission]
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
   // Extract token from URL when the component mounts
   const handleShowModal = () => {
    // Fetch token from URL
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      console.log('URL Token:', tokenFromUrl);
    }

    // Show the modal
    setShowModal(true);
  };
  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard">
        <div className="settings-button-container">
          <button className="settings-button" onClick={handleShowModal}>Change passwords</button>
          {showModal && <SettingsModal token={token} onClose={() => setShowModal(false)} />}
        </div>
        <h1 className="admin-title">EMPLOYEE SETTINGS</h1>
       

        <div className="admin-employee-list">
          <h2>All Employees</h2>
          <table className="admin-employee-list">
  <thead>
    <tr>
      <th>Name</th>
      <th>Role</th>
      <th>Grant Permissions</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    {employees.map((employee, index) => (
      <tr key={employee.EmployeeID}>
        <td>{employee.Name}</td>
        <td>{employee.Role}</td>
        <td>
          {index !== 0 && ( // Check if it's not the first employee
            <button onClick={() => handleGrantPermissions(employee)}>
              Grant Permissions
            </button>
          )}
        </td>
        <td>
          {index !== 0 && ( // Check if it's not the first employee
            <button onClick={() => handleDeleteEmployee(employee.Name, employee.Role, index)}>
              {loadingIndex === index ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faTrashAlt} />
              )}
              {' '} Delete
            </button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</table>

          <button className="admin-add-employee" onClick={() => setShowAddEmployeePrompt(true)}>
            <FontAwesomeIcon icon={faUserPlus} /> Add New Employee
          </button>
        
          
    
          {showAddEmployeePrompt && (
            <div className="admin-modal-overlay">
              <div className="admin-modal-content">
                <div className="admin-transaction-prompt">
                  <h2>Add New Employee</h2>
                  <input type="text" placeholder="Employee Name" value={newEmployeeName} onChange={(e) => setNewEmployeeName(e.target.value)} />
                  <input type="text" placeholder="Employee Role" value={newEmployeeRole} onChange={(e) => setNewEmployeeRole(e.target.value)} />
                  <div className="admin-button-group">
                    <button onClick={() => setShowAddEmployeePrompt(false)}>Cancel</button>
                    <button className="action-button" onClick={handleAddEmployee} disabled={isLoadingAddEmployee}>
  {isLoadingAddEmployee ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Add This Employee'}
</button>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>



      {showSendMessagePrompt && (
  <div className="admin-modal-overlay">
    <div className="admin-modal-content">
      <h2>Set Permissions for {selectedEmployee.Name}</h2>
      <div className="admin-permissions-checkboxes">
       {/*} <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.sendWhatsappMessages} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, sendWhatsappMessages: !prev.sendWhatsappMessages }))} 
            />
            Permit send Whatsapp messages to patients
          </label>
        </div>*/}
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.Store} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, Store: !prev.Store }))} 
            />
            Permit access to store
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.selldrugs} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, selldrugs: !prev.selldrugs }))} 
            />
            Permit access dispensary or drug shelves.
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['access-laboratory']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'access-laboratory': !prev['access-laboratory'] }))} 
            />
            Permit access laboratory section.
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.sales} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, sales: !prev.sales }))} 
            />
            Allow access cashier dashboard
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['access-radiographer']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'access-radiographer': !prev['access-radiographer'] }))} 
            />
            Permit access radiology section.
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['view costs spent on treating patient']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'view costs spent on treating patient': !prev['view costs spent on treating patient'] }))} 
            />
            Allow see costs spent on treating patient. This enables the employee to be able to see the total cost of drugs used to treat a particular patient. 
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['makeOrderForDrugs']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'makeOrderForDrugs': !prev['makeOrderForDrugs'] }))} 
            />
            Permit make order for drugs. 
          </label>
        </div>
        </div>
        <label className="permission-label">
  <input 
    type="checkbox" 
    checked={selectedPermissions['access-clinic-statistics']} 
    onChange={() => setSelectedPermissions(prev => ({ ...prev, 'clinicStatistics': !prev['clinicStatistics'] }))} 
  />
  Permit access to view Statistics. This includes viewing a summarized report of how the business is performing , its profits or losses etc. 
</label>
<div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['access-doctors-room']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'access-doctors-room': !prev['access-doctors-room'] }))} 
            />
            Permit access Doctors section.
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions['access-nurse']} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, 'access-nurse': !prev['access-nurse'] }))} 
            />
            Permit access nurses section.
          </label>
        </div>
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.manageDrugs} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, manageDrugs: !prev.manageDrugs }))} 
            />
            Permit manage drugs. This includes adding new drugs or deleting drugs and changing drug stock values.  
          </label>
        </div>
        {/*<div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.paymentHistory} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, paymentHistory: !prev.paymentHistory }))} 
            />
            Permit view payment history
          </label>
        </div>*/}
        <div>
          <label className="permission-label">
            <input 
              type="checkbox" 
              checked={selectedPermissions.manageLaboratory} 
              onChange={() => setSelectedPermissions(prev => ({ ...prev, manageLaboratory: !prev.manageLaboratory }))} 
            />
            Permit manage laboratory and radiology investigations
                </label>
              </div>
            </div>
            <div>
        <label className="permission-label">
  <input 
    type="checkbox" 
    checked={selectedPermissions['access-sales-details']} 
    onChange={() => setSelectedPermissions(prev => ({ ...prev, 'salesDetails': !prev['salesDetails'] }))} 
  />
  Permit access to Sales History Details. This includes viewing the past sales records in details. 
</label>

        </div>
            <div className="admin-login-code-input">
        <label>
          Set Security Code for {selectedEmployee.Name}:
          <input 
  type="text" 
  value={loginCode} 
  placeholder={`If you don't want to change the security code for ${selectedEmployee.Name}, leave this space empty.`}
  onChange={(e) => setLoginCode(e.target.value)} 
  style={{ fontSize: 'smaller' }} 
/>

        </label>
      </div>
            <div className="admin-button-group">
              <button onClick={handleCancelPermissions}>Cancel</button>
              <button className="action-button" onClick={() => handleUpdatePermissions()} disabled={isLoadingUpdatePermissions}>
  {isLoadingUpdatePermissions ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Update Permissions'}
</button>


            </div>
          </div>
          
        </div>
      )}

      <footer className="admin-footer">
        This system was created by EliteMinds E-Systems. All rights reserved.
      </footer>
    </div>
  );
}

export default AdminDashboard;