import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './Laboratory.css';
import TestResultModal from './TestResultModal';
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component

function Laboratory() {
  const [pendingLabTests, setPendingLabTests] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({});
  const [token, setToken] = useState('');
  const [totalLabTests, setTotalLabTests] = useState(0); // State to hold total lab tests
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  // Fetch clinic details function
  const fetchClinicDetails = async (token) => {
    try {
      const response = await fetch(urls.fetchclinicdetails, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
        setClinicDetails(data);
      } else {
        throw new Error('Failed to fetch clinic details');
      }
    } catch (error) {
      console.error('Error fetching clinic details:', error);
    }
  };

  const fetchPendingLabTests = async (token) => {
    try {
      const response = await fetch(urls.pendinglabtests, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();

        // Process data to calculate totalLabTests correctly
        const processedData = data.map(patient => ({
          ...patient,
          totalLabTests: (patient.lab_tests.match(/Lab Test:/g) || []).length // Count occurrences of "Lab Test:"
        }));

        setPendingLabTests(processedData);
      } else {
        throw new Error('Failed to fetch pending lab tests data');
      }
    } catch (error) {
      console.error('Error fetching pending lab tests data:', error);
    }
  };

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        setToken(tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            fetchClinicDetails(tokenFromUrl); // Fetch clinic details after security check
            fetchPendingLabTests(tokenFromUrl); // Fetch pending lab tests after security check

            // Set up polling to refresh pending lab tests data every 30 seconds
            const intervalId = setInterval(() => {
              fetchPendingLabTests(tokenFromUrl);
            }, 30000); // 30 seconds interval

            // Clear the interval on component unmount
            return () => clearInterval(intervalId);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const handleSubmitResults = (patient) => {
    setSelectedPatient(patient);
    setShowModal(true);
    setTotalLabTests(patient.totalLabTests); // Set total lab tests for the selected patient
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPatient(null);
    setTotalLabTests(0); // Reset total lab tests when closing modal
  };

  const handleSaveResults = (contactId, results) => {
    // Implement logic to submit and save results
    console.log('Submitting and saving results for patient ID:', contactId);
    console.log('Results:', results);
    // Here you can add code to submit the results to the backend
  };

  return (
    <div className="lab-dashboard-container">
      <Topbar token={urlToken} />
      <h1>Pending Lab Tests</h1>
      {pendingLabTests.length === 0 ? (
        <p>No lab tests pending</p> // Display this message if there are no pending lab tests
      ) : (
        pendingLabTests.map((patient) => (
          <div key={patient.contact_id} className="patient-details">
            <h2>{`${patient.first_name} ${patient.last_name}`}</h2>
            <p>Contact ID: {patient.contact_id}</p>
            <p>Age: {patient.age}</p>
            <p>Sex: {patient.sex}</p>
            <p>File ID: {patient.file_id}</p>
            <p>Tests to be done:</p>
            <ul>
              {patient.lab_tests.split(', ').map((test, index) => (
                <li key={index}>{test.trim()}</li>
              ))}
            </ul>
            <p>Total Lab Tests: {patient.totalLabTests}</p> {/* Display total lab tests */}
            <button onClick={() => handleSubmitResults(patient)}>Submit Results</button>
          </div>
        ))
      )}
      {showModal && selectedPatient && (
        <>
          {/* Log details before rendering TestResultModal */}
          {console.log('Rendering TestResultModal with:', {
            patient: selectedPatient,
            clinicDetails: clinicDetails,
            token: token,
            totalLabTests: totalLabTests,
            labTests: selectedPatient.lab_tests,
          })}
          <TestResultModal
            patient={selectedPatient}
            clinicDetails={clinicDetails}
            token={token}
            totalLabTests={totalLabTests} // Pass total lab tests to modal
            labTests={selectedPatient.lab_tests} // Pass the lab tests to the modal
            onClose={handleCloseModal}
            onSubmit={handleSaveResults}
          />
        </>
      )}
    </div>
  );
}

export default Laboratory;
