import React, { useState, useEffect } from 'react';
import './Statistics.css'; // Your CSS styles for the Statistics page
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify

function getTokenFromUrlOrLocalStorage() {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');
  return tokenFromUrl || localStorage.getItem('token');
}

function Statistics() {
  const navigate = useNavigate();
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [year, setYear] = useState(new Date().getFullYear()); // Default to current year
  const [statisticsData, setStatisticsData] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const performSecurityCheck = async (token) => {
    try {
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          fetchPerformanceData(token);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    }
  };

  const fetchPerformanceData = async (token) => {
    try {
      setLoading(true); // Set loading state to true when the request starts
      const payload = {
        token,
        month: month,
        year: year,
      };

      const response = await fetch(`${urls.statistics}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === "No data found for the requested month and year.") {
          toast.error('No data found for the requested month and year.'); // Show toast notification
        } else {
          setStatisticsData(data);
        }
      } else {
        throw new Error('Failed to fetch statistics data');
      }
    } catch (error) {
      console.error('Error fetching statistics datas:', error);
      toast.error('Error fetching statistics data! It might be that the data you are trying to fetch dosent exist or try checking your internet connection');
    } finally {
      setLoading(false); // Set loading state to false when the request is finished
    }
  };

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();
    performSecurityCheck(token);
  }, [month, year]);

  const calculateTotals = () => {
    if (!statisticsData) return;

    let totalDayShiftSales = 0;
    let totalNightShiftSales = 0;
    let totalDayShiftExpenses = 0;
    let totalNightShiftExpenses = 0;
    let totalCostOfProduction = 0;

    statisticsData.sales.forEach(({ DayShiftSales, NightShiftSales }) => {
      totalDayShiftSales += parseFloat(DayShiftSales || 0); // Consider null as 0
      totalNightShiftSales += parseFloat(NightShiftSales || 0); // Consider null as 0
    });

    statisticsData.expenses.forEach(({ DayShiftExpenses, NightShiftExpenses }) => {
      totalDayShiftExpenses += parseFloat(DayShiftExpenses || 0); // Consider null as 0
      totalNightShiftExpenses += parseFloat(NightShiftExpenses || 0); // Consider null as 0
    });

    statisticsData.cop.forEach(({ TotalCOP }) => {
      totalCostOfProduction += parseFloat(TotalCOP || 0); // Consider null as 0
    });

    const totalSales = totalDayShiftSales + totalNightShiftSales;
    const totalExpenses = totalDayShiftExpenses + totalNightShiftExpenses;
    const estimatedProfit = totalSales - totalCostOfProduction;

    const lastDate = statisticsData.sales[statisticsData.sales.length - 1].ShiftDate;
    const daysWithData = parseInt(lastDate.split('-')[2]);

    const averageSales = totalSales / daysWithData;
    const averageExpenses = totalExpenses / daysWithData;
    const averageProfit = estimatedProfit / daysWithData;
    const averageCostOfProduction = totalCostOfProduction / daysWithData;

    const roundToNearestHundred = (num) => Math.round(num / 100) * 100;

    return {
      totalDayShiftSales: roundToNearestHundred(totalDayShiftSales),
      totalNightShiftSales: roundToNearestHundred(totalNightShiftSales),
      totalSales: roundToNearestHundred(totalSales),
      totalDayShiftExpenses: roundToNearestHundred(totalDayShiftExpenses),
      totalNightShiftExpenses: roundToNearestHundred(totalNightShiftExpenses),
      totalExpenses: roundToNearestHundred(totalExpenses),
      totalCostOfProduction: roundToNearestHundred(totalCostOfProduction),
      estimatedProfit: roundToNearestHundred(estimatedProfit),
      averageSales: roundToNearestHundred(averageSales),
      averageExpenses: roundToNearestHundred(averageExpenses),
      averageProfit: roundToNearestHundred(averageProfit),
      averageCostOfProduction: roundToNearestHundred(averageCostOfProduction),
      totalCostOfDrugsUsed: roundToNearestHundred(totalCostOfProduction - totalExpenses),
    };
  };

  const totals = calculateTotals();

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  return (
    <div className="statistics-page">
      <ToastContainer /> {/* Add ToastContainer to render the toasts */}
      <div className="month-year-selection">
        <select value={month} onChange={handleMonthChange}>
          <option value={1}>January</option>
          <option value={2}>February</option>
          <option value={3}>March</option>
          <option value={4}>April</option>
          <option value={5}>May</option>
          <option value={6}>June</option>
          <option value={7}>July</option>
          <option value={8}>August</option>
          <option value={9}>September</option>
          <option value={10}>October</option>
          <option value={11}>November</option>
          <option value={12}>December</option>
        </select>
        <input type="number" value={year} onChange={handleYearChange} />
      </div>

      {loading ? (
        <div>Loading...</div> // Show loading state
      ) : statisticsData ? (
        <div>
          <h1>Statistics for {new Date(year, month - 1).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h1>
          <table className="statistics-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Day Shift Sales</th>
                <th>Night Shift Sales</th>
                <th>Day Shift Expenses</th>
                <th>Night Shift Expenses</th>
                <th>Cost of Production</th>
              </tr>
            </thead>
            <tbody>
              {statisticsData.sales.map(({ ShiftDate, DayShiftSales, NightShiftSales }, index) => {
                const dayShiftExpenses = statisticsData.expenses.find(exp => exp.ShiftDate === ShiftDate)?.DayShiftExpenses || 0;
                const nightShiftExpenses = statisticsData.expenses.find(exp => exp.ShiftDate === ShiftDate)?.NightShiftExpenses || 0;
                const totalCOP = statisticsData.cop.find(cop => cop.date === ShiftDate)?.TotalCOP || 0;

                return (
                  <tr key={index}>
                    <td>{ShiftDate}</td>
                    <td>{parseFloat(DayShiftSales || 0).toFixed(0)}</td>
                    <td>{parseFloat(NightShiftSales || 0).toFixed(0)}</td>
                    <td>{parseFloat(dayShiftExpenses).toFixed(0)}</td>
                    <td>{parseFloat(nightShiftExpenses).toFixed(0)}</td>
                    <td>{parseFloat(totalCOP).toFixed(0)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="totals">
            <h2>Sales Analysis</h2>
            <p>Total sales during day shift: {totals.totalDayShiftSales}</p>
            <p>Total sales during night shift: {totals.totalNightShiftSales}</p>
            <p>Total sales for both shifts: {totals.totalSales}</p>
            <p>Average sales: {totals.averageSales}</p>
            <p>This means your medical center sells approximately UGX {totals.averageSales} per day.</p>

            <h2>Expenses Analysis</h2>
            <p>Total day shift expenses: {totals.totalDayShiftExpenses}</p>
            <p>Total night shift expenses: {totals.totalNightShiftExpenses}</p>
            <p>Total expenses for both shifts: {totals.totalExpenses}</p>
            <p>Average expenses: {totals.averageExpenses}</p>
            <p>This means your medical center spends approximately UGX {totals.averageExpenses} per day.</p>

            <h2>Cost of Production</h2>
            <p>Total cost of drugs used: {totals.totalCostOfDrugsUsed}</p>
            <p>Total expenses: {totals.totalExpenses}</p>
            <p>Total cost of production: {totals.totalCostOfProduction}</p>
            <p>Average cost of production: {totals.averageCostOfProduction}</p>
            <p>This means your medical center's average cost of production is approximately UGX {totals.averageCostOfProduction} per day.</p>

            <h2>Profit Analysis</h2>
            <p>Total profit: {totals.estimatedProfit}</p>
            <p>Average profit: {totals.averageProfit}</p>
            <p>This medical center raises approximately UGX {totals.averageProfit} in profit on a daily basis.</p>
          </div>

          <footer className="footer2">
            This system was created by DeepMind E-Systems. For support or help contact +256786747733
          </footer>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default Statistics;