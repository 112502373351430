import React, { useState, useEffect } from 'react';
import { API_URL, urls } from './config.dev';
import './DrugOrigins.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import UpdateStocksModal from './UpdateStocksModal'; // Import the modal component
import Topbar from './Topbar'; // Import the Topbar component

function DrugOrigins() {
  const [newDrug, setNewDrug] = useState({ drugName: '', packaging: '', warningPoint: '', costPrice: '', sellingPrice: '' });
  const [allDrugs, setAllDrugs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddingDrug, setIsAddingDrug] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedDrug, setSelectedDrug] = useState(null); // State for selected drug details
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here
  const [clinicName, setClinicName] = useState(''); // State for storing clinic name

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        setToken(tokenFromUrl); // Save token to state

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            setClinicName(securityData.clinic); // Set the clinic name from the response
            fetchAllDrugs(tokenFromUrl);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };
    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchAllDrugs = async (token) => {
    try {
      const response = await fetch(urls.fetchoriginaldrugs, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch drugs');
      }
      const data = await response.json();
      const sortedDrugs = data.sort((a, b) => a.drug_name.localeCompare(b.drug_name));
      setAllDrugs(sortedDrugs);
    } catch (error) {
      console.error('Error fetching drugs:', error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    if (tokenFromUrl) {
      const interval = setInterval(() => fetchAllDrugs(tokenFromUrl), 10000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDrug(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddDrug = () => {
    setIsAddingDrug(true);

    if (
      !newDrug.drugName ||
      !newDrug.packaging ||
      !newDrug.warningPoint ||
      !newDrug.costPrice ||
      !newDrug.sellingPrice
    ) {
      alert("Please fill in all fields.");
      setIsAddingDrug(false);
      return;
    }

    const existingDrug = allDrugs.find(drug => 
      drug.drug_name.toLowerCase() === newDrug.drugName.toLowerCase() &&
      drug.packaging.toLowerCase() === newDrug.packaging.toLowerCase()
    );

    if (existingDrug) {
      alert("This drug already exists.");
      setIsAddingDrug(false);
      return;
    }

    fetch(urls.adddrug, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...newDrug,
        token
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to add drug');
      }
      setNewDrug({ drugName: '', packaging: '', warningPoint: '', costPrice: '', sellingPrice: '' });
      fetchAllDrugs(token);
    })
    .catch(error => {
      console.error('Error adding drug:', error);
    })
    .finally(() => {
      setIsAddingDrug(false);
    });
  };

  const handleDeleteDrug = (drugName, packaging) => {
    const confirmDeletion = window.confirm(`Are you sure you want to delete the drug ${drugName} with packaging ${packaging}?`);
  
    if (confirmDeletion) {
      setIsAddingDrug(true);
  
      fetch(urls.deleteoriginaldrug, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          drugName,
          packaging,
          token
        }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete drug');
        }
        fetchAllDrugs(token);
      })
      .catch(error => {
        console.error('Error deleting drug:', error);
      })
      .finally(() => {
        setIsAddingDrug(false);
      });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateStock = (drug) => {
    setSelectedDrug(drug);
    handleShowModal();
  };
  const handleRowClick = (index) => {
    setClickedRowIndex(clickedRowIndex === index ? null : index);
  };
  
  const filteredDrugs = allDrugs.filter(drug => drug.drug_name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div className="drug-origins-wrapper">
       <Topbar token={urlToken} />
      <div className="drug-origins-container">
      
      <h1 className="page-title">SCOPE OF DRUGS USED AT {clinicName}</h1> {/* Updated title */}
        <div className="add-drug-section">
          <h2 className="section-title">Add New Drug</h2>
          <div className="add-drug-form">
            <input 
              type="text" 
              name="drugName" 
              className="form-input" 
              placeholder="Drug Name" 
              value={newDrug.drugName} 
              onChange={handleInputChange} 
              title="This is the name that will appear on all sections of the system. You can use either the brand or generic drug name."
            />
            <select 
              name="packaging" 
              className="form-select" 
              value={newDrug.packaging} 
              onChange={handleInputChange} 
              title="Select how the drug is packaged when selling or using it."
            >
              <option value="">Select Packaging</option>
              <option value="Tablets">Tablets</option>
              <option value="Capsules">Capsules</option>
              <option value="Syrups">Syrups</option>
              <option value="Syringes">Syringes</option>
              <option value="Rolls">Rolls</option>
              <option value="Bottles">Bottles</option>
              <option value="Cannulas">Cannulas</option>
              <option value="Strips">Strips</option>
              <option value="Packets">Packets</option>
              <option value="Vials">Vials</option>
              <option value="Ampules">Ampules</option>
              <option value="Pieces">Pieces</option>
              <option value="Sackets">Sackets</option>
            </select>
            <input 
              type="number" 
              name="warningPoint" 
              placeholder="Minimum Point" 
              value={newDrug.warningPoint} 
              onChange={handleInputChange} 
              title="This point indicates the least amount of the drug that should be at the facility"
            />
            <input 
              type="number" 
              name="costPrice" 
              placeholder="Cost Price" 
              value={newDrug.costPrice} 
              onChange={handleInputChange} 
              title="Enter the cost price of the drug."
            />
            <input 
              type="number" 
              name="sellingPrice" 
              placeholder="Selling Price" 
              value={newDrug.sellingPrice} 
              onChange={handleInputChange} 
              title="Enter the price at which the drug will be sold to clients."
            />
            <button 
              onClick={handleAddDrug} 
              disabled={isAddingDrug} 
              className="add-drug-button"
            >
              {isAddingDrug ? 'Adding...' : 'Add Drug'}
            </button>
          </div>
        </div>
        <div className="drug-list-section">
          <input 
            type="text" 
            className="search-bar" 
            placeholder="Search drugs..." 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
          <table className="drug-table">
            <thead>
              <tr>
                <th>Drug Name</th>
                <th>Packaging</th>
                <th>Warning Point</th>
                <th>Cost Price</th>
                <th>Selling Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
  {filteredDrugs.map((drug, index) => (
    <tr
      key={index}
      className={clickedRowIndex === index ? 'selected-row' : ''}
      onClick={() => handleRowClick(index)}
    >
      <td>{drug.drug_name}</td>
      <td>{drug.packaging}</td>
      <td>{drug.warning_point}</td>
      <td>{drug.cost_price}</td> {/* Corrected: This should map to cost_price */}
      <td>{drug.selling_price}</td> {/* Corrected: This should map to selling_price */}
      <td>
        {clickedRowIndex === index && (
          <>
            <button
              onClick={() => handleUpdateStock(drug)}
              className="update-stock-button"
            >
              Update Stock
            </button>
            <button
              onClick={() => handleDeleteDrug(drug.drug_name, drug.packaging)}
              className="delete-button"
            >
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>



          </table>
        </div>
      </div>

      {/* Modal Component */}
      {showModal && (
        <UpdateStocksModal 
          isOpen={showModal} 
          onClose={handleCloseModal} 
          selectedDrug={selectedDrug} 
          token={token} 
          refreshDrugs={() => fetchAllDrugs(token)}
        />
      )}
    </div>
  );
}

export default DrugOrigins;
