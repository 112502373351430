import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './RadiographerDashboard.css';
import Topbar from './Topbar'; // Import the Topbar component

const RadiographerDashboard = () => {
  const [pendingExamsCount, setPendingExamsCount] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (!securityResponse.ok) throw new Error('Failed to perform security check');

        const securityData = await securityResponse.json();

        if (securityData.message === 'Session valid') {
          setEmployeeName(securityData.employee_name);
          fetchPendingExamsCount(tokenFromUrl); // Fetch pending lab tests count after security check
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchPendingExamsCount = async (token) => {
    try {
      const response = await fetch(urls.pendingexamscount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) throw new Error('Failed to fetch pending exams count');

      const data = await response.json();
      setPendingExamsCount(data.count);
    } catch (error) {
      console.error('Error fetching pending exams count:', error);
      // Handle error gracefully, show a message to the user
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    const interval = setInterval(() => fetchPendingExamsCount(tokenFromUrl), 10000); // Fetch pending exams count every 10 seconds
    fetchPendingExamsCount(tokenFromUrl); // Initial fetch

    return () => clearInterval(interval);
  }, []);

  const handleGoToExamsDoneToday = () => {
    // Add your logic to navigate to exams done today page
  };

  const handleGoToExamsHistory = () => {
    // Add your logic to navigate to exams history page
  };

  const handleGoToPendingExams = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/radiology?token=${tokenFromUrl}`);
  };

  return (
    <div className="radiographer-dashboard-container">
            <Topbar token={urlToken} />
      <h1>Hello, {employeeName}</h1>
      <button className="radiographer-dashboard-button" disabled onClick={handleGoToExamsDoneToday}>
        Exams Done Today
      </button>
      <button className="radiographer-dashboard-button" disabled onClick={handleGoToExamsHistory}>
        History of Exams Done
      </button>
      <button className="radiographer-dashboard-button radiographer-pending-exams" onClick={handleGoToPendingExams}>
        Pending Exams
        {pendingExamsCount > 0 && <span className="radiographer-badge">{pendingExamsCount}</span>}
      </button>
    </div>
  );
};

export default RadiographerDashboard;
