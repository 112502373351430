import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './contacts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingState from './LoadingState';
import Topbar from './Topbar'; // Import the Topbar component


function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newContactVisible, setNewContactVisible] = useState(false);
  const [sendMessageVisible, setSendMessageVisible] = useState(false);
  const [newContact, setNewContact] = useState({ first_name: '', last_name: '', phone_number: '', age: '', sex: '', dob: '', religion: '', address: '' });
 const [message, setMessage] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showSendMessagePrompt, setShowSendMessagePrompt] = useState(false);
  const [showAddContactPrompt, setShowAddContactPrompt] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSendMessageToAllPrompt, setShowSendMessageToAllPrompt] = useState(false);
  const [messageToAllPatients, setMessageToAllPatients] = useState('');
  const [messageToAllPatientsMuslims, setMessageToAllPatientsMuslims] = useState('');
  const [messageToAllPatientsChristians, setMessageToAllPatientsChristians] = useState('');
  const [employeeName, setEmployeeName] = useState(''); // State for employee name
  const [isAddingContact, setIsAddingContact] = useState(false); // State to track add contact process
  const [editContactVisible, setEditContactVisible] = useState(false); // State for showing edit contact prompt
const [editedContact, setEditedContact] = useState({}); // State for edited contact details
const [clinicSessionToken, setClinicSessionToken] = useState(''); // State to store clinic session token
const [token, setToken] = useState('');
const navigate = useNavigate();
const [canSendReminder, setCanSendReminder] = useState(false); // State to control reminder button
const [isLoading, setIsLoading] = useState(false);
const params = new URLSearchParams(window.location.search);
const urlToken = params.get('token'); // Define urlToken here


useEffect(() => {
  const fetchTokenAndCheckSecurity = async () => {
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      console.log('URL Token:', tokenFromUrl);

      // Perform security check using the token
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();

        if (securityData.message === 'Session valid') {
          setEmployeeName(securityData.employee_name);

          console.log('Clinic Session Token:', securityData.clinic_session_token);

          setClinicSessionToken(securityData.clinic_session_token);

          if (securityData.clinic_session_token) {
            setToken(securityData.clinic_session_token);
            fetchContacts(securityData.clinic_session_token);
            fetchMessagingPermission(); // Call the function here
          }
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    }
  };

  fetchTokenAndCheckSecurity();
}, [navigate]);

const fetchContacts = async (token) => {
  try {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    const response = await fetch(urls.fetchcontacts, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    });

    if (response.ok) {
      const data = await response.json();
      const sortedContacts = data.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setContacts(sortedContacts);
      setFilteredContacts(sortedContacts);
    } else {
      console.error('Failed to fetch contacts:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching contacts:', error);
  }
};

const fetchMessagingPermission = async () => {
  try {
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    if (!tokenFromUrl) {
      throw new Error('Token not found in URL parameters');
    }

    const response = await fetch(urls.messagingPermission, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.messages === 'yes') {
        setCanSendReminder(true); // Enable button if permission is granted
      } else {
        setCanSendReminder(false); // Disable button if permission is not granted
      }
    } else {
      throw new Error('Failed to fetch messaging permission');
    }
  } catch (error) {
    console.error('Error fetching messaging permission:', error.message);
  }
};

  const editContact = (contact) => {
    setEditedContact(contact);
    setEditContactVisible(true);
  };
  
  const updateContact = async () => {
    setIsAddingContact(true); // Start add or update contact process
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.editcontact, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...editedContact,
          contact_id: editedContact.contact_id, // Include the contact ID in the payload
          token: tokenFromUrl, // Include the token from URL in the payload
        }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        setEditContactVisible(false);
        fetchContacts(tokenFromUrl);
        alert(responseData.message); // Display success message from backend
      } else {
        console.error('Failed to edit contact:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing contact:', error);
    }
  
    setIsAddingContact(false); // End add or update contact process
  };
  
  
  const addContact = async () => {
    // Validate that all fields are filled
    if (!newContact.first_name || !newContact.last_name || !newContact.phone_number || !newContact.age || !newContact.sex || !newContact.dob || !newContact.religion) {
      alert('Please fill in all fields');
      return;
    }
  
    setIsAddingContact(true); // Start add contact process
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.addcontact, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newContact,
          token: tokenFromUrl
        }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        setShowAddContactPrompt(false);
        fetchContacts(tokenFromUrl);
        alert(responseData.message); // Display success message from backend
      } else {
        console.error('Failed to add contact:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  
    setIsAddingContact(false); // End add contact process
  };
  
  const removeContact = async (contactId, phoneNumber) => {
    if (window.confirm('Are you sure you want to remove this contact?')) {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
  
        const response = await fetch(urls.removecontact, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contactId,
            phoneNumber,
            token: tokenFromUrl
          }),
        });
  
        if (response.ok) {
          // Remove the contact from the state
          const updatedContacts = contacts.filter(contact => contact.contact_id !== contactId);
          setContacts(updatedContacts);
          setFilteredContacts(updatedContacts);
          alert('Contact removed successfully');
        } else {
          console.error('Failed to remove contact:', response.statusText);
        }
      } catch (error) {
        console.error('Error removing contact:', error);
      }
    }
  };
  
  const sendMessage = async (phoneNumber) => {
    setShowSendMessagePrompt(true);
    setPhoneNumber(phoneNumber); // Set phoneNumber in component state
  };
  
  const sendActualMessage = async (phoneNumber) => {
    setIsLoading(true); // Start loading
  
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.whatsappall, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          message: message,
          token: tokenFromUrl // Include the token in the payload
        })
      });
  
      if (response.ok) {
        // Directly parse JSON response
        const responseData = await response.json();
  
        // Extract messages from response data
        const messages = responseData.messages || [];
  
        // Categorize messages
        const sentNumbers = messages.filter(result => result.status.description === 'Message sent to next instance').map(result => result.to);
        const failedNumbersNotRegistered = messages.filter(result => result.status.description === 'Destination not registered').map(result => result.to);
        const failedNumbersUnknownReason = messages.filter(result => result.status.description !== 'Message sent to next instance' && result.status.description !== 'Destination not registered').map(result => result.to);
  
        const successCount = sentNumbers.length;
        const notRegisteredCount = failedNumbersNotRegistered.length;
        const unknownReasonCount = failedNumbersUnknownReason.length;
  
        // Build the toast message
        let toastMessage = '';
  
        if (successCount > 0) {
          const charge = successCount * 30; // Charge is $30 per successful message
          toastMessage += `${successCount} messages sent successfully to the following numbers:\n${sentNumbers.join(', ')}\n\n`;
          toastMessage += `You have been charged UGX ${charge}.\n\n`;
        } else {
          toastMessage += `No successful messages were sent. You have not been charged.\n\n`;
        }
  
        if (notRegisteredCount > 0) {
          toastMessage += `${notRegisteredCount} messages failed to send because the numbers are not registered on WhatsApp:\n${failedNumbersNotRegistered.join(', ')}\n\n`;
        }
  
        if (unknownReasonCount > 0) {
          toastMessage += `${unknownReasonCount} messages failed to send for unknown reasons:\n${failedNumbersUnknownReason.join(', ')}`;
        }
  
        // Display the toast
        if (toastMessage) {
          toast.success(toastMessage, {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
  
        console.log('Message sent successfully');
      } else {
        toast.error(`Failed to send message: ${response.statusText}`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Failed to send message:', response.statusText);
      }
    } catch (error) {
      toast.error(`Error sending message: ${error.message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false); // Stop loading
      setMessage('');
      setSendMessageVisible(false);
    }
  };
  
  const sendMessageToAllPatients = (religion) => {
    setShowSendMessageToAllPrompt(true);
    setNewContact({ ...newContact, religion: religion }); // Update newContact state with religion
  };

  const sendToAllPatients = async () => {
    setIsLoading(true); // Set loading state to true when starting the request
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const phoneNumbers = contacts
        .filter(contact => contact.religion === newContact.religion)
        .map(contact => contact.phone_number);
  
      const response = await fetch(urls.whatsappall, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phoneNumbers: phoneNumbers,
          message: newContact.religion === 'Islamic' ? messageToAllPatientsMuslims : messageToAllPatientsChristians,
          token: tokenFromUrl // Include the token in the payload
        })
      });
  
      if (response.ok) {
        // Get the raw text response
        const responseText = await response.text();
        
        // Split the response text into individual JSON strings
        // Ensure each JSON object is valid by checking and correcting format
        const jsonResponses = responseText
          .replace(/}\s*{/g, '},{') // Fix invalid JSON formatting
          .split('},{') // Split by the valid delimiter
          .map((jsonStr, index, arr) => {
            // Add back the missing braces for each JSON string
            if (index === 0) {
              return jsonStr + '}';
            } else if (index === arr.length - 1) {
              return '{' + jsonStr;
            } else {
              return '{' + jsonStr + '}';
            }
          });
  
        // Parse each JSON string separately
        const responseData = jsonResponses.map(jsonStr => JSON.parse(jsonStr));
  
        // Combine all response messages
        const messages = responseData.flatMap(item => item.messages);
  
        // Categorize messages
        const sentNumbers = messages.filter(result => result.status.description === 'Message sent to next instance').map(result => result.to);
        const failedNumbersNotRegistered = messages.filter(result => result.status.description === 'Destination not registered').map(result => result.to);
        const failedNumbersUnknownReason = messages.filter(result => result.status.description !== 'Message sent to next instance' && result.status.description !== 'Destination not registered').map(result => result.to);
  
        const successCount = sentNumbers.length;
        const notRegisteredCount = failedNumbersNotRegistered.length;
        const unknownReasonCount = failedNumbersUnknownReason.length;
  
        // Calculate the charge for successful messages
        const chargePerMessage = 30;
        const totalCharge = successCount * chargePerMessage;
  
        // Build the toast message
        let toastMessage = '';
  
        if (successCount > 0) {
          toastMessage += `${successCount} messages sent successfully to the following numbers:\n${sentNumbers.join(', ')}\n\n`;
          toastMessage += `You have been charged UGX ${totalCharge} for sending ${successCount} messages successfully.\n\n`;
        } else {
          toastMessage += `You have not been charged since no messages have been successfully sent.\n\n`;
        }
  
        if (notRegisteredCount > 0) {
          toastMessage += `${notRegisteredCount} messages failed to send because the numbers are not registered on WhatsApp:\n${failedNumbersNotRegistered.join(', ')}\n\n`;
        }
  
        if (unknownReasonCount > 0) {
          toastMessage += `${unknownReasonCount} messages failed to send for unknown reasons:\n${failedNumbersUnknownReason.join(', ')}`;
        }
  
        // Display the toast
        if (toastMessage) {
          toast.success(toastMessage, {
            position: "top-right",
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
  
        console.log('Message sent to all patients successfully');
      } else {
        toast.error(`Failed to send message to all patients: ${response.statusText}`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Failed to send message to all patients:', response.statusText);
      }
    } catch (error) {
      toast.error(`Error sending message to all patients: ${error.message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error sending message to all patients:', error);
    }
  
    setIsLoading(false); // Reset loading state when the request is complete
    setMessageToAllPatients('');
    setShowSendMessageToAllPrompt(false);
  };
  
  
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterContacts(query);
  };

  const filterContacts = (query) => {
    if (!query) {
      setFilteredContacts(contacts);
    } else {
      const filteredContacts = contacts.filter(contact =>
        contact.first_name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredContacts(filteredContacts);
    }
  };

  return (
    <div>
          
       {isLoading && <LoadingState />} {/* Ensure correct component rendering */}
      <ToastContainer />
<div className="contacts-scrollable-container">
<Topbar token={urlToken} />
<h1 className="page-title" style={{ marginTop: '60px' }}>Patients Details</h1>

     
  <div className="search-section">
    <input
      type="text"
      placeholder="Search contact by name"
      value={searchQuery}
      onChange={handleSearchChange}
      className="search-input"
    />
  </div>

  {showSendMessagePrompt && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
        <h2>Send Message</h2>
        <textarea
          rows="4"
          cols="50"
          placeholder="Type your message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className="transaction-buttons">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <button onClick={() => setShowSendMessagePrompt(false)}>Cancel</button>
              <button onClick={() => sendActualMessage(phoneNumber)}>Send</button>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)}

     {showSendMessageToAllPrompt && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
        <h2>Send Message to All {newContact.religion} Patients</h2>
        <textarea
          rows="4"
          cols="50"
          placeholder="Type your message here"
          value={newContact.religion === 'Islamic' ? messageToAllPatientsMuslims : messageToAllPatientsChristians}
          onChange={(e) =>
            newContact.religion === 'Islamic'
              ? setMessageToAllPatientsMuslims(e.target.value)
              : setMessageToAllPatientsChristians(e.target.value)
          }
        ></textarea>

        <div className="transaction-buttons">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <button onClick={() => setShowSendMessageToAllPrompt(false)}>Cancel</button>
              <button onClick={sendToAllPatients}>Send to All {newContact.religion} Patients</button>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)}

      {showAddContactPrompt && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="transaction-prompt">
              <h2>Add New Contact</h2>
              <input
                type="text"
                placeholder="First Name"
                value={newContact.first_name}
                onChange={(e) => setNewContact({ ...newContact, first_name: e.target.value })}
                className="add-contact-input"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={newContact.last_name}
                onChange={(e) => setNewContact({ ...newContact, last_name: e.target.value })}
                className="add-contact-input"
              />
              <input
                type="text"
                placeholder="Phone Number"
                value={newContact.phone_number}
                onChange={(e) => setNewContact({ ...newContact, phone_number: e.target.value.replace(/\D/, '') })}
                className="add-contact-input"
              />
              <input
  type="text"
  placeholder="Address"
  value={newContact.address}
  onChange={(e) => setNewContact({ ...newContact, address: e.target.value })}
  className="add-contact-input"
/>

              <input
                type="number"
                placeholder="Age"
                value={newContact.age}
                onChange={(e) => setNewContact({ ...newContact, age: e.target.value.replace(/\D/, '') })}
                className="add-contact-input"
              />
              <select
                value={newContact.sex}
                onChange={(e) => setNewContact({ ...newContact, sex: e.target.value })}
                className="add-contact-input"
              >
                <option value="">Select Sex</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <input
  type="text"
  placeholder="Date of Birth (YYYY-MM-DD)"
  value={newContact.dob}
  onChange={(e) => setNewContact({ ...newContact, dob: e.target.value })}
  onFocus={(e) => e.target.type = "date"} // Change to date input type on focus
  onBlur={(e) => e.target.type = "text"} // Change back to text input type on blur
  className="add-contact-input"
/>

              <select
                value={newContact.religion}
                onChange={(e) => setNewContact({ ...newContact, religion: e.target.value })}
                className="add-contact-input"
              >
                <option value="">Select Religion</option>
                <option value="Christian">Christian</option>
                <option value="Islamic">Islamic</option>
              </select>
              
              
              <div className="transaction-buttons">
                <button onClick={() => setShowAddContactPrompt(false)}>Cancel</button>
                <button onClick={addContact} disabled={isAddingContact}>
                  {isAddingContact ? 'Please wait...' : 'Add Contact'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="custom-button-container">
      <button onClick={() => sendMessageToAllPatients('Islamic')} disabled={!canSendReminder}>
  <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Send Message to all Muslims
</button>
<button onClick={() => sendMessageToAllPatients('Christian')} disabled={!canSendReminder}>
  <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Send Message to all Christians
</button>
<button onClick={() => sendMessageToAllPatients()} disabled={!canSendReminder}>
  <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Send Message to all Patients
</button>
  </div>

  <div className="custom2-button-container">
<div className="add-contact-section">
  <button onClick={() => setShowAddContactPrompt(true)}>Add New Patient/Client</button>
</div>
</div>

      
      <ul className="contacts-list">
      {filteredContacts.map((contact) => (
  <li key={contact.contact_id} className="contact-item">
    {/* Existing patient details */}
    <p>{contact.first_name} {contact.last_name}</p>
    <p>Phone: {contact.phone_number}</p>
    <p>Age: {contact.age}</p>
    <p>Sex: {contact.sex}</p>
    <p>Date of Birth: {contact.dob}</p>
    <p>Religion: {contact.religion}</p>
    <p>Address: {contact.address}</p>
    <button onClick={() => editContact(contact)} disabled={isAddingContact}>
      <FontAwesomeIcon icon={faEdit} />
      Edit Details
    </button>
    <button onClick={() => sendMessage(contact.phone_number)} disabled={!canSendReminder}>
  <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Whatsapp Message
</button>
            <button onClick={() => removeContact(contact.contact_id, contact.phone_number)} disabled={isAddingContact}>
            <FontAwesomeIcon icon={faTrash} />
            Delete Patient
          </button>
          </li>
        ))}
      </ul>
      </div>
      {editContactVisible && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
        <h2>Edit Contact Details</h2>
        {/* Input fields for editing contact details */}
        <input
          type="text"
          placeholder="First Name"
          value={editedContact.first_name}
          onChange={(e) => setEditedContact({ ...editedContact, first_name: e.target.value })}
          className="add-contact-input"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={editedContact.last_name}
          onChange={(e) => setEditedContact({ ...editedContact, last_name: e.target.value })}
          className="add-contact-input"
        />
        <input
          type="text"
          placeholder="Phone Number"
          value={editedContact.phone_number}
          onChange={(e) => setEditedContact({ ...editedContact, phone_number: e.target.value.replace(/\D/, '') })}
          className="add-contact-input"
        />
        <input
          type="text"
          placeholder="Address"
          value={editedContact.address}
          onChange={(e) => setEditedContact({ ...editedContact, address: e.target.value })}
          className="add-contact-input"
        />
        <input
          type="number"
          placeholder="Age"
          value={editedContact.age}
          onChange={(e) => setEditedContact({ ...editedContact, age: e.target.value.replace(/\D/, '') })}
          className="add-contact-input"
        />
        <select
          value={editedContact.sex}
          onChange={(e) => setEditedContact({ ...editedContact, sex: e.target.value })}
          className="add-contact-input"
        >
          <option value="">Select Sex</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <input
          type="text"
          placeholder="Date of Birth (YYYY-MM-DD)"
          value={editedContact.dob}
          onChange={(e) => setEditedContact({ ...editedContact, dob: e.target.value })}
          onFocus={(e) => e.target.type = "date"} // Change to date input type on focus
          onBlur={(e) => e.target.type = "text"} // Change back to text input type on blur
          className="add-contact-input"
        />
        <select
          value={editedContact.religion}
          onChange={(e) => setEditedContact({ ...editedContact, religion: e.target.value })}
          className="add-contact-input"
        >
          <option value="">Select Religion</option>
          <option value="Christian">Christian</option>
          <option value="Islamic">Islamic</option>
        </select>
        {/* Add button to save changes */}
        <div className="transaction-buttons">
          <button onClick={() => setEditContactVisible(false)}>Cancel</button>
          <button onClick={updateContact} disabled={isAddingContact}>
            {isAddingContact ? 'Please wait...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default Contacts;
