import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './Radiology.css';
import RadiologyResultModal from './RadiologyResultModal';
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component

function Radiology() {
  const [pendingRadiologyExams, setPendingRadiologyExams] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({});
  const [token, setToken] = useState('');
  const [totalRadiologyExams, setTotalRadiologyExams] = useState(0); // State to hold total radiology exams
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  // Fetch clinic details function
  const fetchClinicDetails = async (token) => {
    try {
      const response = await fetch(urls.fetchclinicdetails, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
        setClinicDetails(data);
      } else {
        throw new Error('Failed to fetch clinic details');
      }
    } catch (error) {
      console.error('Error fetching clinic details:', error);
    }
  };

  const fetchPendingRadiologyExams = async (token) => {
    try {
      const response = await fetch(urls.pendingradiologyexams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
  
        // Process data to calculate totalRadiologyExams correctly
        const processedData = data.map(patient => ({
          ...patient,
          totalRadiologyExams: (patient.radiology_exam.match(/Radiology Exam:/g) || []).length
        }));
  
        setPendingRadiologyExams(processedData);
      } else {
        throw new Error('Failed to fetch pending radiology exams data');
      }
    } catch (error) {
      console.error('Error fetching pending radiology exams data:', error);
    }
  };
  
  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        setToken(tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            fetchClinicDetails(tokenFromUrl); // Fetch clinic details after security check
            fetchPendingRadiologyExams(tokenFromUrl); // Fetch pending radiology exams after security check

            // Set interval to fetch pending radiology exams every 3 seconds
            const intervalId = setInterval(() => {
              fetchPendingRadiologyExams(tokenFromUrl);
            }, 3000);

            // Clear interval when component unmounts
            return () => clearInterval(intervalId);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const handleSubmitResults = (patient) => {
    setSelectedPatient(patient);
    setShowModal(true);
    setTotalRadiologyExams(patient.totalRadiologyExams); // Set total radiology exams for the selected patient
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPatient(null);
    setTotalRadiologyExams(0); // Reset total radiology exams when closing modal
  };

  const handleSaveResults = async (contactId, results, event) => {
    event.preventDefault();

    try {
      console.log('Submitting and saving results for patient ID:', contactId);
      console.log('Results:', results);
      // Implement logic to submit and save results to the backend
    } catch (error) {
      console.error('Error submitting results:', error);
    }
  };

  return (
    <div className="radiology-exams-container">
       <Topbar token={urlToken} />
      <h1>Pending Radiology Exams</h1>
      {pendingRadiologyExams.map((patient) => (
        <div key={patient.contact_id} className="patient-details">
          <h2>{`${patient.first_name} ${patient.last_name}`}</h2>
          <p>Contact ID: {patient.contact_id}</p>
          <p>Age: {patient.age}</p>
          <p>Sex: {patient.sex}</p>
          <p>Exam to be done:</p>
          <ul>
            {patient.radiology_exam.split('\n').map((exam, index) => (
              <li key={index}>{exam}</li>
            ))}
          </ul>
          <p>Total Radiology Exams: {patient.totalRadiologyExams}</p> {/* Display total radiology exams */}
          <button onClick={() => handleSubmitResults(patient)}>Submit Results</button>
        </div>
      ))}
      {showModal && selectedPatient && (
        <RadiologyResultModal
          patient={selectedPatient}
          clinicDetails={clinicDetails}
          token={token}
          totalRadiologyExams={totalRadiologyExams} // Pass total radiology exams to modal
          examsToBeDone={selectedPatient.radiology_exam.split('\n')} // Pass the list of exams to the modal
          onClose={handleCloseModal}
          onSubmit={handleSaveResults}
        />
      )}
    </div>
  );
}

export default Radiology;
