import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Store.css';
import { API_URL, urls } from './config.dev';
import { faPlus, faCheck, faStore, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component

function Store() {
  const navigate = useNavigate();

  const [showPrompt, setShowPrompt] = useState('');
  const [newDrugs, setNewDrugs] = useState([
    {
      drug: '',
      quantity: '',
      packaging: '',
      costPrice: '',
      sellingPrice: '',
      sellingPrice2: '' // Ensure this is initialized
    }
  ]);
  

  const [insertionMessage, setInsertionMessage] = useState('');
  const [stockData, setStockData] = useState([]);
  const [searchQueryMove, setSearchQueryMove] = useState('');
  const [searchQueryStock, setSearchQueryStock] = useState('');
  const [sortedStockData, setSortedStockData] = useState([]);
  const [movedDrugs, setMovedDrugs] = useState([]);
  const [originalDrugs, setOriginalDrugs] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [insertingDrugs, setInsertingDrugs] = useState(false); // State to track drug insertion process
  const [movingDrugs, setMovingDrugs] = useState(false); // State to track drug moving process
  const [movingDrugsInProgress, setMovingDrugsInProgress] = useState(false); // State to track drug moving process
  const [stockWorth, setStockWorth] = useState(null);
  const [editableDrugs, setEditableDrugs] = useState(newDrugs.map(() => true));
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
  
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });
  
        if (securityResponse.ok) {
          const securityData = await securityResponse.json();
  
          if (securityData.message === 'Session valid') {
            // Assuming you need to set some state based on the security check
            setEmployeeName(securityData.employee_name);
  
            // Start interval to fetch stock worth periodically
            startStockWorthInterval(tokenFromUrl); 
          } else if (securityData.error === 'Session expired') {
            // Redirect or navigate based on session expiration
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            // Redirect to login page if security check fails
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        // Redirect to login page in case of error
        navigate('/login');
      }
    };
  
    const fetchStockWorth = async (token) => {
      try {
        const response = await fetch(urls.stockworth, {
          method: 'POST', // Using POST since token is in request body
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: token }), // Include token in request body
        });
        if (!response.ok) {
          throw new Error('Failed to fetch stock worth');
        }
        const data = await response.json();
        // Process the response data as needed
        if (typeof data.stockWorth === 'object') {
          const stockWorthValue = data.stockWorth.stockWorth;
          setStockWorth(stockWorthValue);
        } else {
          setStockWorth(data.stockWorth);
        }
      } catch (error) {
        console.error('Error fetching stock worth:', error);
      }
    };
  
    const startStockWorthInterval = (token) => {
      // Initial fetch of stock worth
      fetchStockWorth(token);
  
      // Set up interval to fetch stock worth periodically
      const intervalId = setInterval(() => {
        fetchStockWorth(token); // Fetch with token in request body
      }, 4000);
  
      // Clean up function to clear interval when component unmounts or re-renders
      return () => clearInterval(intervalId);
    };
  
    // Call the function to initiate token check and interval setup
    fetchTokenAndCheckSecurity();
  
  }, [navigate]); // Include navigate in dependencies if it's from a hook like useHistory
  


  useEffect(() => {
    fetchStockData();
    fetchOriginalDrugs();

    // Fetch stock data every 5 seconds
    const intervalId = setInterval(fetchStockData, 5000);

    // Clean up function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const sortedData = [...stockData].sort((a, b) => a.Drug.localeCompare(b.Drug));
    setSortedStockData(sortedData);
  }, [stockData]);

  const fetchStockData = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
  
    // Construct your request body (if needed)
    const requestBody = {
      token: tokenFromUrl,
      // Add other data if needed
    };
  
    fetch(urls.fetchdrugs, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // You might need additional headers depending on your server requirements
      },
      body: JSON.stringify(requestBody), // Convert request body to JSON string
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch stock data');
      }
      return response.json();
    })
    .then(data => {
      // Handle the response data as needed
      setStockData(data);
    })
    .catch(error => {
      console.error('Error fetching stock data:', error);
    });
  };
  
 
  const fetchOriginalDrugs = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
  
    // Construct your request body
    const requestBody = {
      token: tokenFromUrl,
      // Add other data if needed
    };
  
    fetch(urls.fetchoriginaldrugs, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // You might need additional headers depending on your server requirements
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch original drugs');
        }
        return response.json();
      })
      .then(data => {
        // Map the cost_price and selling_price to originalDrugs
        const mappedDrugs = data.map(drug => ({
          ...drug,
          cost_price: parseFloat(drug.cost_price).toFixed(2), // Ensure it's formatted as needed
          selling_price: parseFloat(drug.selling_price).toFixed(2), // Ensure it's formatted as needed
          selling_price2: parseFloat(drug.selling_price2).toFixed(2), // Ensure it's formatted as needed
     
        }));
        setOriginalDrugs(mappedDrugs);
      })
      .catch(error => {
        console.error('Error fetching original drugs:', error);
      });
  };
  
  
  const handleButtonClick = (action) => {
    setShowPrompt(action);
  };

  const handleDrugChange = (index, field, value) => {
    const updatedDrugs = [...newDrugs];
  
    if (field === 'drug') {
      const [selectedDrug, selectedPackaging] = value.split(' - ');
      const selectedDrugObject = originalDrugs.find(drug => drug.drug_name === selectedDrug && drug.packaging === selectedPackaging);
  
      if (selectedDrugObject) {
        updatedDrugs[index] = {
          ...updatedDrugs[index],
          drug: selectedDrugObject.drug_name,
          packaging: selectedDrugObject.packaging,
          costPrice: selectedDrugObject.cost_price,
          sellingPrice: selectedDrugObject.selling_price,
          sellingPrice2: selectedDrugObject.selling_price2,
        };
  
        // Set the drug cell as non-editable
        setEditableDrugs(prevState => {
          const newState = [...prevState];
          newState[index] = false; // Mark this cell as non-editable
          return newState;
        });
      } else {
        updatedDrugs[index] = {
          ...updatedDrugs[index],
          drug: value,
          packaging: '',
          costPrice: '',
          sellingPrice: '',
          sellingPrice2: '',
        };
      }
    } else {
      updatedDrugs[index][field] = value;
    }
  
    setNewDrugs(updatedDrugs);
  };
  
  
  const addNewRow = () => {
    if (newDrugs.length < 5) {
      setNewDrugs([...newDrugs, { drug: '', quantity: '', packaging: '', costPrice: '', sellingPrice: '', sellingPrice2: '' }]);
      setEditableDrugs([...editableDrugs, true]);
    } else {
      toast.warning('Maximum 5 rows allowed. Please insert the current data first to avoid data loss.');
    }
  };
  const removeRow = (index) => {
    if (newDrugs.length > 1) { // Ensure there's at least one row remaining
      const updatedDrugs = [...newDrugs];
      updatedDrugs.splice(index, 1); // Remove the drug at the specified index
      setNewDrugs(updatedDrugs); // Update the state
    }
  };
  
  
  const handleInsertDrugs = () => {
    setInsertingDrugs(true);
  
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    const filteredDrugs = newDrugs.filter(drug => drug.drug && drug.quantity && drug.costPrice && drug.sellingPrice && drug.sellingPrice2);
  
    if (filteredDrugs.length === 0) {
      toast.error('Please fill in all required fields before submitting.');
      setInsertingDrugs(false);
      return;
    }

    const invalidDrugs = filteredDrugs.filter(drug => 
      !originalDrugs.some(originalDrug => 
        originalDrug.drug_name === drug.drug && originalDrug.packaging === drug.packaging
      )
    );
    
    if (invalidDrugs.length > 0) {
      toast.error('Some drugs do not exist in the facility’s scope. Contact an administrator.');
      setInsertingDrugs(false);
      return;
    }
  
    const abnormalCostPriceDrugs = filteredDrugs.filter(drug => {
      const originalDrug = originalDrugs.find(original => 
        original.drug_name === drug.drug && original.packaging === drug.packaging
      );
      if (originalDrug) {
        const originalCostPrice = parseFloat(originalDrug.cost_price);
        const insertedCostPrice = parseFloat(drug.costPrice);
        return (insertedCostPrice - originalCostPrice) > 1000;
      }
      return false;
    });
  
    if (abnormalCostPriceDrugs.length > 0) {
      const detailedMessage = abnormalCostPriceDrugs.map(drug => 
        `Drug Name: ${drug.drug}, Packaging: ${drug.packaging}, New Cost Price: ${drug.costPrice}`
      ).join('\n');
  
      const confirmMessage = `The new cost price of some drugs is abnormally high:\n\n${detailedMessage}\n\nAre you sure you want to proceed?`;
  
      if (!window.confirm(confirmMessage)) {
        setInsertingDrugs(false);
        return;
      }
    }
  
    const requestData = {
      token: tokenFromUrl,
      drugs: filteredDrugs.map(drug => ({
        drug: drug.drug,
        quantity: drug.quantity,
        packaging: drug.packaging,
        costPrice: drug.costPrice,
        sellingPrice: drug.sellingPrice,
        sellingPrice2: drug.sellingPrice2,  // Include sellingPrice2
      }))
    };

    fetch(urls.insertdrugs, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to insert drugs');
      }
      return response.json();
    })
    .then(data => {
      toast.success('Drugs inserted successfully.');
      setTimeout(() => {
        setInsertionMessage('');
        setShowPrompt('');
        setNewDrugs([{ drug: '', quantity: '', packaging: '', costPrice: '', sellingPrice: '', sellingPrice2: '' }]);
      }, 5000);
      setEditableDrugs(newDrugs.map(() => true));
      setInsertingDrugs(false);
    })
    .catch(error => {
      toast.error('Drug insertion failed. Please check your network connectivity.');
      console.error('Error inserting drugs:', error);
      setInsertingDrugs(false);
    });
  };

  const handleCancelInsertion = () => {
    setShowPrompt('');
    setInsertionMessage('');
  };

  const handleSearchMove = (e) => {
    setSearchQueryMove(e.target.value.slice(0, 10));
  };

  const handleSearchStock = (e) => {
    setSearchQueryStock(e.target.value.slice(0, 10));
  };

 const filteredStockData = sortedStockData.filter(item => item.Drug.toLowerCase().includes(searchQueryStock.toLowerCase()));

 const handleMoveDrugs = () => {
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');

  const invalidMove = Object.values(movedDrugs).some((drug) => {
    const stockDrug = sortedStockData.find(
      (stockItem) => stockItem.Drug === drug.drug && stockItem.Packaging === drug.packaging
    );
    if (stockDrug) {
      const availableQuantity = parseInt(stockDrug.Quantity, 10);
      const moveQuantity = parseInt(drug.quantity, 10);
      if (moveQuantity > availableQuantity) {
        toast.error(
          `Ooops! Check out this, you are  trying to move ${moveQuantity} of ${drug.drug} but only ${availableQuantity} is available in stock.`,
          {
            position: "top-right",
            autoClose: 15000,
          }
        );
        return true;
      }
    } else {
      toast.error(
        `Drug ${drug.drug} with packaging ${drug.packaging} not found in stock data.`,
        {
          position: "top-right",
          autoClose: 10000,
        }
      );
      return true;
    }
    return false;
  });

  if (invalidMove) {
    // General error message for any invalid move
   {/*} toast.error('Failed to move drugs..', {
      position: "top-right",
      autoClose: 0,
    });*/}
    return;
  }

  setMovingDrugsInProgress(true);

  const requestData = {
    token: tokenFromUrl, // Include token in the request payload
    drugs: Object.values(movedDrugs).map((drug) => ({
      drug: drug.drug,
      packaging: drug.packaging,
      quantity: drug.quantity,
      sellingPrice: drug.sellingPrice,
      sellingPrice2: drug.sellingPrice2, // Include Selling Price 2
    })),
  };

  fetch(urls.movedrugs, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to move drugs');
      }
      return response.json();
    })
    .then((data) => {
      setMovingDrugsInProgress(false);
      toast.success('Drugs moved to dispensing shelves successfully.', {
        position: "top-right",
        autoClose: 5000,
      });
      setMovedDrugs({});
      setShowPrompt('');
    })
    .catch((error) => {
      setMovingDrugsInProgress(false);
      toast.error('Failed to move drugs..', {
        position: "top-right",
        autoClose: 5000,
      });
      console.error('Error moving drugs:', error);
    });
};

  const handleCancelMove = () => {
    setShowPrompt('');
    setMovedDrugs({});
    setInsertionMessage('');
    setSearchQueryMove(''); // Reset search query for moved drugs
  };
  

  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }} className="store-container">
       <Topbar token={urlToken} />
      <ToastContainer />
      <h1 style={{ marginTop: '60px' }}>
  STORE MANAGEMENT  <FontAwesomeIcon icon={faStore} className="store-icon" />
</h1>
      <div className="move-insert-container">
  <button
    className="insert-new-drugs-button"
    
    style={showPrompt === 'insertNewStock' ? { backgroundColor: 'red', color: 'white' } : {}}
    onClick={() => handleButtonClick('insertNewStock')}
    data-tooltip="This button will enable you to input new stock into the store, from where you can transfer them to the  drug shelves for sale or for use."
  >
    <FontAwesomeIcon icon={faPlus} /> Insert New Drugs
  </button>
  <button
    className="move-drugs-button"
    style={showPrompt === 'moveToShelves' ? { backgroundColor: 'red', color: 'white' } : {}}
    onClick={() => handleButtonClick('moveToShelves')}
    data-tooltip="This button will allow you to move drugs to the dispensary or drug shelves."
  >
    <FontAwesomeIcon icon={faCheck} /> Move Drugs to Shelves/Dispensary
  </button>
</div>
{showPrompt === 'insertNewStock' && (
  <>
    {/* Backdrop for the entire screen */}
    <div className="backdrop" />

    {/* Prompt content */}
    <div className="prompt">
    <span className="close-btn" onClick={handleCancelInsertion}>
        &times;
      </span>
      <Topbar token={urlToken} />
      <h1 style={{ marginTop: '60px' }}>
  INSERT DRUGS INTO THE STORE
</h1>

      <table>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Quantity</th>
            <th>Packaging</th>
            <th>Cost Price</th>
            <th>Selling Price</th>
            {/*<th>Wholesale Price</th>*/}
            <th>Actions</th> {/* Added an Actions header */}
          </tr>
        </thead>
        <tbody>
          {newDrugs.map((drug, index) => (
            <tr key={index}>
              <td>
                {editableDrugs[index] ? (
                  <input
                    type="text"
                    value={drug.drug}
                    onChange={(e) => handleDrugChange(index, 'drug', e.target.value)}
                    list="originalDrugsList"
                    className="drug-input"
                  />
                ) : (
                  <span className="non-editable">{drug.drug}</span>
                )}
                <datalist id="originalDrugsList">
                  {originalDrugs.map((drug, idx) => (
                    <option key={idx} value={`${drug.drug_name} - ${drug.packaging}`} />
                  ))}
                </datalist>
              </td>
              <td>
                <input
                  type="text"
                  value={drug.quantity}
                  onChange={(e) => handleDrugChange(index, 'quantity', e.target.value)}
                />
              </td>
              <td><span className="non-editable">{drug.packaging}</span></td>
              <td>
                <input
                  type="text"
                  value={drug.costPrice}
                  onChange={(e) => handleDrugChange(index, 'costPrice', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={drug.sellingPrice}
                  onChange={(e) => handleDrugChange(index, 'sellingPrice', e.target.value)}
                />
              </td>
             {/* <td>
  <input
    type="text"
    value={drug.sellingPrice2}
    onChange={(e) => handleDrugChange(index, 'sellingPrice2', e.target.value)}
  />
</td>*/}

              {index === newDrugs.length - 1 && (
                <td>
                  <button onClick={addNewRow}>+</button>
                  <button onClick={() => removeRow(index)} className="delete-button">X</button> {/* Added delete button */}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleInsertDrugs} disabled={insertingDrugs}>
        {insertingDrugs ? 'Please wait...' : 'Insert Drugs'}
      </button>
      <button onClick={handleCancelInsertion}>Cancel</button>
      {insertionMessage && <p>{insertionMessage}</p>}
    </div>
  </>
)}

  

{showPrompt === 'moveToShelves' && (
  <>
    {/* Backdrop for the entire screen */}
    <div className="backdrop" />

    {/* Prompt content */}
    <div className="move-drugs-prompt">
      {/* Close button (X) */}
      <span className="close-btn" onClick={handleCancelMove}>
        &times;
      </span>
      <Topbar token={urlToken} />
    <h1 style={{ marginTop: '60px' }}>
 MOVE DRUGS FROM THE STORE TO THE SHELVES
    </h1>
      <div className="search-container">
        <input 
          type="text" 
          placeholder="Search drugs you want to move...select the drug u want to move by clicking on the selection box"
          value={searchQueryMove} 
          onChange={handleSearchMove} 
        />
      </div>
      <div className="move-drugs-table-wrapper">
        <table className="move-drugs-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const updatedMovedDrugs = e.target.checked
                      ? Object.fromEntries(
                          sortedStockData
                            .filter((drug) => drug.Quantity > 0) // Filter out drugs with quantity 0
                            .map((drug) => [
                              `${drug.Drug}-${drug.Packaging}`,
                              {
                                drug: drug.Drug,
                                packaging: drug.Packaging,
                                quantity: drug.Quantity,
                                sellingPrice: drug.Selling_Price,
                                // sellingPrice2: drug.selling_price2, Include Selling_Price_2
                              },
                            ])
                        )
                      : {};
                    setMovedDrugs(updatedMovedDrugs);
                  }}
                />
              </th>
              <th>Drug</th>
              <th>Packaging</th>
              <th>Available Quantity</th>
              <th>Quantity to Move</th>
              <th>Selling Price</th>
             {/*  <th>Wholesale Price</th> New column header */}
            </tr>
          </thead>
          <tbody>
            {sortedStockData
              .filter((item) => item.Quantity > 0) // Filter out drugs with quantity 0
              .filter(
                (item) =>
                  searchQueryMove === "" ||
                  item.Drug.toLowerCase().includes(searchQueryMove.toLowerCase())
              )
              .map((drug, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        const updatedMovedDrugs = { ...movedDrugs };
                        if (e.target.checked) {
                          updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`] = {
                            drug: drug.Drug,
                            packaging: drug.Packaging,
                            quantity: drug.Quantity,
                            sellingPrice: drug.Selling_Price,
                           //  sellingPrice2: drug.selling_price2, Include Selling_Price_2
                          };
                        } else {
                          delete updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`];
                        }
                        setMovedDrugs(updatedMovedDrugs);
                      }}
                      checked={!!movedDrugs[`${drug.Drug}-${drug.Packaging}`]}
                    />
                  </td>
                  <td>{drug.Drug}</td>
                  <td>{drug.Packaging}</td>
                  <td>{drug.Quantity}</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      value={
                        movedDrugs[`${drug.Drug}-${drug.Packaging}`]?.quantity ??
                        drug.Quantity
                      }
                      onChange={(e) => {
                        const updatedMovedDrugs = { ...movedDrugs };
                        const newQuantity =
                          e.target.value === "" ? "" : parseInt(e.target.value, 10);
                        if (newQuantity === 0) {
                          delete updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`];
                        } else {
                          updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`] = {
                            ...updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`],
                            quantity: newQuantity,
                          };
                        }
                        setMovedDrugs(updatedMovedDrugs);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={
                        movedDrugs[`${drug.Drug}-${drug.Packaging}`]?.sellingPrice ||
                        drug.Selling_Price
                      }
                      onChange={(e) => {
                        const updatedMovedDrugs = { ...movedDrugs };
                        updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`] = {
                          ...updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`],
                          sellingPrice: e.target.value,
                        };
                        setMovedDrugs(updatedMovedDrugs);
                      }}
                    />
                  </td>
                 {/*} <td>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={
                        movedDrugs[`${drug.Drug}-${drug.Packaging}`]?.sellingPrice2 ||
                        drug.selling_price2
                      }
                      onChange={(e) => {
                        const updatedMovedDrugs = { ...movedDrugs };
                        updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`] = {
                          ...updatedMovedDrugs[`${drug.Drug}-${drug.Packaging}`],
                          sellingPrice2: e.target.value,
                        };
                        setMovedDrugs(updatedMovedDrugs);
                      }}
                    />
                  </td>*/}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="selected-drugs-container">
        <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Below is the list of Drugs you have selected to be moved to the
          drug shelves
        </h3>
        <p style={{ color: "red", fontSize: "12px" }}>
          Make sure each line has a drug name along with its packaging, quantity to
          be moved , and unit price to prevent drug movement errors
        </p>
        <ul className="selected-drugs-list">
          {Object.values(movedDrugs).map((drug, index) => (
            <li key={index} className="selected-drug-item">
              <span className="selected-drug-name">
                {drug.drug} - {drug.packaging}
              </span>
              <span className="selected-drug-quantity">Quantity: {drug.quantity}</span>
              <span className="selected-drug-price">
                Selling Price: {drug.sellingPrice}
              </span>
              {/*<span className="selected-drug-price2">
                Wholesale Price: {drug.sellingPrice2}
              </span>*/}
            </li>
          ))}
        </ul>
      </div>
      <div className="move-actions">
        <button onClick={handleMoveDrugs} disabled={movingDrugsInProgress}>
          {movingDrugsInProgress ? 'Please wait...' : 'Move the selected Drugs '}
        </button>
        <button onClick={handleCancelMove}>Cancel</button>
        {insertionMessage && <p>{insertionMessage}</p>}
      </div>
    </div>
  </>
)}

      <div className="search-container">
        <input type="text" placeholder="Search drugs in store (in put first letters) " value={searchQueryStock} onChange={handleSearchStock} />
      </div>

      <div className="stock-table">
  Current Stock ({`worth approximately UGX ${stockWorth !== null ? stockWorth : '...'}`})
  <table>
    <thead>
      <tr>
        <th>Drug</th>
        <th>Quantity</th>
        <th>Packaging</th>
        <th>Cost Price</th>
        <th>Selling Price</th> {/* Add Selling Price column header */}
       {/*  <th>Wholesale Price</th> Add Selling Price column header */}
      </tr>
    </thead>
    <tbody>
      {filteredStockData.map((item, index) => (
        <tr key={index}>
          <td>{item.Drug}</td>
          <td>{item.Quantity}</td>
          <td>{item.Packaging}</td>
          <td>{item.Cost_Price}</td>
          <td>{item.Selling_Price}</td> {/* Display Selling Price */}
        {/*  <td>{item.selling_price2}</td>  Display Selling Price */}
        </tr>
      ))}
    </tbody>
  </table>
</div>
<footer className="footer2">
        This system was created DeepMind E-Systems. For help or support contact  +256786747733
      </footer>

    </div>
  );
}

export default Store;