import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { urls } from './config.dev';
import './TreatmentPlanModal.css';
import MedicalBillModal from './MedicalBillModal';

const cellStyle = {
    padding: '10px',
    border: '1px solid #ddd'
};

function TreatmentPlanModal({ onClose, fileId, employeeName, token }) {
    const [treatmentPlanRows, setTreatmentPlanRows] = useState([createEmptyRow()]);
    const [drugSuggestions, setDrugSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showMedicalBillModal, setShowMedicalBillModal] = useState(false);
    const [billData, setBillData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [changesMade, setChangesMade] = useState(false); // Track if changes have been made


    useEffect(() => {
        fetchExistingTreatmentRows();
    }, []);

    // Log the token whenever it changes
    useEffect(() => {
        console.log('Token received:', token);
    }, [token]);

    const fetchExistingTreatmentRows = () => {
        setLoading(true); // Set loading to true when fetching starts
        const payload = { fileId: fileId };

        fetch(urls.fetchtreatmentrows, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
        .then(response => response.ok ? response.json() : Promise.reject('Failed to fetch existing treatment rows'))
        .then(data => {
            if (data && data.length > 0) {
                const existingRows = data.map(row => ({
                    route: row.route,
                    drug: row.drug_name,
                    dosage: row.dosage,
                    frequency: row.frequency,
                    duration: row.duration,
                    durationUnit: row.duration_unit,
                    quantity: row.quantity,
                    packaging: row.packaging
                }));
                setTreatmentPlanRows([...existingRows, createEmptyRow()]);
            } else {
                setTreatmentPlanRows([createEmptyRow()]);
            }
            setLoading(false); // Set loading to false after data is fetched
        })
        .catch(error => {
            console.error('Error fetching existing treatment rows:', error);
            setLoading(false); // Set loading to false if there's an error
        });
    };

    useEffect(() => {
        const payload = { token: token };

        fetch(urls.fetchoriginaldrugs, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => setDrugSuggestions(data))
        .catch(error => console.error('Error fetching drug suggestions:', error));
    }, [token]);

    function createEmptyRow() {
        return {
            drug: '',
            packaging: '',
            route: '',
            dosage: '',
            frequency: '',
            duration: '',
            durationUnit: '',
            quantity: ''
        };
    }

    const handleDrugInputChange = (index, event) => {
        const value = event.target.value;
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = value;
    
        // Auto-fill packaging based on selected drug
        const matchingDrug = drugSuggestions.find(drug => drug.drug_name.toLowerCase() === value.toLowerCase());
        if (matchingDrug) {
            updatedRows[index].packaging = matchingDrug.packaging;
        } else {
            updatedRows[index].packaging = ''; // Clear packaging if no match
        }
    
        setTreatmentPlanRows(updatedRows);
        setChangesMade(true); // Track changes
    
        if (value) {
            const filtered = drugSuggestions.filter(drug =>
                drug.drug_name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setActiveSuggestionIndex(index);
        } else {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(null);
        }
    };
    

    const handleDrugQuantityChange = (index, event) => {
        const inputValue = event.target.value;
        const columnName = event.target.name;

        if (columnName === 'quantity' && !/^\d*$/.test(inputValue)) {
            return;
        }

        const newTreatmentPlanRows = [...treatmentPlanRows];
        newTreatmentPlanRows[index][columnName] = inputValue;
        setTreatmentPlanRows(newTreatmentPlanRows);
        setChangesMade(true); // Changes made flag
    };

   
    
    const renderDrugSuggestions = (index) => {
        return (
            <datalist id={`suggestions-list-${index}`}>
                {filteredSuggestions.map((suggestion, i) => (
                    <option 
                        key={i} 
                        value={suggestion.drug_name} 
                    >
                        {suggestion.drug_name}
                    </option>
                ))}
            </datalist>
        );
    };
    
    

    const handleSuggestionClick = (index, suggestion) => {
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = suggestion.drug_name;
        updatedRows[index].packaging = suggestion.packaging; // Set packaging from suggestion
        setTreatmentPlanRows(updatedRows);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(null);
        setChangesMade(true); // Track changes
    };

    const handleTreatmentPlanChange = (index, field, value) => {
        const newRows = [...treatmentPlanRows];
        newRows[index][field] = value;
        setTreatmentPlanRows(newRows);
        setChangesMade(true); // Changes made flag
    };

    const addNewRow = async (index) => {
        // Validate existing rows
        if (!validateRows(treatmentPlanRows)) {
            toast.error('Failed, some parts of the prescription are missing. Ensure that all rows and columns of the prescription table have been filled.');
            return;
        }
        
    
        const newRows = [...treatmentPlanRows];
        newRows.splice(index + 1, 0, createEmptyRow()); // Insert new empty row
        setTreatmentPlanRows(newRows); // Update state with new rows
        setChangesMade(true); // Mark as changed
    
        // Prepare payload for backend
        const treatmentRowPayload = newRows.map(row => ({
            fileId: fileId,
            route: row.route,
            drug: row.drug,
            dosage: row.dosage,
            frequency: row.frequency,
            duration: row.duration,
            durationUnit: row.durationUnit,
            quantity: row.quantity,
            packaging: row.packaging
        }));
    
        try {
            const response = await fetch(urls.treatmentrow, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(treatmentRowPayload),
            });
    
            if (!response.ok) {
                throw new Error('Failed to add new treatment rows');
            }
    
            console.log('New treatment rows added successfully');
        } catch (error) {
            console.error('Error updating treatment rows after deletion:', error);
            toast.error('The changes are not updating. Please check your network connectivity.');
        }
    
        // Scroll to new row
        setTimeout(() => {
            const rowElement = document.getElementById(`row-${index + 1}`);
            if (rowElement) {
                rowElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };
    

    const handleDeleteRow = async (index) => {
        // Check if there is only one row, and if so, prevent deletion
        if (treatmentPlanRows.length === 1) {
            toast.error('Cannot delete the First row. There must be at least one row.');
            return;
        }
    
        // Remove the row from the state
        const updatedRows = treatmentPlanRows.filter((_, rowIndex) => rowIndex !== index);
        setTreatmentPlanRows(updatedRows);
    
        // Prepare the payload with the remaining rows
        const treatmentRowPayload = updatedRows.map(row => ({
            fileId: fileId,
            route: row.route,
            drug: row.drug,
            dosage: row.dosage,
            frequency: row.frequency,
            duration: row.duration,
            durationUnit: row.durationUnit,
            quantity: row.quantity,
            packaging: row.packaging
        }));
    
        console.log("Payload to be submitted to treatmentRow after deletion:", treatmentRowPayload);
    
        try {
            // Send the updated payload to the backend
            const response = await fetch(urls.treatmentrow, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(treatmentRowPayload),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update treatment rows after deletion');
            }
    
            console.log('Treatment rows updated successfully after deletion');
        } catch (error) {
            console.error('Error updating treatment rows after deletion:', error);
            toast.error('The changes are not updating. Please check your network connectivity.');
        }
    };
    
    
    const validateRows = () => {
        let allRowsEmpty = true;

        for (const row of treatmentPlanRows) {
            const isRowFilled = row.route && row.drug && row.dosage && row.frequency && row.duration && row.durationUnit && row.quantity;

            if (isRowFilled) {
                allRowsEmpty = false;
            } else if (
                row.route ||
                row.drug ||
                row.dosage ||
                row.frequency ||
                row.duration ||
                row.durationUnit ||
                row.quantity
            ) {
                return false; // Some fields are filled, but not all
            }
        }

        return !allRowsEmpty; // Returns false if all rows are empty
    };

    const handleTreatmentPlanSubmit = async () => {
        if (!validateRows()) {
            toast.error('Failed, some parts of the prescription are missing. Ensure that all rows and columns of the prescription table have been filled.', {
                duration: 10000,
            });
            return;
        }
        
    
        setSubmitting(true);
    
        const employeeSentence = `By Doctor (${employeeName})`;
        const updatedTreatmentPlan = treatmentPlanRows.map(constructTreatmentSentence).join('\n');
        const updatedTreatmentPlanWithEmployee = `${updatedTreatmentPlan}\n${employeeSentence}`;
    
        const submitChangesPayload = {
            fileId: fileId,
            treatment_plan: updatedTreatmentPlanWithEmployee
        };
    
        try {
            // Submit changes payload
            const submitResponse = await fetch(urls.submitchanges, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(submitChangesPayload),
            });
    
            if (!submitResponse.ok) {
                toast.error('Failed to submit treatment plan changes');
                throw new Error('Failed to submit treatment plan changes');
            }
            
            toast.success('Changes submitted successfully!');
            
    
            // Prepare treatment row payload
            const treatmentRowPayload = treatmentPlanRows.map(row => ({
                fileId: fileId,
                route: row.route,
                drug: row.drug,
                dosage: row.dosage,
                frequency: row.frequency,
                duration: row.duration,
                durationUnit: row.durationUnit,
                quantity: row.quantity,
                packaging: row.packaging
            }));
    
            console.log("Payload to be submitted to treatmentRow:", treatmentRowPayload);
    
            // Submit treatment rows payload
            const treatmentRowResponse = await fetch(urls.treatmentrow, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(treatmentRowPayload),
            });
    
            if (!treatmentRowResponse.ok) {
                throw new Error('Failed to add new treatment rows');
            }
    
            console.log('New treatment rows added successfully');
    
            // Reset changesMade to false after successful responses
            setChangesMade(false);
    
        } catch (error) {
            console.error('Error during submission:', error);
            toast.error('Oops! Something went wrong. Please try checking your network connectivity.');
        
        } finally {
            setSubmitting(false);
        }
    };
    
    const constructTreatmentSentence = (row) => {
        return `• ${row.route} ${row.drug} ${row.dosage} ${row.frequency} ${row.duration} ${row.durationUnit} ----(${row.quantity} ${row.packaging})`;
    };

    const handleSeeBillClick = () => {
        const nonEmptyRows = treatmentPlanRows.filter(row => row.drug && row.quantity); // Filter out empty rows
        const billData = nonEmptyRows.map(row => ({
            drug: row.drug,
            packaging: row.packaging,
            route: row.route,
            quantity: row.quantity
        }));
        setBillData(billData);
        setShowMedicalBillModal(true);
    };
    const handleCloseModal = () => {
        if (changesMade) {
            const confirmClose = window.confirm("You have unsaved changes. Are you sure you want to close without updating? If you proceed, your changes will not be saved.");
            if (confirmClose) {
                onClose();
            } else {
               
                
            }
            
        } else {
            onClose();
        }
    };
    
    return (
        <div className="treatment-plan-container">
            <ToastContainer />
            <h3>Treatment Plan</h3>
            {loading ? ( // Conditionally render loading state or content
                <div>Loading...</div>
            ) : (
                <>
                    <div>
                        {treatmentPlanRows.map((row, index) => (
                            <p key={index}>
                                {constructTreatmentSentence(row)}
                            </p>
                        ))}
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Route</th>
                                    <th>Drug</th>
                                    <th>Dosage</th>
                                    <th>Frequency</th>
                                    <th>Duration</th>
                                    <th>Drug Quantity</th>
                                    <th>Actions</th> {/* Ensure this header is added */}
                                </tr>
                            </thead>
                            <tbody>
                                {treatmentPlanRows.map((row, index) => (
                                    <tr key={index} id={`row-${index}`}>
                                        <td style={cellStyle}>
                                            <select
                                                value={row.route}
                                                onChange={e => handleTreatmentPlanChange(index, 'route', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="IV">IV</option>
                                                <option value="IM">IM</option>
                                                <option value="Tablets">Tablets</option>
                                                <option value="Syrup">Syrup</option>
                                                <option value="Capsules">Capsules</option>
                                                <option value="Topical">Topical</option>
                                                <option value="Rectal">Rectal</option>
                                                <option value="Vaginal">Vaginal</option>
                                                <option value="Sublingual">Sublingual</option>
                                                <option value="Eyes">Eyes</option>
                                                <option value="Inhalation">Inhalation</option>
                                                <option value="Sub-Cuteneous">Sub-Cuteneous</option>
                                                <option value="Intra-Dermal">Intra-Dermal</option>
                                                <option value="ear">ear</option>
                                            </select>
                                        </td>
                                        <td style={cellStyle}>
                                            <div className="drug-input-container">
                                                <input
                                                    type="text"
                                                    value={row.drug}
                                                    onChange={e => handleDrugInputChange(index, e)}
                                                    onFocus={() => setFilteredSuggestions([])}
                                                    onClick={() => setActiveSuggestionIndex(index)}
                                                    list={`suggestions-list-${index}`}
                                                />
                                                {renderDrugSuggestions(index)} {/* Pass index here */}
                                            </div>
                                        </td>
                                        <td style={cellStyle}>
                                            <input
                                                type="text"
                                                value={row.dosage}
                                                onChange={e => handleTreatmentPlanChange(index, 'dosage', e.target.value)}
                                            />
                                        </td>
                                        <td style={cellStyle}>
                                            <select
                                                value={row.frequency}
                                                onChange={e => handleTreatmentPlanChange(index, 'frequency', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="once a day for">Once a day for</option>
                                                <option value="once noct for">Once Noct for</option>
                                                <option value="twice daily for">Twice Daily for</option>
                                                <option value="stat for">Stat for</option>
                                                <option value="tds for">tds for</option>
                                                <option value="12 hourly for">12 Hourly for</option>
                                                <option value="24 hourly for">24 Hourly for</option>
                                                <option value="8 hourly for">8 Hourly for</option>
                                                <option value="2 hourly for">2 Hourly for</option>
                                                <option value="prn for">pro re nata for</option>
                                                <option value="BID (bis in die) for">BID bis in die for</option>
                                                <option value="QD (quaque die) for">QD quaque die for</option>
                                            </select>
                                        </td>
                                        <td style={cellStyle}>
                                            <input
                                                type="number"
                                                value={row.duration}
                                                onChange={e => handleTreatmentPlanChange(index, 'duration', e.target.value)}
                                                placeholder="Enter duration number"
                                            />
                                            <select
                                                value={row.durationUnit}
                                                onChange={e => handleTreatmentPlanChange(index, 'durationUnit', e.target.value)}
                                            >
                                                <option value="">Select Duration Unit</option>
                                                <option value="days">Days</option>
                                                <option value="weeks">Weeks</option>
                                                <option value="months">Months</option>
                                                <option value="doses">Doses</option>
                                                
                                                <option value="days in">Days in</option>
                                                <option value="weeks in">Weeks in</option>
                                                <option value="months in">Months in</option>
                                                <option value="doses in">Doses in</option>
                                                
                                                <option value="days then">Days then</option>
                                                <option value="weeks then">Weeks then</option>
                                                <option value="months then">Months then</option>
                                                <option value="doses then">Doses then</option>
                                            </select>
                                        </td>
                                        <td style={{...cellStyle, display: 'flex', alignItems: 'center'}}>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={row.quantity || "0"}
                                                onChange={e => handleDrugQuantityChange(index, e)}
                                                placeholder="000"
                                                pattern="\d*"
                                                style={{ width: "80px" }}
                                            />
                                            <span>{` ${row.packaging}`}</span>
                                        </td>
                                        <td style={cellStyle} className="row-actions"> {/* Ensure actions are placed here */}
                                            <button className="delete-button" onClick={() => handleDeleteRow(index)}>X</button>
                                            <button className="add-button" onClick={() => addNewRow(index)}>+</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            <div className="actions">
                <button className="add-row-button" onClick={addNewRow}>+</button>
                <button onClick={handleTreatmentPlanSubmit} disabled={submitting}>
                    {submitting ? 'Please wait...' : 'Update Treatment Plan'}
                </button>
                <button onClick={handleSeeBillClick}>See Patient Bill</button> {/* See Bill Button */}
                {showMedicalBillModal && (
                    <>
                        <div className="medical-bill-modal-overlay" onClick={() => setShowMedicalBillModal(false)}></div>
                        <MedicalBillModal token={token} fileId={fileId} billData={billData} onClose={() => setShowMedicalBillModal(false)} />
                    </>
                )}
                <button className="btn btn-danger" onClick={handleCloseModal}>
                    Close
                </button>
            </div>
        </div>
    );
    
}

export default TreatmentPlanModal;
