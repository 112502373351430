import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './AwaitingPayments.css';
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvestigationReceiptModal from './InvestigationReceiptModal'; // Import the receipt modal

function AwaitingPayments() {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [showReceiptModal, setShowReceiptModal] = useState(false); // State for showing receipt modal
  const [receiptDetails, setReceiptDetails] = useState(null); // Store receipt details
  const [selectedPatient, setSelectedPatient] = useState(null); // Store the selected patient for receipt
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);

            // Fetch awaiting payments after security check
            fetchAwaitingPayments(tokenFromUrl);
            const intervalId = setInterval(() => fetchAwaitingPayments(tokenFromUrl), 3000);

            return () => clearInterval(intervalId); // Clear interval on component unmount
          } else if (securityData.error === 'Session expired') {
            toast.warning('Session expired, redirecting to the dashboard...');
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            toast.error('Session invalid, redirecting to login...');
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        toast.error('Error performing security check, redirecting to login...');
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchAwaitingPayments = async (token) => {
    try {
      const response = await fetch(urls.waitingpayment, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setPatients(data);
      } else {
        throw new Error('Failed to fetch awaiting payments');
      }
    } catch (error) {
      console.error('Error fetching awaiting payments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPayment = async (patient) => {
    try {
      setConfirmingPayment(true);
      setSelectedPatient(patient); // Set the selected patient for the receipt

      const tokenFromUrl = urlToken;
      const { lab_tests, radiology_exams } = patient;
      let tests = [];
      if (lab_tests) {
        tests = tests.concat(lab_tests);
      }
      if (radiology_exams) {
        tests = tests.concat(radiology_exams);
      }

      const totalBill = tests.reduce((acc, test) => {
        const price = parseFloat(test.price);
        return isNaN(price) ? acc : acc + price;
      }, 0);

      const payload = {
        ...patient,
        totalBill, // Include total amount in the payload
        token: tokenFromUrl,
      };

      const response = await fetch(urls.payinvestigations, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success('Payment successful');
        setReceiptDetails({ ...patient, tests, totalBill }); // Set receipt details
        setShowReceiptModal(true); // Show receipt modal
      } else {
        throw new Error('Failed to confirm payment');
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
      toast.error('Failed to confirm payment. Please try again.');
    } finally {
      setConfirmingPayment(false);
    }
  };

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false); // Close receipt modal
  };

  return (
     <div className="awaiting-payments-container" style={{ paddingTop: '70px' }}>
      <Topbar token={urlToken} />
      <ToastContainer />
      <div className="awaiting-payments-content">
      <h2 className="awaiting-payments-title" style={{ textAlign: 'center' }}>
  Patients Awaiting Payments for Laboratory or Radiology Services
</h2>

        {loading ? (
          <div className="awaiting-payments-loading">
            <div className="awaiting-payments-spinner"></div>
            <p>Loading...</p>
          </div>
        ) : patients.length === 0 ? (
          <p className="awaiting-payments-no-patients">No patients awaiting payments.</p>
        ) : (
          <div className="awaiting-payments-list">
            {patients.map((patient, index) => {
  const { patient_name, file_id, contact_id, lab_tests, radiology_exams } = patient;
  let tests = [];
  if (lab_tests) {
    tests = tests.concat(lab_tests);
  }
  if (radiology_exams) {
    tests = tests.concat(radiology_exams);
  }

  const totalBill = tests.reduce((acc, test) => {
    const price = parseFloat(test.price);
    return isNaN(price) ? acc : acc + price;
  }, 0);

  return (
    <div key={index} className="lab-dashboard-container">
      <div className="patient-details">
        <p><strong>Name:</strong> {patient_name}</p>
        <p><strong>File ID:</strong> {file_id}</p>
        <p><strong>Contact ID:</strong> {contact_id}</p>
        <h4 className="awaiting-payments-tests-title">Tests/Exams</h4>
        {tests.map((test, index) => (
          <p key={index}>
            {test.name}: UGX {parseFloat(test.price).toFixed(2)}
          </p>
        ))}
        <p><strong>Total Bill:</strong> UGX {totalBill.toFixed(2)}</p>
      </div>
      <button
      style={{
        marginTop: '0px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        opacity: confirmingPayment ? 0.6 : 1,
        pointerEvents: confirmingPayment ? 'none' : 'auto',
        
      }}
      onClick={() => handleConfirmPayment(patient)}
      disabled={confirmingPayment}
    >
      {confirmingPayment && selectedPatient === patient
        ? 'Processing Payment...'
        : 'Pay'}
    </button>
    </div>
  );
})}
          </div>
        )}
      </div>


      {/* Render receipt modal */}
      {showReceiptModal && (
  <InvestigationReceiptModal
    isOpen={showReceiptModal}
    onClose={handleCloseReceiptModal}
    details={receiptDetails}
    token={urlToken}  // Pass the token as a prop
  />
)}
    </div>
  );
}

export default AwaitingPayments;
