import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import './Malariagraph.css';

// Register the components with Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Malariagraph() {
  const [dataAvailable, setDataAvailable] = useState(true);
  const [selectedAgeRange, setSelectedAgeRange] = useState({ min: 0, max: 120 });
  const [selectedSex, setSelectedSex] = useState('Both');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDisease, setSelectedDisease] = useState('Malaria');
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();
    performSecurityCheck(token);
  }, [selectedAgeRange, selectedSex, selectedYear, selectedDisease]);

  const getTokenFromUrlOrLocalStorage = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    return tokenFromUrl || localStorage.getItem('token');
  };

  const performSecurityCheck = async (token) => {
    try {
      setLoading(true); // Start loading spinner
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          fetchData(token);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const fetchData = async (token) => {
    try {
      setLoading(true); // Start loading spinner
      const response = await fetch(urls.diseasebargraph, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          ageRange: selectedAgeRange,
          sex: selectedSex,
          year: selectedYear,
          disease: selectedDisease,
        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error('Error fetching data:', data.error);
        setDataAvailable(false);
      } else {
        const counts = [
          data.diseaseCounts.January,
          data.diseaseCounts.February,
          data.diseaseCounts.March,
          data.diseaseCounts.April,
          data.diseaseCounts.May,
          data.diseaseCounts.June,
          data.diseaseCounts.July,
          data.diseaseCounts.August,
          data.diseaseCounts.September,
          data.diseaseCounts.October,
          data.diseaseCounts.November,
          data.diseaseCounts.December,
        ];

        setBarChartData(counts);
        setDataAvailable(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setDataAvailable(false);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleAgeRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedAgeRange({
      ...selectedAgeRange,
      [name]: parseInt(value),
    });
  };

  const handleSexChange = (event) => {
    setSelectedSex(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDiseaseChange = (event) => {
    setSelectedDisease(event.target.value);
  };

  const printGraph = () => {
    window.print();
  };

  const barChartDataConfig = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: `${selectedDisease} cases of ${selectedSex} sex for ${selectedAgeRange.min}-${selectedAgeRange.max} years for the year ${selectedYear} `,
        backgroundColor: 'Blue',
        borderColor: 'Blue',
        borderWidth: 1,
        hoverBackgroundColor: 'Orange',
        hoverBorderColor: 'Orange',
        data: barChartData,
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Disease cases of ${selectedDisease} for ${selectedAgeRange.min}-${selectedAgeRange.max} years`
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${selectedYear} Months`
        }
      },
      y: {
        title: {
          display: true,
          text: `${selectedDisease} cases`
        }
      }
    }
  };

  return (
    <div className="malariagraph-container">
      <h1>Bar Graph: {selectedDisease} cases of {selectedSex} sex aged {selectedAgeRange.min}-{selectedAgeRange.max} years for year {selectedYear}</h1>
      <div className="selections-container">
        <div className="selection-group">
          <label>Select Age Range:</label>
          <input
            type="number"
            name="min"
            value={selectedAgeRange.min}
            onChange={handleAgeRangeChange}
          />
          <span>to</span>
          <input
            type="number"
            name="max"
            value={selectedAgeRange.max}
            onChange={handleAgeRangeChange}
          />
        </div>
        <div className="selection-group">
          <label>Select Sex:</label>
          <select value={selectedSex} onChange={handleSexChange}>
            <option value="Both">Both Male and Female</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="selection-group">
          <label>Select Year:</label>
          <input
            type="number"
            value={selectedYear}
            onChange={handleYearChange}
          />
        </div>
        <div className="selection-group">
          <label>Select Disease:</label>
          <input
            type="text"
            value={selectedDisease}
            onChange={handleDiseaseChange}
          />
        </div>
      </div>
      <div className="bar-chart-container">
        {loading ? (
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin size="10x" />
          </div>
        ) : dataAvailable ? (
          <Bar data={barChartDataConfig} options={barChartOptions} />
        ) : (
          <p>No data found</p>
        )}
      </div>
      <button className="print-button" onClick={printGraph}>Print Graph</button>
    </div>
  );
}

export default Malariagraph;
