import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Payment.css';

function Payment() {
    const [formData, setFormData] = useState({
        amount: '',
    });

    const [clinicDetails, setClinicDetails] = useState({
        healthFacilityName: '',
        country: '',
        district: '',
        currentBalance: 0,
    });

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClinicDetails = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const token = params.get('token');

                const response = await fetch(urls.fetchpaymentdetails, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch clinic details');
                }

                const data = await response.json();
                setClinicDetails({
                    healthFacilityName: data.clinic_name,
                    country: data.country,
                    district: data.district,
                    currentBalance: data.currentBalance,
                });
            } catch (error) {
                console.error('Error fetching clinic details:', error);
                setError('Failed to fetch clinic details');
                navigate('/login');
            }
        };

        fetchClinicDetails();
    }, [navigate]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (formData.amount.trim() === '') {
            setError('Please enter the amount.');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        try {
            setLoading(true);

            const flutterSubmitPayload = {
                amount: formData.amount,
                currency: 'USD',
                clinicName: clinicDetails.healthFacilityName,
                email: email,
            };

            const response = await fetch(urls.flutter_submit, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(flutterSubmitPayload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();
            const { status, message, data: { link } } = JSON.parse(data.raw_response);

            if (status === 'success' && message === 'Hosted Link' && link) {
                const txRef = data.tx_ref;

                const clinicPayload = {
                    txRef: txRef,
                    clinicName: clinicDetails.healthFacilityName,
                };

                const clinicResponse = await fetch(urls.recordtransaction, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(clinicPayload),
                });

                if (!clinicResponse.ok) {
                    throw new Error('Failed to record transaction.');
                }

                const clinicData = await clinicResponse.json();
                console.log('Transaction recorded successfully:', clinicData);

                setTimeout(() => {
                    window.location.href = link;
                }, 1000);
            } else {
                throw new Error('Invalid response or missing Hosted Link.');
            }
        } catch (error) {
            console.error('Error in handleFormSubmit:', error);
            setError('There was an error processing your request. Please check the console for details.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail(value);
        setEmailError('');
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    const renderField = (label, value) => (
        <div className="payment-field">
            <label>{label}:</label>
            <span>{value}</span>
        </div>
    );

    return (
        <div className="payment-container mt-5">
            <h2 className="payment-heading">Payment Details</h2>
            {error && <p className="payment-error-message">{error}</p>}
            <form onSubmit={handleFormSubmit}>
                {renderField('Health Facility Name', clinicDetails.healthFacilityName)}
                {renderField('Country', clinicDetails.country)}
                {renderField('District', clinicDetails.district)}
                <div className="payment-instructions-toggle" onClick={toggleInstructions}>
                    <span>Click here to see tips on how to Pay</span>
                    <FontAwesomeIcon icon={showInstructions ? faChevronUp : faChevronDown} />
                </div>
                {showInstructions && (
                    <div className="payment-instructions">
                       <h3>Tips on how to Pay</h3>
                        <ol>
                            <li>Please ensure you have a stable network connection to avoid disconnection in the middle of the transaction.</li>
                            <li>Fill in your email and the amount you want to pay to Clinic Pro.</li>
                            <li>Press "Submit Details".</li>
                            <li>You will be redirected to a secure payment page where you need to insert your mobile number or visa card number, then press "Pay".</li>
                            <li>After pressing "Pay", you will receive an OTP (One Time Password) via SMS, WhatsApp, or email.</li>
                            <li>Fill in the OTP in the space provided and press "Validate".</li>
                            <li>If you chose to pay with mobile money, you might be prompted to insert your Mobile Money PIN.</li>
                            <li>After inserting your PIN, you will see a payment successful message. Please do not close the payment page until you have been automatically redirected back to the Clinic Pro login page.</li>
                        </ol>
                    </div>
                )}
                <label className="payment-label">
                    Email:
                    <input type="email" name="email" value={email} onChange={handleEmailChange} />
                    {emailError && <p className="payment-error-message">{emailError}</p>}
                </label>
                <label className="payment-label">
                    Amount (UGX):
                    <input type="number" name="amount" value={formData.amount} onChange={handleInputChange} />
                    {error && <p className="payment-error-message">{error}</p>}
                </label>
                <button type="submit" className="payment-button" disabled={loading}>
                    {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                        'Submit Details'
                    )}
                </button>
            </form>
        </div>
    );
}

export default Payment;
