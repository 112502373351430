import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './getdrugs.css'; // Update the CSS file name if necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import cpsImage from './images/cps.PNG'; // Correctly import the image
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component

function GetDrugs() {
  const [stockData, setStockData] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [quantityToTake, setQuantityToTake] = useState(0);
  const [takenItems, setTakenItems] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isTakeButtonActive, setIsTakeButtonActive] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');
  const [clinicName, setClinicName] = useState('');
  
  const urlToken = params.get('token'); // Define urlToken here
  
  
  
  
  const performSecurityCheck = async (token) => {
    try {
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          setEmployeeName(securityData.employee_name);
          setClinicName(securityData.clinic); // Set the clinic name
          return true; // Security check passed
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          return false; // Security check failed due to session expired
        } else {
          navigate('/login');
          return false; // Security check failed
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
      return false; // Security check failed
    }
  };
  
    useEffect(() => {
      const fetchDispensaryStock = async (token) => {
        try {
          const response = await fetch(urls.fetchdispensary, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }), // Include token in the request body
          });
          const data = await response.json();
          setStockData(data);
        } catch (error) {
          console.error('Error fetching dispensary stock:', error);
        }
      };
    
      const fetchTokenAndCheckSecurity = async () => {
        try {
          const params = new URLSearchParams(window.location.search);
          const tokenFromUrl = params.get('token');
          if (!tokenFromUrl) {
            throw new Error('Token not found in URL');
          }
    
          const securityPassed = await performSecurityCheck(tokenFromUrl);
    
          if (securityPassed) {
            fetchDispensaryStock(tokenFromUrl);
            // Set interval to fetch stock data every 5 seconds with the token
            const intervalId = setInterval(() => fetchDispensaryStock(tokenFromUrl), 5000);
            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Error fetching token and checking security:', error);
          navigate('/login');
        }
      };
    
      fetchTokenAndCheckSecurity();
    }, [navigate]);
    

  const handleDrugSelect = (drug) => {
    setSelectedDrug(drug);
    setQuantityToTake(1);
    setSearchQuery('');
    setConfirmClicked(false); // Deactivate the "Confirm Take" button when a drug is selected
  };
  

  const handleTakeButtonClick = () => {
    if (!selectedDrug) {
      alert('Please select a drug.');
      return;
    }
    const newTakenItem = { 
      drug: selectedDrug.Drug, 
      packaging: selectedDrug.Packaging, 
      quantity: quantityToTake, 
      served_by: employeeName 
    };
    setTakenItems([...takenItems, newTakenItem]);
    setSelectedDrug(null);
    setQuantityToTake(0);
    setIsTakeButtonActive(false); // Deactivate the "Take" button
    setConfirmClicked(true); // Activate the "Confirm Take" button
  };
  
  const handleRemoveTakenItem = (index) => {
    const updatedTakenItems = takenItems.filter((_, idx) => idx !== index);
    setTakenItems(updatedTakenItems);
  };

  const confirmTake = async () => {
    setConfirmClicked(true);
    
    // Extract token from URL
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
  
    const confirmation = window.confirm(`Are you sure you want to take these items?\n\n${takenItems.map(item => `${item.quantity} ${item.packaging} ${item.drug}`).join('\n')}`);
    
    if (confirmation) {
      try {
        const payload = {
          token: tokenFromUrl,
          takenItems: takenItems
        };
  
        const response = await fetch(urls.takedrugs, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        const data = await response.json();
        
        if (response.ok) {
          alert(data.message);
          // Reset all parts of the table to initial states
          setTakenItems([]);
          setSelectedDrug(null);
          setQuantityToTake(0);
          setSearchQuery('');
          setIsTakeButtonActive(false);
          setConfirmClicked(false);
        } else {
          alert(data.error);
          setConfirmClicked(false); // Re-enable the "Confirm Take" button
        }
      } catch (error) {
        console.error('Error taking drugs:', error);
        alert('An error occurred while taking drugs.');
        setConfirmClicked(false); // Re-enable the "Confirm Take" button
      }
    } else {
      setConfirmClicked(false);
      alert('Take action canceled.');
    }
  };
  
  
  // Filter stockData based on searchQuery
  const filteredStockData = stockData.filter(item =>
    item.Drug.toLowerCase().includes(searchQuery.toLowerCase())
  );
 
   
   
    useEffect(() => {
      setIsTakeButtonActive(!!selectedDrug);
    }, [selectedDrug]);
     
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#ffffff' }} className="container-selling-drugs">
      <Topbar token={urlToken} />
      <div className="image-container">
        <img src={cpsImage} alt="Description" />
      </div>
      <div className="content">
        <div style={{ display: 'flex' }}>
          <div className="container-get-drugs">
            <h2>Get Drugs/Equipment</h2>
            <table>
              <thead>
                <tr>
                  <th>Drug/Equipment</th>
                  <th>Quantity to Take</th>
                  <th>Served By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {takenItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.drug}</td>
                    <td>{item.quantity}</td>
                    <td>{item.served_by}</td>
                    <td><button 
          onClick={() => handleRemoveTakenItem(index)} 
          style={{ backgroundColor: 'red', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
        >
          X
        </button></td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <input
                      type="text"
                      value={selectedDrug ? selectedDrug.Drug : ''}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={quantityToTake}
                      onChange={(e) => setQuantityToTake(e.target.value)}
                    />
                  </td>
                  <td>
                    <button onClick={handleTakeButtonClick} disabled={!selectedDrug || !isTakeButtonActive}>
                      <FontAwesomeIcon icon={faCartPlus} /> Take
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            {takenItems.length > 0 && (
              <button onClick={confirmTake} disabled={!confirmClicked}>
                {confirmClicked ? 'Take the selected Drugs' : 'Confirm Take'}
              </button>
            )}
          </div>
          <div className="container-stock">
            <h2>Dispensary Stock</h2>
            <input
              className="selldrugs-search-input"
              type="text"
              placeholder="Search drugs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Drug/Equipment</th>
                  <th>Quantity</th>
                  <th>Packaging</th>
                </tr>
              </thead>
              <tbody>
                {filteredStockData.map((item, index) => (
                  <tr key={`${item.Drug}-${index}`} onClick={() => handleDrugSelect(item)}>
                    <td>{item.Drug}</td>
                    <td>{item.Quantity}</td>
                    <td>{item.Packaging}</td>
                  </tr>
                ))}
                {filteredStockData.length === 0 && (
                  <tr key="no-results">
                    <td colSpan="3">No matching drugs found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default GetDrugs;