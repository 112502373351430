import React, { useState } from 'react';
import './RadiologyResultModal.css';
import { urls } from './config.dev';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RadiologyResultModal({ patient, onClose, clinicDetails, token, totalRadiologyExams }) {
  const [results, setResults] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [error, setError] = useState('');
  const [formatting, setFormatting] = useState({
    bold: false,
    italic: false,
    underlined: false,
    fontSize: '14px', // Default font size
  });

  const handleInputChange = (event) => {
    setResults(event.target.value);
  };

  const toggleFormatting = (format) => {
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      [format]: !prevFormatting[format],
    }));
  };

  const handleFontSizeChange = (event) => {
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      fontSize: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const requestBody = {
        file_id: patient.file_id,
        contact_id: patient.contact_id,
        results,
        formatting,
        totalRadiologyExams,
        token, // Include token in the request body
      };

      const response = await fetch(urls.submitradiologyresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success('Radiology results submitted successfully!');
        // Ask user if they want to print the report
        if (window.confirm('Do you want to print a report?')) {
          handlePrint();
        } else {
          onClose();
        }
      } else {
        throw new Error('Failed to submit radiology results');
      }
    } catch (error) {
      console.error('Error submitting radiology results:', error);
      toast.error('Error submitting radiology results');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrint = () => {
    setPrintLoading(true);

    // Prepare the data to be sent to the backend
    const printData = {
      clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
      contact: clinicDetails?.owners_contact || 'No Contact Available',
      location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
      patientName: `${patient.first_name} ${patient.last_name}`,
      patientAge: patient.age,
      patientSex: patient.sex,
      radiologyTests: patient.radiology_exam || [], // Ensure radiologyTests is an array
      radiologyResults: results.trim().split('\n').map(result => result.trim()), // Format results
    };

    // Send data to the backend to generate the PDF
    fetch(urls.pdfscan2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(printData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to generate PDF: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank'); // Open PDF in a new tab
        toast.success('PDF report generated successfully!');
      })
      .catch(error => {
        setError("Failed to generate PDF");
        console.error("PDF generation error:", error);
        toast.error('Failed to generate PDF report');
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

  return (
    <div className="radiology-modal-overlay">
      <div className="radiology-modal-content">
        <div className="radiology-header">
          <h1>{clinicDetails.clinic_name}</h1>
          <p>{clinicDetails.sub_county}</p>
          <h2>Radiology Results</h2>
          <h3>{`${patient.first_name} ${patient.last_name}`}</h3>
        </div>
        <div className="radiology-patient-details">
          <h3>Patient Details</h3>
          <p><strong>Name:</strong> {`${patient.first_name} ${patient.last_name}`}</p>
          <p><strong>Age:</strong> {patient.age}</p>
          <p><strong>Sex:</strong> {patient.sex}</p>
        </div>
        <div className="radiology-formatting-buttons">
          <button
            className={formatting.bold ? 'radiology-active-button' : ''}
            onClick={() => toggleFormatting('bold')}
          >
            Bold
          </button>
          <button
            className={formatting.italic ? 'radiology-active-button' : ''}
            onClick={() => toggleFormatting('italic')}
          >
            Italic
          </button>
          <button
            className={formatting.underlined ? 'radiology-active-button' : ''}
            onClick={() => toggleFormatting('underlined')}
          >
            Underline
          </button>
          <select onChange={handleFontSizeChange} className="radiology-font-size-select">
            <option value="12px">Font Size: 12</option>
            <option value="14px">Font Size: 14</option>
            <option value="16px">Font Size: 16</option>
            <option value="18px">Font Size: 18</option>
            <option value="20px">Font Size: 20</option>
          </select>
        </div>
        <div className="radiology-input-area">
          <h3>Enter Radiology Results</h3>
          <textarea
            value={results}
            onChange={handleInputChange}
            placeholder="Type your results here..."
            className={`radiology-results-input ${formatting.bold ? 'radiology-bold' : ''} ${formatting.italic ? 'radiology-italic' : ''} ${formatting.underlined ? 'radiology-underlined' : ''}`}
            style={{ fontSize: formatting.fontSize }}
          />
        </div>
        <div className="radiology-button-area">
          <button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Please wait...' : 'Submit Report'}
          </button>
          <button onClick={onClose} disabled={isSubmitting}>Cancel</button>
         {/* <button onClick={handlePrint} disabled={printLoading}>
            {printLoading ? 'Generating PDF...' : 'Print Report'}
          </button>*/}
          {error && <p className="radiology-error">{error}</p>}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RadiologyResultModal;
