import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './LabDashboard.css';
import Topbar from './Topbar'; // Import the Topbar component

function LabDashboard() {
  const [pendingLabTestsCount, setPendingLabTestsCount] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            fetchPendingLabTestsCount(tokenFromUrl); // Fetch pending lab tests count after security check
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchPendingLabTestsCount = async (token) => {
    try {
      const response = await fetch(urls.labtestscount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setPendingLabTestsCount(data.count);
      } else {
        throw new Error('Failed to fetch pending lab tests count');
      }
    } catch (error) {
      console.error('Error fetching pending lab tests count:', error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    if (tokenFromUrl) {
      const interval = setInterval(() => fetchPendingLabTestsCount(tokenFromUrl), 10000); // Fetch every 10 seconds
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, []);

  const handleGoToLabTestsDoneToday = () => {
    // Implement the logic to navigate to lab tests done today page
  };

  const handleGoToPendingLabTests = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/lab?token=${tokenFromUrl}`);
  };

  const handleGoToLabTestsHistory = () => {
    // Implement the logic to navigate to lab tests history page
  };

  return (
    <div className="lab-dashboard-container">
        <Topbar token={urlToken} />
      <h1>Hello, {employeeName}</h1>
      <button className="lab-dashboard-button" onClick={handleGoToLabTestsDoneToday}>
        See Lab Tests Done Today
      </button>
      <button className="lab-dashboard-button" onClick={handleGoToLabTestsHistory}>
        History of Previous Lab Tests
      </button>
      <button className="lab-dashboard-button lab-pending-tests" onClick={handleGoToPendingLabTests}>
        Pending Lab Tests
        {pendingLabTestsCount > 0 && <span className="lab-badge">{pendingLabTestsCount}</span>}
      </button>
    </div>
  );
}

export default LabDashboard;
