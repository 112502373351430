import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import printJS from 'print-js';

const ReceiptModal = ({ receiptDetails = {}, onClose }) => {
  // Log receiptDetails to see its structure
  console.log("Received receiptDetails:", receiptDetails);

  // Destructure receiptDetails with default values
  const {
    clinicName = "Default Clinic",
    town = "Default Town",
    ownersContact = "Default Contact",
    date = "Default Date",
    time = "Default Time",
    drugsSold = [],
    totalAmount = 0,
    employeeName = "Unknown Employee" // Added employeeName
  } = receiptDetails;

  // Handle print receipt
  const handlePrintReceipt = () => {
    // Get the current date and time
    const currentDate = new Date().toLocaleString();

    // Create a printable HTML content with embedded CSS
    const receiptHtml = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            width: 80mm; /* Adjust to match printer width, e.g., 80mm or 58mm */
            height: auto;
          }
          .receipt-content {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
          }
          .clinic-heading {
            font-size: 20px; /* Adjust as needed */
            font-weight: bold;
            margin: 0;
            line-height: 1.5; /* Increased line height for better spacing */
          }
          .receipt-title {
            font-size: 18px; /* Adjust as needed */
            font-weight: bold;
            margin-bottom: 10px;
          }
          .receipt-details {
            font-size: 16px;
            text-align: left;
            margin: 10px 0; /* Adjusted margin to reduce top space */
            line-height: 1.5; /* Increased line height for better spacing */
          }
          .receipt-footer {
            font-size: 16px;
            text-align: center;
            color: #555;
            margin-top: 20px; /* Ensure spacing matches other sections */
            margin-bottom: 10px; /* Consistent bottom margin */
          }
        </style>
      </head>
      <body>
        <div class="receipt-content">
          <h2 class="receipt-title">Drug Purchase Receipt</h2>
          <h2 class="clinic-heading">${clinicName}</h2>
          <p>${town}</p>
          <p>${ownersContact}</p>
          <p>${currentDate}</p>
          <div class="receipt-details">
            ${drugsSold.map(item => `
              <p>${item.quantity} ${item.packaging} ${item.drug} - UGX ${item.amount}</p>
            `).join('')}
            <p>Total Amount: UGX ${totalAmount}</p><br><br>
            <strong>You were served by: ${employeeName}</strong><br>
            <strong>Thank you for your purchase!</strong><br>
            -----------------------------------------------<br>
            <strong>Software developed by: <br>
            DeepMind E-Systems 0786747733</strong>
          </div>
        </div>
      </body>
      </html>
    `;

    // Use print-js to print the HTML content
    printJS({
      printable: receiptHtml,
      type: 'raw-html',
      documentTitle: 'Receipt',
    });
  };

  return (
    <div className="receipt-modal-overlay">
      <div className="receipt-modal-content">
        <h2>Drug Purchase Receipt</h2> {/* Added receipt title */}
        <h3>{clinicName}</h3>
        <p>{town}</p>
        <p>{ownersContact}</p>
        <p>{date}</p>
        <p>{time}</p>
        <div className="receipt-items">
          {drugsSold.length > 0 ? (
            drugsSold.map((item, index) => (
              <div key={index}>
                {item.quantity} {item.packaging} {item.drug} - UGX {item.amount}
              </div>
            ))
          ) : (
            <p>No items to display</p>
          )}
          <div className="receipt-total">Total Amount: UGX {totalAmount}</div>
          <div className="receipt-employee">You were served by: {employeeName}</div> {/* Added employeeName */}
        </div>
        <div className="receipt-action-buttons">
          <button onClick={handlePrintReceipt}>
            <FontAwesomeIcon icon={faPrint} /> Print Receipt
          </button>
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptModal;
