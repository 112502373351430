import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheckCircle, faMoneyBillWave, faFlask, faClock } from '@fortawesome/free-solid-svg-icons';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import doctorsSvg from './images/doctors.svg'; // Import the SVG image
import './DoctorsDashboard.css';
import Topbar from './Topbar'; // Import the Topbar component

function DoctorsDashboard() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [patientData, setPatientData] = useState({ contact_id: null, first_name: '' });
  const [completeResultsCount, setCompleteResultsCount] = useState(0);
  const [waitingPaymentCount, setWaitingPaymentCount] = useState(0);
  const [inProcessCount, setInProcessCount] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        console.log('URL Token:', urlToken);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: urlToken }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            localStorage.setItem('token', securityData.clinic_session_token);

            fetchPatientNames(urlToken);
            fetchCounts(urlToken);

          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate, urlToken]);

  const fetchPatientNames = (tokenFromUrl) => {
    fetch(urls.patientnames, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    })
      .then(response => response.json())
      .then(data => {
        setPatientData({ contact_id: data.contact_id, first_name: data.first_name });
      })
      .catch(error => {
        console.error('Error fetching patient names:', error);
      });
  };

  const fetchCounts = (tokenFromUrl) => {
    const fetchCompletedResultsCount = () => {
      fetch(urls.countfinishedlabresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      })
        .then(response => response.json())
        .then(data => {
          setCompleteResultsCount(data.count);
        })
        .catch(error => {
          console.error('Error fetching completed results count:', error);
        });
    };

    const fetchWaitingPaymentCount = () => {
      fetch(urls.waitingpaymentcount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      })
        .then(response => response.json())
        .then(data => {
          setWaitingPaymentCount(data.count);
        })
        .catch(error => {
          console.error('Error fetching waiting payment count:', error);
        });
    };

    const fetchInProcessCount = () => {
      fetch(urls.inprocesscount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      })
        .then(response => response.json())
        .then(data => {
          setInProcessCount(data.count);
        })
        .catch(error => {
          console.error('Error fetching in process count:', error);
        });
    };

    fetchCompletedResultsCount();
    fetchWaitingPaymentCount();
    fetchInProcessCount();

    setInterval(fetchCompletedResultsCount, 1000);
    setInterval(fetchWaitingPaymentCount, 1000);
    setInterval(fetchInProcessCount, 2000);
  };

  const handlePatientClick = () => {
    console.log('Clicked on patient:', patientData);
  };

  const handleButtonClick = (message, url) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: urlToken }),
    })
      .then(response => response.json())
      .then(data => {
        const names = data.names.join(', ');
        window.alert(`${message}: ${names}`);
      })
      .catch(error => {
        console.error(`Error fetching data for ${message}:`, error);
      });
  };

  const handleNewPatientClick = () => {
    navigate(`/attend-to-new-patient?token=${urlToken}`);
  };

  const handlePatientFilesClick = () => {
    navigate(`/patientfiles?token=${urlToken}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="full-screen-container">
      <Topbar token={urlToken} />
      <div className="doctors-room-container">
        <div className="doctors-room-header">
          <h1 className="doctors-room-title">DOCTOR'S ROOM</h1>
          <img src={doctorsSvg} alt="Doctor's Icon" className="doctors-room-icon" />
        </div>
        <h2 className="doctors-room-date">
          <FontAwesomeIcon icon={faClock} /> {currentTime.toLocaleString('en-US', { timeZone: 'Africa/Kampala' })}
        </h2>
  
        <button className="doctors-room-patient-name-button" onClick={handlePatientClick}>
          <FontAwesomeIcon icon={faUser} /> {`${patientData.first_name} (ID: ${patientData.contact_id})`}
        </button>
  
        <div className="doctors-room-button-container">
          {/* Full-width button for Register New Patient */}
          <button className="doctors-room-menu-button full-width-button" onClick={handleNewPatientClick}>
            <FontAwesomeIcon icon={faUser} /> Attend to New Patient
          </button>
  
          {/* Remaining buttons split into two columns */}
          <div className="split-buttons">
            <button className="doctors-room-menu-button" onClick={handlePatientFilesClick}>
              <FontAwesomeIcon icon={faUser} /> Manage Existing Patient Files
            </button>
            <button className="doctors-room-menu-button" onClick={() => handleButtonClick("Completed Lab/Scan Results", urls.completedlabnames)}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span className="doctors-room-button-description">
                View patients whose lab or scan results are ready.
              </span>
              {completeResultsCount > 0 && <span className="doctors-room-appointment-indicator">{completeResultsCount}</span>}
            </button>
            <button className="doctors-room-menu-button" onClick={() => handleButtonClick("Patients Awaiting Payment", urls.awaitingnames)}>
              <FontAwesomeIcon icon={faMoneyBillWave} />
              <span className="doctors-room-button-description">
                Patients waiting to clear bills at the cashier before heading to the lab/radiology room.
              </span>
              {waitingPaymentCount > 0 && <span className="doctors-room-appointment-indicator">{waitingPaymentCount}</span>}
            </button>
            <button className="doctors-room-menu-button" onClick={() => handleButtonClick("Patients in Lab/Scan Process", urls.inprocessnames)}>
              <FontAwesomeIcon icon={faFlask} />
              <span className="doctors-room-button-description">
                Patients currently being attended to in the lab or radiology room.
              </span>
              {inProcessCount > 0 && <span className="doctors-room-appointment-indicator">{inProcessCount}</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorsDashboard;
