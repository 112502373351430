import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { urls } from './config.dev';
import './ReferralForm.css';

function ReferralForm() {
    const [patientDetails, setPatientDetails] = useState({});
    const [reasonForReferral, setReasonForReferral] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().slice(0, 10));
    const [admittedOnDate, setAdmittedOnDate] = useState(new Date().toISOString().slice(0, 10));
    const [referredOnDate, setReferredOnDate] = useState(new Date().toISOString().slice(0, 10));
    const [conditionOnReferral, setConditionOnReferral] = useState('');
    const [referringTo, setReferringTo] = useState('');
    const { fileId } = useParams();

    useEffect(() => {
        fetchPatientDetails();
    }, [fileId]);

    const fetchPatientDetails = () => {
        fetch(`${urls.viewfile}?fileId=${fileId}`)
            .then(response => response.json())
            .then(data => {
                const { fileData } = data;
                setPatientDetails(fileData);
            })
            .catch(error => {
                console.error('Error fetching patient details:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPatientDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleReasonChange = (e) => {
        setReasonForReferral(e.target.value);
    };

    const handleDateChange = (e) => {
        setCurrentDate(e.target.value);
    };

    const handleAdmissionDateChange = (e) => {
        setAdmittedOnDate(e.target.value);
    };

    const handleReferralDateChange = (e) => {
        setReferredOnDate(e.target.value);
    };

    const handleConditionChange = (e) => {
        setConditionOnReferral(e.target.value);
    };

    const handleReferringToChange = (e) => {
        setReferringTo(e.target.value);
    };

    const handlePrint = () => {
        window.print();
    };


    return (
        <div className="referral-form-container">
            <h1 className="centered-heading">LIFE-SURE MEDICARE</h1>
            <div className="address-container">
                <p>Bulaga ku Mwenda</p>
            </div>
            <div className="contacts-container">
                <p>Contacts: Your Contact Information Here</p>
            </div>
    
            <h2 className="referral-heading">REFERRAL FORM</h2>
    
            <form className="referral-details-form">
                <h3>Patient Details</h3>
                <div className="patient-detail">
                    <label htmlFor="first_name">First Name:</label>
                    <input type="text" id="first_name" name="first_name" value={patientDetails.first_name || ''} onChange={handleInputChange} />
                </div>
    
                <div className="patient-detail">
                    <label htmlFor="last_name">Last Name:</label>
                    <input type="text" id="last_name" name="last_name" value={patientDetails.last_name || ''} onChange={handleInputChange} />
                </div>
    
                <div className="patient-detail">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input type="text" id="phone_number" name="phone_number" value={patientDetails.phone_number || ''} onChange={handleInputChange} />
                </div>
    
                <div className="patient-detail">
                    <label htmlFor="age">Age:</label>
                    <input type="text" id="age" name="age" value={patientDetails.age || ''} onChange={handleInputChange} />
                </div>
    
                <div className="patient-detail">
                    <label htmlFor="sex">Sex:</label>
                    <input type="text" id="sex" name="sex" value={patientDetails.sex || ''} onChange={handleInputChange} />
                </div>
    
               
    
                <label htmlFor="admitted_on">Admitted On:</label>
                <input type="text" id="admitted_on" name="admitted_on" value={currentDate} onChange={handleAdmissionDateChange} />
    
                <label htmlFor="referred_on">Referred On:</label>
                <input type="text" id="referred_on" name="referred_on" value={currentDate} onChange={handleReferralDateChange} />
    
                <h2>Treatment Given on Admission</h2>
                <label htmlFor="treatment_on_admission">Treatment Given on Admission:</label>
                <textarea id="treatment_on_admission" name="treatment_on_admission" value={patientDetails.treatment_notes || ''} onChange={handleInputChange} />
    
                <h2>Reason for Referral</h2>
                <textarea id="reason_for_referral" name="reason_for_referral" value={reasonForReferral} onChange={handleReasonChange} />
    
                <h2>Condition on Referral</h2>
                <textarea id="condition_on_referral" name="condition_on_referral" value={conditionOnReferral} onChange={handleConditionChange} />
    
                <h2>Referring to</h2>
                <input type="text" id="referring_to" name="referring_to" value={referringTo} onChange={handleReferringToChange} />
    
                {/* Add any other required fields for the referral form */}
    
                <div className="doctor-signature">
                    <label htmlFor="doctor_signature">Doctor/Nurse's Signature:</label>
                    {/* Add input for doctor/nurse's signature */}
                </div>
            </form>
    
            <button onClick={handlePrint}>Print</button>
        </div>
    );
    
}

export default ReferralForm;
