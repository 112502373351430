import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topbar from './Topbar'; // Import the Topbar component
import './DoctorsRoom.css'; // Import CSS file for styling
import { urls } from './config.dev'; // Import URL configurations for API calls
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Select from 'react-select';
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

function DoctorsRoom() {
    // State variable to track whether the "Save as New Patient" button has been clicked
const [savePatientClicked, setSavePatientClicked] = useState(false);
const navigate = useNavigate(); // Initialize useNavigate for redirection

// State variable to track whether the "Confirm Patient" button should be disabled
const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
    // State variables for selected lab tests and radiology exams
const [selectedLabTests, setSelectedLabTests] = useState([]);
const [selectedRadiologyExams, setSelectedRadiologyExams] = useState([]);
    // State variables
    const [employeeName, setEmployeeName] = useState('');
    const [patientDetails, setPatientDetails] = useState(null);
    const [patientName, setPatientName] = useState('');
    const [clinicalNotes, setClinicalNotes] = useState('');
    const [signsAndSymptoms, setSignsAndSymptoms] = useState('');
    const [labTests, setLabTests] = useState([]);
    // State variables for vitals
    const [bloodPressure, setBloodPressure] = useState('');
    const [temperature, setTemperature] = useState('');
    const [spo2, setSpo2] = useState('');
    const [bodyWeight, setBodyWeight] = useState('');
    const [selectedLabTest, setSelectedLabTest] = useState('');
    const [radiologyExams, setRadiologyExams] = useState([]);
    const [selectedRadiologyExam, setSelectedRadiologyExam] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [message, setMessage] = useState('');
    const [diagnosis, setDiagnosis] = useState('');
    const [recognition, setRecognition] = useState(null);
    const [diagnosisText, setDiagnosisText] = useState('');
    const textareaRef = useRef(null);
    const [readOnlyPatientDetails, setReadOnlyPatientDetails] = useState(null);
    const [submitting, setSubmitting] = useState(false); // State to track submission status
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token'); // Define urlToken he
    const [treatmentPlan, setTreatmentPlan] = useState('');
    const [showAddPatientPrompt, setShowAddPatientPrompt] = useState(false);
    const diagnosisOptions = [
     
        { value: 'Malaria', label: 'Malaria' },
        { value: 'Pneumonia', label: 'Pneumonia' },
        { value: 'Tuberculosis', label: 'Tuberculosis (TB)' },
        { value: 'TyphoidFever', label: 'Typhoid Fever' },
        { value: 'Gastroenteritis', label: 'Gastroenteritis' },
        { value: 'Syphilis', label: 'Syphilis' },
        { value: 'Gonorrhea', label: 'Gonorrhea' },
        { value: 'Urinary Tract Infections', label: 'Urinary Tract Infections' },
       
        { value: 'Endometritis', label: 'Endometritis' },
        { value: 'PelvicInflammatoryDisease', label: 'Pelvic Inflammatory Disease' },
        { value: 'Cervicitis', label: 'Cervicitis' },
        { value: 'Vulvitis', label: 'Vulvitis' },
        { value: 'Vaginitis', label: 'Vaginitis' },
        { value: 'Bartholinitis', label: 'Bartholinitis' },
        { value: 'Urethritis', label: 'Urethritis' },
        { value: 'VulvarDermatitis', label: 'Vulvar Dermatitis' },
        { value: 'Urethritis', label: 'Urethritis' },

        { value: 'HIV/AIDS', label: 'HIV/AIDS' },
       
        { value: 'HepatitisB', label: 'Viral Hepatitis (Hepatitis B)' },
        { value: 'HepatitisC', label: 'Viral Hepatitis (Hepatitis C)' },
        { value: 'Measles', label: 'Measles' },
        { value: 'Meningitis', label: 'Meningitis (Bacterial and Viral)' },
        { value: 'Cholera', label: 'Cholera' },
        { value: 'Shigella', label: 'Shigella' },
        
       ,
        { value: 'Schistosomiasis', label: 'Schistosomiasis' },
        { value: 'Hookworm', label: 'Hookworm' },
        { value: 'Roundworm', label: 'Roundworm' },
        { value: 'Leprosy', label: 'Leprosy' },
        { value: 'Brucellosis', label: 'Brucellosis' },
       
        
        { value: 'Hypertension', label: 'Hypertension' },
        { value: 'DiabetesMellitus', label: 'Diabetes Mellitus' },
        { value: 'COPD', label: 'Chronic Obstructive Pulmonary Disease (COPD)' },
        { value: 'Asthma', label: 'Asthma' },
        { value: 'IschemicHeartDisease', label: 'Ischemic Heart Disease' },
        { value: 'CervicalCancer', label: 'Cervical Cancer' },
        { value: 'BreastCancer', label: 'Breast Cancer' },
        { value: 'ChronicKidneyDisease', label: 'Chronic Kidney Disease' },
        { value: 'Epilepsy', label: 'Epilepsy' },
        { value: 'SickleCellDisease', label: 'Sickle Cell Disease' },
        { value: 'Depression', label: 'Depression' },
        { value: 'Anxiety', label: 'Anxiety' },
        { value: 'Psychosis', label: 'Psychosis' },
        
    
        
        { value: 'AntenatalComplications', label: 'Antenatal Complications' },
        { value: 'PreEclampsia', label: 'Pre-eclampsia' },
        { value: 'Eclampsia', label: 'Eclampsia' },
        { value: 'PostpartumHemorrhage', label: 'Postpartum Hemorrhage' },
        { value: 'ObstetricFistula', label: 'Obstetric Fistula' },
        { value: 'NeonatalSepsis', label: 'Neonatal Sepsis' },
        { value: 'LowBirthWeight', label: 'Low Birth Weight' },
        { value: 'Malnutrition', label: 'Malnutrition' },
        { value: 'GrowthDisorders', label: 'Growth and Developmental Disorders' },
        
    
       
        { value: 'Iron-Deficiency Anemia', label: 'Iron-Deficiency Anemia' },
        { value: 'Vitamin A Deficiency', label: 'Vitamin A Deficiency' },
        { value: 'Iodine Deficiency Disorders', label: 'Iodine Deficiency Disorders' },
    
    
        { value: 'Snake Bites', label: 'Snake Bites' },
        { value: 'Pesticide Poisoning', label: 'Pesticide Poisoning' },
        { value: 'Food  Poisoning', label: 'Food Poisoning' },
        { value: 'Silicosis', label: 'Silicosis)' },
        { value: 'Heat Stroke', label: 'Heat Stroke' },
      
    
        
        { value: 'Scabies', label: 'Scabies' },
        { value: 'Dermatophytosis', label: 'Dermatophytosis' },
        { value: 'Ringworm', label: 'Ringworm' },
        { value: 'Eczema', label: 'Eczema' },
        { value: 'Impetigo', label: 'Impetigo' },
        { value: 'Leprosy', label: 'Leprosy (Hansen\'s Disease)' },
    
        
        { value: 'Cataracts', label: 'Cataracts' },
        { value: 'Glaucoma', label: 'Glaucoma' },
        { value: 'Trachoma', label: 'Trachoma' },
        { value: 'Conjunctivitis', label: 'Conjunctivitis' },
        { value: 'Refractive Errors', label: 'Refractive Errors' },
    
        
        { value: 'Dental Caries', label: 'Dental Caries (Tooth Decay)' },
        { value: 'Periodontal Diseases', label: 'Periodontal Diseases ' },
        { value: 'Gingivitis', label: ' Gingivitis' },
        { value: 'Oral Cancers', label: 'Oral Cancers' },
    
      
        { value: 'Arthritis', label: 'Arthritis' },
        { value: 'Osteoporosis', label: 'Osteoporosis' },
        { value: 'Lumbar Spondylosis', label: ' Lumbar Spondylosis' },
    
        
        { value: 'Trauma and Injuries', label: 'Trauma and Injuries ' },
        
        { value: 'Road Traffic Accidents', label: 'Road Traffic Accidents' },
        { value: 'Burns', label: 'Burns' },
        { value: 'Snake Bites', label: 'Snake Bites' },
        { value: 'Rabies', label: ' Rabies' },
    ];
    
    // Update the state variable newPatientDetails to include an address field
const [newPatientDetails, setNewPatientDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    age: '',
    sex: '',
    dob: '',
    religion: '',
    address: '', // Add address field
    nextOfKin: '',
    nextOfKinContact: ''
});
useEffect(() => {
    const fetchEmployeeName = async () => {
        try {
            // Fetch token from URL parameter
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get('token');

            console.log('URL Token:', tokenFromUrl);

            // Perform security check using the token
            const securityResponse = await fetch(urls.security, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: tokenFromUrl }),
            });

            if (securityResponse.ok) {
                const securityData = await securityResponse.json();

                console.log('Security Data:', securityData);

                if (securityData.message === 'Session valid') {
                    // Security check passed, set the employee name
                    setEmployeeName(securityData.employee_name);

                    // Store token in localStorage for subsequent requests
                    localStorage.setItem('token', securityData.clinic_session_token);

                    // Proceed with fetching lab tests and radiology tests
                    fetchAvailableLabTests();
                    fetchAvailableRadiologyTests();
                } else if (securityData.error === 'Session expired') {
                    // Redirect to dashboard if session expired
                    console.log('Session expired, redirecting to dashboard');
                    navigate(`/dashboard?token=${securityData.clinic_session_token}`);
                } else {
                    // Handle other errors and log the error
                    console.log('Other error:', securityData.error);
                    // Navigate to appropriate error page or handle as needed
                    navigate('/login');
                }
            } else {
                throw new Error('Failed to perform security check');
            }
        } catch (error) {
            console.error('Error performing security check:', error);
            // Redirect to login page on error
            navigate('/login');
        }
    };

    // Effect runs on mount and whenever `navigate` changes
    fetchEmployeeName();
}, [navigate]);

  
// Function to handle recognition results
const handleRecognitionResult = (event) => {
    const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join('');

    console.log('Transcript:', transcript); // Log the transcript to see the recognized speech

    // Check if the active element is the clinical notes or signs and symptoms textarea
    const activeElementId = document.activeElement.id;
    console.log('Active Element ID:', activeElementId); // Log the active element ID

    if (activeElementId === 'clinical-notes') {
        console.log('Updating clinical notes:', transcript);
        setClinicalNotes(transcript); // Update clinicalNotes state with spoken text
    } else if (activeElementId === 'signs-and-symptoms') {
        console.log('Updating signs and symptoms:', transcript);
        setSignsAndSymptoms(transcript); // Update signsAndSymptoms state with spoken text
    }
};


// Function to start speech recognition
const startRecognition = (fieldId) => {
    const textarea = document.getElementById(fieldId);
    if (textarea && recognition && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Focus on the desired textarea element
        textarea.focus();
        // Request microphone permission
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                recognition.start();
            })
            .catch((error) => {
                console.error('Error accessing microphone:', error);
                alert('Error accessing microphone. Please check your browser settings.');
            });
    } else {
        console.error('getUserMedia is not supported in this environment');
        alert('Microphone access is not supported in this browser.');
    }
};



    // Function to update read-only patient details
    const updateReadOnlyPatientDetails = (newDetails) => {
        setReadOnlyPatientDetails(newDetails);
    };

    // Function to display read-only fields on the dashboard
    const displayReadOnlyFields = () => {
        if (readOnlyPatientDetails) {
            return (
                <div className="read-only-fields-container">
                <div className="input-container">
                        <label>Patient ID:</label>
                        <input type="text" value={readOnlyPatientDetails.contactId} readOnly />
                    </div>
                    <div className="input-container">
                        <label>First Name:</label>
                        <input type="text" value={readOnlyPatientDetails.firstName} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Last Name:</label>
                        <input type="text" value={readOnlyPatientDetails.lastName} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Phone Number:</label>
                        <input type="text" value={readOnlyPatientDetails.phoneNumber} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Age:</label>
                        <input type="text" value={readOnlyPatientDetails.age} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Sex:</label>
                        <input type="text" value={readOnlyPatientDetails.sex} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Religion:</label>
                        <input type="text" value={readOnlyPatientDetails.religion} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Date of Birth:</label>
                        <input type="text" value={readOnlyPatientDetails.dob} readOnly />
                    </div>
                </div>
            );
        } else {
            return null; // Render nothing if patient details are not available
        }
    };

  // Function to fetch patient details
const fetchPatientDetails = async () => {
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      // Fetch patient details from the backend
      const response = await fetch(`${urls.nameSuggestion}?name=${patientName}&token=${tokenFromUrl}`);
      
      // Check if the response is successful (status code 200-299)
      if (response.ok) {
        // Extract JSON data from the response
        const data = await response.json();
        
        // Check if patient details exist in the response
        if (data.exists) {
          // Update read-only patient details state with fetched data
          setReadOnlyPatientDetails(data.patientDetails);
        } else {
          // Display option to register new patient
          // You can implement this part according to your UI design
          // For example, you can show a message to the user indicating that no patient records were found.
        }
      } else {
        // Handle non-successful response (status code outside 200-299 range)
        // You can log an error message or handle it according to your application's requirements
        console.error('Failed to fetch patient details:', response.statusText);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch operation
      console.error('Error fetching patient details:', error);
    }
  };
  
// Function to handle suggesting patient names
const handleSuggestName = async (name) => {
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const queryString = `?name=${encodeURIComponent(name)}&token=${tokenFromUrl}`;
      const response = await fetch(`${urls.suggest}${queryString}`);
      
      if (response.ok) {
        const data = await response.json();
        
        if (data.message && data.message === 'No patient records found for the specified clinic') {
          setSuggestions([]);
          setMessage('No patient records found');
          // Enable the "Save as New Patient" button
         
          // Disable the "Confirm Patient" button
          setConfirmButtonDisabled(true);
        } else if (data.error === 'Name and token parameters are required'){
            setSuggestions([]);
            setMessage('No patient records found');
            // Enable the "Save as New Patient" button
            
            // Disable the "Confirm Patient" button
            setConfirmButtonDisabled(true);
        
        } else {
          setSuggestions(data);
          setMessage(''); // Clear any previous error message
          // Disable the "Save as New Patient" button
         
          // Enable the "Confirm Patient" button
         
        }
      } else {
        const errorData = await response.json();
        
        if (errorData.error === "Name parameter is required") {
          // Handle the specific error response
          // You can choose not to show any alert for this error
          console.log("Name parameter is required");
          // Return early or perform any other actions you want for this specific error
          return;
        }
        throw new Error('Failed to suggest names');
      }
    } catch (error) {
        console.error('Error suggesting names:', error.message);
        // Show an alert for unexpected errors
        
        // Reset suggestions and set button states
        setSuggestions([]);
        setMessage('No patient records found');
        
    }
};
  

const addContact = async () => {
    // Check if the "Save as New Patient" button has already been clicked
    if (savePatientClicked) {
      return; // Do nothing if the button has already been clicked
    }
  
    // Set the "Save as New Patient" button as clicked
    setSavePatientClicked(true);
  
    // Validate required fields
    if (
      !newPatientDetails.firstName ||
      !newPatientDetails.lastName ||
      !newPatientDetails.phoneNumber ||
      !newPatientDetails.age ||
      !newPatientDetails.sex ||
      !newPatientDetails.religion ||
      !newPatientDetails.dob ||
      !newPatientDetails.address // Check if address is provided
    ) {
      toast.error('Please fill in all required fields including address.');
      setSavePatientClicked(false); // Reset the button state
      return;
    }
  
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
  
    // Proceed with submitting the form
    try {
      const response = await fetch(urls.addcontact2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...newPatientDetails, token: tokenFromUrl }),
      });
      const data = await response.json();
      if (response.ok) {
        // Update read-only patient details with newly saved patient data
        updateReadOnlyPatientDetails(data.patientDetails);
        // Display success message
        toast.success(data.message);
        // Disable the "Save as New Patient" button after successful submission
        setSavePatientClicked(true);
  
        // Hide the prompt
        setShowAddPatientPrompt(false);
      } else {
        throw new Error('Failed to add new patient');
      }
    } catch (error) {
      console.error('Error adding new patient:', error);
      // Show an error message
      toast.error('Oops! Something went wrong.');
      // Reset the "Save as New Patient" button state
      setSavePatientClicked(false);
    }
  };
  const handleSelectSuggestedPatient = (patient) => {
    // Set the selected patient details
    setNewPatientDetails({
        firstName: patient.first_name,
        lastName: patient.last_name,
        contactId: patient.contact_id,
        phoneNumber: patient.phone_number,
        age: patient.age,
        sex: patient.sex,
        religion: patient.religion,
        dob: patient.dob,
    });

    // Set the state of read-only fields with confirmed patient details
    setReadOnlyPatientDetails({
        firstName: patient.first_name,
        lastName: patient.last_name,
        contactId: patient.contact_id,
        phoneNumber: patient.phone_number,
        age: patient.age,
        sex: patient.sex,
        religion: patient.religion,
        dob: patient.dob,
    });

    // Clear the suggestions array
    setSuggestions([]);

    // Additional reset operations if needed can be added here

    // Clear the data and hide the prompt
    setNewPatientDetails({
        firstName: '',
        lastName: '',
        contactId: '',
        phoneNumber: '',
        age: '',
        sex: '',
        religion: '',
        dob: '',
    });
    setShowAddPatientPrompt(false);
};


    // Function to add lab test
    const addLabTest = () => {
        if (selectedLabTest !== '' && !labTests.includes(selectedLabTest)) {
            setLabTests([...labTests, selectedLabTest]);
        }
    };

    // Function to remove lab test
    const removeLabTest = (test) => {
        setLabTests(labTests.filter((item) => item !== test));
    };

    // Function to add radiology exam
    const addRadiologyExam = () => {
        if (selectedRadiologyExam !== '' && !radiologyExams.includes(selectedRadiologyExam)) {
            setRadiologyExams([...radiologyExams, selectedRadiologyExam]);
        }
    };

    // Function to remove radiology exam
    const removeRadiologyExam = (exam) => {
        setRadiologyExams(radiologyExams.filter((item) => item !== exam));
    };
 
    

  // Function to display random toast message
const generateRandomToast = () => {
    const messages = [
        'Form is being submitted...',
        'Please wait, processing your request...',
        'Submitting your data, hang tight!',
    ];
    const randomMessage = messages[Math.floor(Math.random() * messages.length)];
    toast.info(randomMessage);
};
const submitForm = async () => {
    try {
        // Display a random toast message
        generateRandomToast();

        // Disable the submit button
        setSubmitting(true);

        // Fetch token from URL parameter
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        // Debugging: Log the diagnosisText value
        console.log('Diagnosis Text:', diagnosisText);

        // Prepare data to be sent to the backend
        const formData = {
            contactId: readOnlyPatientDetails.contactId,
            clinicalNotes,
            signsAndSymptoms,
            bloodPressure,
            temperature,
            spo2,
            bodyWeight,
            diagnosis: diagnosisText, // Ensure diagnosisText is used
            treatmentPlan,
            labTests: selectedLabTests, // Include selected lab tests
            radiologyExams: selectedRadiologyExams, // Include selected radiology exams
            token: tokenFromUrl, // Include the token
            // Add other fields as needed
        };

        // Make POST request to submit form data
        const submitResponse = await fetch(urls.submitpatient, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        // Handle response
        if (submitResponse.ok) {
            const responseData = await submitResponse.json();
            if (responseData.message === 'Data inserted successfully') {
                // Display success toast message
                toast.success('Patient form submitted successfully');

                // Delay redirection by 10 seconds
                setTimeout(() => {
                    // Enable the submit button
                    setSubmitting(false);
                    // Redirect to PatientFiles component
                    navigate(`/patientfiles/?token=${tokenFromUrl}`);
                }, 10000); // 10 seconds in milliseconds
            } else {
                // Handle unexpected response from the backend
                console.error('Unexpected response from backend:', responseData);
                toast.error('Unexpected response from server');
                setSubmitting(false);
            }
        } else {
            // Enable the submit button
            setSubmitting(false);

            // Handle error if submission fails
            console.error('Error submitting patient data:', submitResponse.statusText);
            toast.error('Error submitting patient data');
        }
    } catch (error) {
        // Enable the submit button
        setSubmitting(false);

        console.error('Error submitting form:', error);
        toast.error('Error submitting form');
    }
};


// Fetch lab tests from backend when the component mounts
useEffect(() => {
    fetchAvailableLabTests();
    fetchAvailableRadiologyTests();
}, []);

const fetchAvailableLabTests = () => {
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    // Construct the payload with the token
    const payload = {
        token: tokenFromUrl,
        // Add any other parameters required by your API
    };

    // Fetch available lab tests using the token in the payload
    fetch(urls.testsavailable, {
        method: 'POST',  // Adjust the method based on your API's requirements
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to fetch available lab tests');
        }
        return response.json();
    })
    .then(data => {
        setLabTests(data);  // Assuming setLabTests is a function to set the lab tests data
    })
    .catch(error => {
        console.error('Error fetching available lab tests:', error);
    });
};


const fetchAvailableRadiologyTests = () => {
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    // Construct the payload with the token
    const payload = {
        token: tokenFromUrl,
        // Add any other parameters required by your API
    };

    // Fetch available radiology exams using the token in the payload
    fetch(urls.radiologytestsavailable, {
        method: 'POST',  // Adjust the method based on your API's requirements
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to fetch available radiology exams');
        }
        return response.json();
    })
    .then(data => {
        setRadiologyExams(data);  // Assuming setRadiologyExams is a function to set the radiology exams data
    })
    .catch(error => {
        console.error('Error fetching available radiology exams:', error);
    });
};


// Function to add selected lab test
const addSelectedLabTest = (test) => {
    setSelectedLabTests([...selectedLabTests, test]);
};

// Function to remove selected lab test
const removeSelectedLabTest = (test) => {
    setSelectedLabTests(selectedLabTests.filter((item) => item !== test));
};

// Function to add selected radiology exam
const addSelectedRadiologyExam = (exam) => {
    if (exam !== '' && !selectedRadiologyExams.some((selectedExam) => selectedExam.name === exam)) {
        const selectedExamObject = radiologyExams.find((e) => e.name === exam);
        if (selectedExamObject) {
            setSelectedRadiologyExams([...selectedRadiologyExams, selectedExamObject]);
        }
    }
};

// Function to remove selected radiology exam
const removeSelectedRadiologyExam = (examToRemove) => {
    setSelectedRadiologyExams(selectedRadiologyExams.filter((exam) => exam.name !== examToRemove.name));
};

const formatDate = (dateString) => {
    // Check if dateString is empty or null
    if (!dateString) {
        return '';
    }

    // Attempt to create a Date object from the dateString
    const date = new Date(dateString);

    // Check if the Date object is valid
    if (isNaN(date.getTime())) {
        // If the Date object is invalid, return an empty string or handle the error as per your requirement
        return '';
    }

    // Format the Date object as YYYY-MM-DD string
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
};

const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
        const textarea = textareaRef.current;
        if (!textarea) {
            console.error('Textarea ref is not assigned.');
            return;
        }

        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        const textBefore = diagnosisText.substring(0, start);
        const textAfter = diagnosisText.substring(end);

        // Identify the word being typed and replace it with the selected diagnosis
        const words = textBefore.split(/\s+/);
        words[words.length - 1] = selectedOption.value;

        const newDiagnosisText = words.join(' ') + textAfter;
        setDiagnosisText(newDiagnosisText);
        
        // Debugging: Log the new diagnosis text
        console.log('New Diagnosis Text:', newDiagnosisText);

        // Adjust the cursor position
        const newCursorPosition = (words.join(' ') + ' ').length;
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPosition, newCursorPosition);
            textarea.focus();
        }, 0);
    } else {
        // Debugging: Log if no option is selected
        console.log('No diagnosis selected.');
    }
};

const handleCancel = () => {
    // Reset new patient details
    setNewPatientDetails({
        firstName: '',
        lastName: '',
        contactId: '',
        phoneNumber: '',
        age: '',
        sex: '',
        religion: '',
        dob: '',
        address: '' // Ensure to include all necessary fields
    });

    // Hide the prompt
    setShowAddPatientPrompt(false);
    setSuggestions([]);
};

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontSize: '16px', // Reduced font size
        fontFamily: 'Arial, sans-serif',
        marginBottom: '10px',
        width: '100%',
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#007BFF' : '#ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
        '&:hover': {
            borderColor: '#007BFF',
        },
        height: '10px', // Reduced height of the select input
        transition: 'border-color 0.3s ease', // Smooth transition for border color
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6c757d',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#007BFF' : '#000',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#007BFF',
            color: '#fff',
        },
        fontSize: '12px', // Smaller font size for options
        padding: '8px 10px', // Reduced padding for options
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#333',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginTop: '0',
        backgroundColor: '#000',
        animation: 'fadeIn 0.3s ease-in-out', // Animation for dropdown appearance
    }),
    menuList: (provided) => ({
        ...provided,
        padding: '5px',
        color: '#fff',
        fontSize: '12px', // Smaller font size for dropdown list
        gap: '2px', // Reduced space between options
        maxHeight: '400px', // Set a maximum height for scrolling
        overflowY: 'auto', // Enable vertical scrolling
    }),
};


// Add the keyframes for the fade-in animation to your global CSS or within a styled component
const styles = `
    @keyframes fadeIn {
        0% { opacity: 0; transform: translateY(-10px); }
        100% { opacity: 1; transform: translateY(0); }
    }
`;

// Append the styles to the document head (if not using styled-components)
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

    return (
        <div className="doctors-room-body">
                <Topbar token={urlToken} />
        <div className="doctors-room-container2">
        <ToastContainer />
            <h1>Hi Doctor {employeeName} !</h1>
            {/* Patient Details Section */}
            {displayReadOnlyFields()}
            <div className="patient-details-section">
                <button onClick={() => setShowAddPatientPrompt(true)}>Insert Patient Details</button>
                {showAddPatientPrompt && (
            <div className="doctors-modal-overlay">
            <div className="doctors-modal-content">
                <div className="transaction-prompt">
                    <h2>Insert Patient Details</h2>
                    {/* Input fields for new patient details */}
                    <div className="doctors-input-container">
                        <label>First Name:</label>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={newPatientDetails.firstName}
                            onChange={(e) => {
                                setNewPatientDetails({ ...newPatientDetails, firstName: e.target.value });
                                handleSuggestName(e.target.value);
                            }}
                            className="add-patient-input"
                        />
                    </div>
                    {/* Display suggested names */}
                    { suggestions.map((patient, index) => (
    <div key={index} onClick={() => handleSelectSuggestedPatient(patient)} className="suggestion-item">
        {patient.first_name} {patient.last_name}
    </div>
))}
                    {/* Other input fields for patient details */}
                    <div className="input-container">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={newPatientDetails.lastName}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, lastName: e.target.value })}
                            className="add-patient-input"
                        />
                    </div>
                    {/*<div className="input-container">
                        <label>Patient ID:</label>
                        <input
                            type="text"
                            placeholder="This is automatically determined by the system, you don't have to fill this section."
                            value={newPatientDetails.contactId}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, contactId: e.target.value })}
                            readOnly // Set the readOnly attribute
                            className="add-patient-input"
                        />
                    </div>*/}
                    <div className="input-container">
                        <label>Phone Number:</label>
                        <input
                            type="text"
                            placeholder="Phone Number"
                            value={newPatientDetails.phoneNumber}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, phoneNumber: e.target.value })}
                            className="add-patient-input"
                        />
                    </div>
                    <div className="input-container">
                        <label>Age:</label>
                        <input
                            type="text"
                            placeholder="Age"
                            value={newPatientDetails.age}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, age: e.target.value })}
                            className="add-patient-input"
                        />
                    </div>
                    <div className="input-container">
                        <label>Sex:</label>
                        <select
                            value={newPatientDetails.sex}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, sex: e.target.value })}
                            className="add-patient-input"
                        >
                            <option value="">Select Sex</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Religion:</label>
                        <select
                            value={newPatientDetails.religion}
                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, religion: e.target.value })}
                            className="add-patient-input"
                        >
                            <option value="">Select Religion</option>
                            <option value="Christian">Christian</option>
                            <option value="Islamic">Islamic</option>
                        </select>
                    </div>
                    <div className="input-container">
    <label>Address:</label>
    <input
        type="text"
        placeholder="Address"
        value={newPatientDetails.address}
        onChange={(e) => setNewPatientDetails({ ...newPatientDetails, address: e.target.value })}
        className="add-patient-input"
    />
</div>

                    <div className="input-container">
    <label>Date of Birth (month/date/year):</label>
    <input
        type="date"
        value={formatDate(newPatientDetails.dob)}
        onChange={(e) => setNewPatientDetails({ ...newPatientDetails, dob: e.target.value })}
        className="add-patient-input"
    />
</div>

        

<div className="transaction-buttons">
    <button onClick={handleCancel}>Cancel</button>
    <button onClick={addContact} disabled={savePatientClicked}>
        {savePatientClicked ? 'Please wait' : 'Save as New Patient'}
    </button>
</div>
                </div>
            </div>
        </div>
        
                )}
            </div>
            <div className="vitals-section textarea-container">
                <h2>Patient Vitals</h2>
                <div className="vitals-inputs">
                    <div className="vital-input">
                        <label>Blood Pressure:</label>
                        <input
                            type="text"
                            placeholder="Blood Pressure"
                            value={bloodPressure}
                            onChange={(e) => setBloodPressure(e.target.value)}
                        />
                    </div>
                    <div className="vital-input">
                        <label>Temperature:</label>
                        <input
                            type="text"
                            placeholder="Temperature"
                            value={temperature}
                            onChange={(e) => setTemperature(e.target.value)}
                        />
                    </div>
                    <div className="vital-input">
                        <label> Capillary Oxygen Saturation:</label>
                        <input
                            type="text"
                            placeholder="SPo2"
                            value={spo2}
                            onChange={(e) => setSpo2(e.target.value)}
                        />
                    </div>
                    <div className="vital-input">
                        <label>Body Weight:</label>
                        <input
                            type="text"
                            placeholder="Body Weight"
                            value={bodyWeight}
                            onChange={(e) => setBodyWeight(e.target.value)}
                        />
                    </div>
                </div>
            </div>
             {/* Signs and Symptoms Section */}
             <div className="signs-symptoms-section textarea-container">
                <h2>Presenting Complaints</h2>
                <textarea
                    id="signs-and-symptoms"
                    rows="4"
                    value={signsAndSymptoms}
                    onChange={(e) => setSignsAndSymptoms(e.target.value)}
                    placeholder="Enter patient presenting complaints here here..."
                    style={{
                        fontSize: '20px', // Set the font size
                        fontFamily: 'Arial, sans-serif', // Set the font family
                        height: '150px', // Set the height
                    }}
                ></textarea>
                
                
                

{/*<button onClick={() => startRecognition('signs-and-symptoms')}>Start Speech Recognition</button>*/}
<div className="clinical-notes-section textarea-container">
    <h2>Clinical Notes</h2>
    <textarea
        id="clinical-notes"
        rows="6"
        value={clinicalNotes}
        onChange={(e) => setClinicalNotes(e.target.value)}
        placeholder="Enter clinical notes here..."
        style={{
            fontSize: '20px', // Set the font size
            fontFamily: 'Arial, sans-serif', // Set the font family
            height: '150px', // Set the height
        }}
    ></textarea>
</div>

              
{/*<button onClick={() => startRecognition('clinical-notes')}>Start Speech Recognition</button>*/}
            </div>
           
            <div className="tests-container">
    <div className="lab-tests-section">
        <h2>Make a Lab request</h2>
        <div className="lab-test-form">
            <select
                value={selectedLabTest}
                onChange={(e) => {
                    const test = e.target.value;
                    setSelectedLabTest(test);
                    if (test) {
                        addSelectedLabTest(test);
                        setSelectedLabTest(""); // Clear selection after adding
                    }
                }}
            >
                <option value="">Select Lab Test</option>
                {labTests.map((test, index) => (
                    <option key={index} value={test.name}>
                        {test.name} - UGX {test.price}
                    </option>
                ))}
            </select>
        </div>
        <div className="lab-test-list">
            {selectedLabTests.map((test, index) => (
                <div key={index} className="lab-test-item">
                    <span>{test}</span>
                    <button className="remove-button" onClick={() => removeSelectedLabTest(test)}>Remove</button>
                </div>
            ))}
        </div>
    </div>

    <div className="radiology-exams-section">
        <h2>Request For Ultrasound scan or Xray</h2>
        <div className="radiology-exam-form">
            <select
                value={selectedRadiologyExam}
                onChange={(e) => {
                    const exam = e.target.value;
                    setSelectedRadiologyExam(exam);
                    if (exam) {
                        addSelectedRadiologyExam(exam);
                        setSelectedRadiologyExam(""); // Clear selection after adding
                    }
                }}
            >
                <option value="">Select Radiology Exam</option>
                {radiologyExams.map((exam, index) => (
                    <option key={index} value={exam.name}>
                        {exam.name} - {exam.price}
                    </option>
                ))}
            </select>
        </div>
        <div className="radiology-exam-list">
            {selectedRadiologyExams.map((exam, index) => (
                <div key={index} className="radiology-exam-item">
                    <span>{exam.name} - {exam.price}</span>
                    <button className="remove-button" onClick={() => removeSelectedRadiologyExam(exam)}>Remove</button>
                </div>
            ))}
        </div>
    </div>



            </div>
           {/*} <div className="diagnosis-section textarea-container">
    <h2>Diagnosis</h2>
    <textarea
        ref={textareaRef}
        rows="6"
        value={diagnosisText}
        onChange={(e) => setDiagnosisText(e.target.value)}
        placeholder="Enter diagnosis here... or leave this field empty if you still need to wait for the investigation results"
        style={{
            fontSize: '18px', // Slightly reduced font size
            fontFamily: 'Arial, sans-serif',
            height: '150px',
            marginBottom: '8px', // Reduced margin below the textarea
        }}
    ></textarea>
    <Select
    options={diagnosisOptions}
    onChange={handleSelectChange}
    placeholder="Select diagnosis..."
    isClearable
    styles={customStyles}
    menuPlacement="top"
/>

</div>

            {/* Submit Button */}
            <button onClick={submitForm} disabled={submitting}>
            {submitting ? 'Submitting....' : 'Submit Patient File'}
        </button>
<footer className="footer2">
        This software was created by DeepMind E-Systems. For support or help contact +256786747733 
      </footer>
        </div>
        </div>
    );
}

export default DoctorsRoom;
