import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';

function DispensedSoldDrugs() {
  const [dispensedSoldData, setDispensedSoldData] = useState([]);
  const [message, setMessage] = useState('');
  const [searchDate, setSearchDate] = useState(new Date().toISOString().split('T')[0]);
  const [employeeName, setEmployeeName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Function to perform the security check
    const performSecurityCheck = async (token) => {
      try {
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
    
        if (securityResponse.ok) {
          const securityData = await securityResponse.json();
          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            return true; // Security check passed
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
            return false; // Security check failed due to session expired
          } else {
            navigate('/login');
            return false; // Security check failed
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
        return false; // Security check failed
      }
    };
    
    // Function to fetch dispensed and sold data
const fetchDispensedSoldData = async (token) => {
  try {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    const response = await fetch(`${urls.dispensedsold}?date=${searchDate}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token || tokenFromUrl }),
    });

    const data = await response.json();

    // Check if the response contains an error
    if (data.error) {
      if (data.error.includes('Incorrect DATE value')) {
        setMessage('Please insert the correct date format.');
      } else {
        setMessage(data.error); // Set other errors as they are
      }
    } else if (data.message) {
      setMessage(data.message);
    } else {
      setDispensedSoldData(data);
      setMessage(''); // Reset message if data is received
    }
  } catch (error) {
    console.error('Error fetching dispensed and sold drugs:', error);
  }
};

    // Extract token from URL or localStorage
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    const token = tokenFromUrl || localStorage.getItem('token');

    // Perform security check before fetching dispensed and sold data
    performSecurityCheck(token).then((securityPassed) => {
      if (securityPassed) {
        // If security check passed, fetch the dispensed and sold data
        fetchDispensedSoldData();
      }
    });
  }, [searchDate]); // Include searchDate in dependencies to refetch data when it changes
  
  const handleDateChange = (event) => {
    setSearchDate(event.target.value);
  };
  
  return (
    <div>
      <h1>History of Drugs sold over the counter</h1>
      <div>
        <label htmlFor="dateInput">Select Date:</label>
        <input
          type="date"
          id="dateInput"
          value={searchDate}
          onChange={handleDateChange}
        />
      </div>
      {message ? (
        <p>{message}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Drug</th>
              <th>Packaging</th>
              <th>Quantity Sold</th>
              <th>Sold By</th>
              <th>Sold At</th>
            </tr>
          </thead>
          <tbody>
            {dispensedSoldData.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.Drug}</td>
                <td>{item.Packaging}</td>
                <td>{item.Quantity}</td>
                <td>{item.SoldBy}</td>
                <td>{item.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default DispensedSoldDrugs;
