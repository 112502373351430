import React, { useState, useEffect, useRef } from 'react';
import { urls } from './config.dev';
import { ToastContainer, toast } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './TreatmentChatModal.css';

const cellStyle = {
    padding: '10px',
    border: '1px solid #ddd'
};

const drugNameCellStyle = {
    ...cellStyle,
    width: '40%' 
};

const drugQuantityCellStyle = {
    ...cellStyle,
    width: '10%' 
};

const nextDoseTimeCellStyle = {
    ...cellStyle,
    width: '25%' 
};


function TreatmentChatModal({ onClose, fileId, employeeName, token }) {
    const [treatmentPlanRows, setTreatmentPlanRows] = useState([{ drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' }]);
    const [drugSuggestions, setDrugSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [nextDose, setNextDose] = useState(''); // Changed to handle "File Closed"
    const [treatmentSentence, setTreatmentSentence] = useState('');
    const [nextDoseDate, setNextDoseDate] = useState(''); // Added state for next dose date
    const [nextDoseTime, setNextDoseTime] = useState(''); // Added state for next dose time
    const suggestionsRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedDrugIndex, setSelectedDrugIndex] = useState(null);
    const quantityRefs = useRef([]); 
    const [isScrolling, setIsScrolling] = useState(false);

const handleWheel = (e) => {
    setIsScrolling(true);
    e.preventDefault();
};

const handleWheelEnd = () => {
    setIsScrolling(false);
};

useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('wheel', handleWheelEnd, { passive: false });

    return () => {
        window.removeEventListener('wheel', handleWheel);
        window.removeEventListener('wheel', handleWheelEnd);
    };
}, []);
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        console.log('Token received:', token);
    }, [token]);

    useEffect(() => {
        const fetchDrugs = async () => {
            try {
                const response = await fetch(urls.fetchdispensary2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token })
                });

                if (!response.ok) throw new Error('Error fetching drugs');

                const data = await response.json();
                setDrugSuggestions(data);
            } catch (error) {
                console.error('Error fetching drugs:', error);
            }
        };
        fetchDrugs();
    }, [token]);

    useEffect(() => {
        const updatedSentence = constructTreatmentSentence();
        setTreatmentSentence(updatedSentence);
    }, [treatmentPlanRows, nextDose]);

    const handleDrugInputChange = (index, event) => {
        const value = event.target.value;
        
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = value;
        setTreatmentPlanRows(updatedRows);
    
        if (!value) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(null);
            return;
        }
    
        const filtered = drugSuggestions.filter(drug =>
            drug.Drug.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setActiveSuggestionIndex(index);
    };
    
    useEffect(() => {
        const updatedSentence = constructTreatmentSentence();
        setTreatmentSentence(updatedSentence);
    }, [treatmentPlanRows, nextDose, nextDoseDate, nextDoseTime]); // Added nextDoseDate and nextDoseTime as dependencies
    
    const handleSuggestionClick = (index, suggestion) => {
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = suggestion.Drug;
        updatedRows[index].packaging = suggestion.Packaging;
        updatedRows[index].quantity = '';
        setTreatmentPlanRows(updatedRows);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(null);
        setSelectedDrugIndex(index);

        // Focus on the quantity input of the selected row
        if (quantityRefs.current[index]) {
            quantityRefs.current[index].focus();
        }
    };
    

    const handleDrugQuantityChange = (index, e) => {
        const { value, key } = e.target;
        const newQuantity = parseInt(value, 10) || 0;
    
        // Update the quantity in the state
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].quantity = newQuantity;
        setTreatmentPlanRows(updatedRows);
    
        // If Enter key is pressed
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter key behavior (form submit)
    
            // Create a new row
            addNewRow();
            
            // Focus on the "Drug Name" input in the new row
            const nextIndex = index + 1;
            if (quantityRefs.current[nextIndex]) {
                const nextInput = quantityRefs.current[nextIndex].previousElementSibling; // Get the previous sibling (Drug Name input)
                if (nextInput) {
                    nextInput.focus(); // Focus on the Drug Name input
                }
            }
        }
    };
    
    const addNewRow = () => {
        const lastRow = treatmentPlanRows[treatmentPlanRows.length - 1];
        
        // Check if the current row's required fields are filled
        if (!lastRow.drug || !lastRow.quantity) {
            toast.error('Please fill out the drug and quantity fields before adding a new row.');
            return; // Prevent adding a new row if fields are incomplete
        }
        
        // Add a new row
        const newRow = { drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' };
        setTreatmentPlanRows([...treatmentPlanRows, newRow]);
    
        // Focus on the "Drug Name" input in the newly created row
        // Ensure that the new row index is correct
        const nextRowIndex = treatmentPlanRows.length;
        setTimeout(() => {
            const newDrugInput = document.querySelector(`.treatment-chat-table tr:nth-child(${nextRowIndex + 2}) .drug-chat-input`);
            if (newDrugInput) {
                newDrugInput.focus();
            }
        }, 0); // Use setTimeout to ensure the new row is rendered before trying to focus
    };
    

    const removeRow = (index) => {
        if (treatmentPlanRows.length > 1) {
            const updatedRows = treatmentPlanRows.filter((_, i) => i !== index);
            setTreatmentPlanRows(updatedRows);
        }
    };
    const handleTreatmentPlanSubmit = async () => {
        let errorMessage = '';
        
        // Show initial toast message to inform user
        toast.info(`Hello ${employeeName}, please wait, we are submitting your chat.`);
        
        // Wait for 2 seconds before proceeding with the rest of the function
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Check if next dose is selected
        if (!nextDose) {
            errorMessage = 'Please select the next dose option.';
        }
        
        // Check if all table cells are filled
        const incompleteRows = treatmentPlanRows.filter(row =>
            !row.drug || !row.quantity
        );
        
        if (incompleteRows.length > 0) {
            if (errorMessage) errorMessage += ' ';
            errorMessage += 'Some rows are incomplete. Please fill out all drug and quantity fields.';
        }
        
        if (errorMessage) {
            toast.error(errorMessage);
            return;
        }
        
        setSubmitting(true);
        
        const payload = {
            fileId,
            treatment_plan: treatmentSentence,
            table_data: treatmentPlanRows,
            employee_name: employeeName
        };
        
        try {
            const response = await fetch(urls.submitchat, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
    
            const data = await response.json(); // Parse JSON body
            
            if (!response.ok) {
                // If response is not ok, throw the error message from the response
                const backendErrorMessage = data.error || 'Something went wrong.';
                // Handle detailed error message if provided
                const details = data.details ? data.details.map(detail => 
                    `Drug: ${detail.drug}, Packaging: ${detail.packaging}, Requested: ${detail.requested}, Available: ${detail.available}, Shortfall: ${detail.shortfall}`
                ).join('\n') : '';
                
                throw new Error(`${backendErrorMessage}\n\nDetails:\n${details}`);
            }
            
            if (data.success) {
                // Wait for 2 seconds before showing success toast
                setTimeout(() => {
                    toast.success('Success! Treatment plan submitted.');
                    // Delay closing the modal by 5 seconds
                    setTimeout(() => {
                        onClose();
                    }, 5000);
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting treatment plan:', error);
            // Wait for 2 seconds before showing the actual error from the backend
            setTimeout(() => {
                toast.error(`Error: ${error.message}`);
            }, 2000);
        } finally {
            setSubmitting(false);
        }
    };
    
    const constructTreatmentSentence = () => {
        const drugsList = treatmentPlanRows.map(row =>
            `${row.quantity} ${row.packaging} of ${row.drug}`
        ).join(', ');
    
        const currentDate = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = currentDate.toLocaleDateString('en-US', options);
        const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    
        let nextDoseSentence = '';
    
        if (nextDose === 'File Closed') {
            nextDoseSentence = 'File Closed';
        } else if (nextDose === 'On a specific date and time') {
            // Log the values of nextDoseDate and nextDoseTime
            console.log('Next Dose Date:', nextDoseDate);
            console.log('Next Dose Time:', nextDoseTime);
    
            // Check if both date and time are provided
            if (nextDoseDate && nextDoseTime) {
                // Create a combined Date object
                const nextDoseDateTime = new Date(`${nextDoseDate}T${nextDoseTime}`);
                // Check if the date object is valid
                if (isNaN(nextDoseDateTime.getTime())) {
                    console.error('Invalid Date:', nextDoseDateTime);
                    nextDoseSentence = 'Invalid date and time provided';
                } else {
                    const nextDoseFormattedDate = nextDoseDateTime.toLocaleDateString('en-US', options);
                    const nextDoseFormattedTime = nextDoseDateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                    nextDoseSentence = `on ${nextDoseFormattedDate} at ${nextDoseFormattedTime}`;
                }
            } else {
                nextDoseSentence = 'on a specified date and time (date or time missing)';
            }
        }
    
        return `Date: ${formattedDate} ${formattedTime}\n${employeeName} administered ${drugsList}.\nNext dose is ${nextDoseSentence}.`;
    };
    
    

    return (
        <div className="treatment-chat-container">
             <ToastContainer />
            <h3 className="treatment-chat-header">Treatment Chat</h3>
            <div>
                
  <p style={{ fontSize: 'small', color: 'red', marginBottom: '13px' }}>
    Please chat everything you have given to this patient, however small, be it a syringe or cannula, to enables the system to make more accurate calculations.
  </p>
  </div>
            <table className="treatment-chat-table">
                <thead>
                    <tr>
                        <th>Drug Name</th>
                        <th>Packaging</th>
                        <th>Quantity</th>
                        <th>Actions</th> {/* Added a column for actions */}
                    </tr>
                </thead>
                <tbody>
    {treatmentPlanRows.map((row, index) => {
        // Determine if the row's drug is selected
        const isDrugSelected = index === selectedDrugIndex;

        // Inline styles for the quantity input
        const quantityInputStyle = {
            pointerEvents: isDrugSelected ? 'auto' : 'none',
            opacity: isDrugSelected ? 1 : 0.8,
        };

        // Prevent default wheel event
        const handleWheel = (e) => e.preventDefault();

        return (
            <tr key={index}>
                <td style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="drug-chat-input"
                        value={row.drug}
                        onChange={e => handleDrugInputChange(index, e)}
                        onFocus={() => setShowSuggestions(true)}
                    />
                    {index === activeSuggestionIndex && filteredSuggestions.length > 0 && showSuggestions && (
                        <div className="drug-chat-suggestions-container" ref={suggestionsRef}>
                            <ul className="drug-chat-suggestions-list">
                                {filteredSuggestions.map((suggestion, i) => (
                                    <li key={i} onClick={() => handleSuggestionClick(index, suggestion)}>
                                        {suggestion.Drug} - {suggestion.Packaging} (Available: {suggestion.Quantity})
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </td>
                <td>{row.packaging}</td>
                <td>
                    <input
                        type="number"
                        className={`quantity-input ${isScrolling ? 'scrolling' : ''}`}
                        value={row.quantity}
                        onChange={e => handleDrugQuantityChange(index, e)}
                        onKeyDown={e => handleDrugQuantityChange(index, e)} // Ensure this handler is properly implemented
                        style={quantityInputStyle} // Apply inline styles
                        ref={el => quantityRefs.current[index] = el} // Assign ref
                       
                    />
                </td>
                <td>
                    <button
                        type="button"
                        className="add-row-button"
                        onClick={addNewRow}
                        disabled={treatmentPlanRows.length >= 10} // Optional limit for rows
                    >
                        +
                    </button>
                    <button
                        type="button"
                        className="remove-row-button"
                        onClick={() => removeRow(index)}
                    >
                        x
                    </button>
                </td>
            </tr>
        );
    })}
</tbody>


            </table>

            <div className="next-dose-container">
    <h3>Please select the time for  next dose or close this file</h3>
    <label>
        <input
            type="radio"
            name="nextDose"
            value="On a specific date and time"
            checked={nextDose === 'On a specific date and time'}
            onChange={() => setNextDose('On a specific date and time')}
        />
        On a specific date and time
    </label>
    {nextDose === 'On a specific date and time' && (
        <div className="date-time-inputs">
            <label>
                Date:
                <input
                    type="date"
                    value={nextDoseDate}
                    onChange={(e) => setNextDoseDate(e.target.value)}
                />
            </label>
            <label>
                Time:
                <input
                    type="time"
                    value={nextDoseTime}
                    onChange={(e) => setNextDoseTime(e.target.value)}
                />
            </label>
        </div>
    )}
    <label>
        <input
            type="radio"
            name="nextDose"
            value="File Closed"
            checked={nextDose === 'File Closed'}
            onChange={() => setNextDose('File Closed')}
        />
        File Closed
    </label>
</div>


            <div className="treatment-chat-preview">
                <h4>Preview</h4>
                <pre>{treatmentSentence}</pre>
            </div>

            <div className="submit-container">
                <button
                    type="button"
                    onClick={handleTreatmentPlanSubmit}
                    disabled={submitting}
                    className="treatment-chat-submit-button"
                >
                    {submitting ? 'Submitting...' : 'Submit Chat'}
                </button>
                <button type="button" className="treatment-chat-close-button" onClick={onClose}>
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default TreatmentChatModal;