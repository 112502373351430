import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Sales.css';
import { API_URL, urls } from './config.dev';

function SalesDetails() {
  const { employee } = useParams();
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedShift, setSelectedShift] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [showSalesTable, setShowSalesTable] = useState(true);
  const [newSale, setNewSale] = useState({ amount: 0, reason: '', servedBy: employee });
  const [newExpense, setNewExpense] = useState({ amount: 0, details: '', takenBy: '', servedBy: employee });
  const [totalSales, setTotalSales] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [cashAtHand, setCashAtHand] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState('');



  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchShiftData();
  }, []);

  useEffect(() => {
    if (selectedShift) {
      fetchSalesData(selectedShift);
      fetchExpensesData(selectedShift);
    }
  }, [selectedShift]);

  useEffect(() => {
    const salesTotal = salesData.reduce((total, sale) => total + parseFloat(sale.amount), 0);
    setTotalSales(salesTotal);

    const expensesTotal = expensesData.reduce((total, expense) => total + parseFloat(expense.amount), 0);
    setTotalExpenses(expensesTotal);

    const cashAtHand = salesTotal - expensesTotal;
    setCashAtHand(cashAtHand);
  }, [salesData, expensesData]);

  const fetchShiftData = (tokenFromUrl) => {
    fetch(urls.checkshift, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.shiftType) {
          setSelectedShift(data.shiftType);
        } else {
          throw new Error('Failed to fetch selected shift');
        }
      })
      .catch(error => {
        console.error('Error fetching selected shift:', error);
      });
  };

  const fetchSalesData = (shift, date = selectedDate) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      if (!tokenFromUrl) {
        console.error('Token not found in URL');
        return;
      }
  
      const queryParams = `date=${date}&shift=${shift}&token=${tokenFromUrl}`;
      const url = `${API_URL}/fetchsales2.php?${queryParams}`;
      setIsLoading(true); // Set loading state to true
  
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('No sales data found');
            } else {
              throw new Error('Failed to fetch sales data');
            }
          }
          return response.json();
        })
        .then(data => {
          if (data.length === 0) {
            setSalesData([]); // Clear sales data if no data found
            setComments('No sales data found for the provided date, shift, and clinic.');
          } else {
            // Map the response data to include only required fields
            const formattedData = data.map(sale => ({
              amount: sale.Amount,
              reason: sale.Reason,
              servedBy: sale.ServedBy,
              id: sale.SaleID,
              comments: sale.Comments || null, // Ensure comments are null if undefined
            }));
  
            setSalesData(formattedData); // Update sales data
  
            // Set comments based on the first non-null comment found
            const comment = formattedData.find(sale => sale.comments !== null)?.comments || '';
            setComments(comment !== '' ? comment : 'No comments for this shift yet');
          }
  
          setIsLoading(false); // Set loading state to false
        })
        .catch(error => {
          console.error('Error fetching sales data:', error);
          setSalesData([]); // Clear sales data in case of error or no data found
          setComments('No sales data found for the provided date, shift, and clinic.');
          setIsLoading(false); // Set loading state to false in case of error
        });
    } catch (error) {
      console.error('Error fetching sales data:', error);
      setSalesData([]); // Clear sales data in case of error
      setComments('No sales data found for the provided date, shift, and clinic.');
      setIsLoading(false); // Set loading state to false in case of error
    }
  };
  const fetchExpensesData = (shift, date = selectedDate) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      if (!tokenFromUrl) {
        console.error('Token not found in URL');
        return;
      }
  
      const queryParams = `date=${date}&shift=${shift}&token=${tokenFromUrl}`;
      const url = `${urls.fetchexpenses2}?${queryParams}`;
      setIsLoading(true); // Set loading state to true
  
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('No expenses data found');
            } else {
              throw new Error('Failed to fetch expenses data');
            }
          }
          return response.json();
        })
        .then(data => {
          if (Array.isArray(data) && data.length === 0) {
            setExpensesData([]); // Clear expenses data if no data found
            setIsLoading(false); // Set loading state to false
          } else if (Array.isArray(data)) {
            // Filter out the required components from the response data
            const formattedData = data.map(expense => ({
              amount: expense.Amount,
              details: expense.Details,
              takenBy: expense.TakenBy,
              servedBy: expense.ServedBy,
              id: expense.ExpenseID
            }));
  
            setExpensesData(formattedData);
            setIsLoading(false); // Set loading state to false
          } else {
            // Handle unexpected response format
            console.error('Error fetching expenses data:', data);
            setIsLoading(false); // Set loading state to false
          }
        })
        .catch(error => {
          console.error('Error fetching expenses data:', error);
          setExpensesData([]); // Clear expenses data in case of error or no data found
          setIsLoading(false); // Set loading state to false in case of error
        });
    } catch (error) {
      console.error('Error fetching expenses data:', error);
      setExpensesData([]); // Clear expenses data in case of error
      setIsLoading(false); // Set loading state to false in case of error
    }
  };
    
  

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const handleFetchData = () => {
    fetchSalesData(selectedShift, selectedDate);
    fetchExpensesData(selectedShift, selectedDate);
  };
  return (
    <div className="sales-container">
      <div className="header">
        <div className="employee-name">{employee}</div>
        <div className="current-time">
          {currentTime.toLocaleString('en-US', { timeZone: 'Africa/Kampala' })}
        </div>
      </div>

      {/* Date and shift selection */}
      <div className="filter-container">
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
        <select value={selectedShift} onChange={handleShiftChange} className="shift-selection">
          <option value="">Please Select the  Shift</option>
          <option value="Day">Day</option>
          <option value="Night">Night</option>
        </select>
        <button onClick={handleFetchData} disabled={isLoading}>
          {isLoading ? 'Fetching Data...' : 'Fetch Data'}
        </button>
      </div>

      {/* Display loading message */}
      {isLoading && <div className="loading-message">Fetching sales and expenses, please wait...</div>}

      {/* Table rendering */}
      {selectedShift && !isLoading && (
        <>
          <div className="toggle-button">
            <button onClick={() => setShowSalesTable(!showSalesTable)}>
              {showSalesTable ? 'Switch to Expenses' : 'Switch to Sales'}
            </button>
          </div>
  
          {showSalesTable ? (
            <div className="sales-table">
           <div
  className="sales-table-header"
  style={{
    fontSize: '36px', // Adjust font size as needed
    fontWeight: 'bold',
    color: '#333',
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderBottom: '2px solid #ddd',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center', // Center the text
    width: '100%' // Ensure the header spans the full width of the container
  }}
>
  {selectedShift} Shift - Sales
</div>

              {/* Sales table */}
              <table>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Served By</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Display sales data */}
                  {salesData.map((sale, index) => (
                    <tr key={index}>
                      <td>{sale.amount}</td>
                      <td>{sale.reason}</td>
                      <td>{sale.servedBy}</td>
                    </tr>
                  ))}
                  {/* Empty row for adding new sale */}
                  <tr>
                    <td>
                      <input 
                        type="text" 
                        className="yellow-border" 
                        style={{ width: '80px', height: '30px', fontSize: '16px' }} 
                        value={newSale.amount} 
                        onChange={(e) => setNewSale({ ...newSale, amount: e.target.value })} 
                        inputMode="numeric" 
                        pattern="[0-9]*" 
                      />
                    </td>
                    <td>
                      <input 
                        type="text" 
                        className="yellow-border" 
                        style={{ width: '150px', height: '30px', fontSize: '16px' }} 
                        value={newSale.reason} 
                        onChange={(e) => setNewSale({ ...newSale, reason: e.target.value })} 
                      />
                    </td>
                    <td>{employee}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            /* Display expenses table */
            <div className="expenses-table">
               <div
  className="expenses-table-header"
  style={{
    fontSize: '36px', // Adjust font size as needed
    fontWeight: 'bold',
    color: '#333',
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderBottom: '2px solid #ddd',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center', // Center the text
    width: '100%' // Ensure the header spans the full width of the container
  }}
>
  {selectedShift} Shift - Expenses
</div>
              {/* Expenses table */}
              <table>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Taken By</th>
                    <th>Served By</th>
                  </tr>
                </thead>
                <tbody>
                  {expensesData.map((expense, index) => (
                    <tr key={index}>
                      <td>{expense.amount}</td>
                      <td>{expense.details}</td>
                      <td>{expense.takenBy}</td>
                      <td>{expense.servedBy}</td>
                    </tr>
                  ))}
                  {/* Empty row for adding new expense */}
                  <tr>
                    <td>
                      <input 
                        type="text" 
                        className="yellow-border" 
                        style={{ width: '80px', height: '30px', fontSize: '16px' }} 
                        value={newExpense.amount} 
                        onChange={(e) => setNewExpense({ ...newExpense, amount: e.target.value })} 
                        inputMode="numeric" 
                        pattern="[0-9]*" 
                      />
                    </td>
                    <td>
                      <input 
                        type="text" 
                        className="yellow-border" 
                        style={{ width: '150px', height: '30px', fontSize: '16px' }} 
                        value={newExpense.details} 
                        onChange={(e) => setNewExpense({ ...newExpense, details: e.target.value })} 
                      />
                    </td>
                    <td>
                      <input 
                        type="text" 
                        className="yellow-border" 
                        style={{ width: '120px', height: '30px', fontSize: '16px' }} 
                        value={newExpense.takenBy} 
                        onChange={(e) => setNewExpense({ ...newExpense, takenBy: e.target.value })} 
                      />
                    </td>
                    <td>{employee}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      {/* Totals section */}
      <div className="totals">
        <div>Total Sales: {totalSales}</div>
        <div>Total Expenses: {totalExpenses}</div>
        <div>Cash at Hand: {cashAtHand}</div>
      </div>

      {/* Comments section */}
      <div className="comments-section">
        <h3>Comments for {selectedShift} Shift</h3>
        <div className="comments">
          {comments !== '' ? (
            <p>{comments}</p>
          ) : (
            <p>No comments for this shift yet</p>
          )}
        </div>
        <footer className="footer2">
            This system was created by DeepMind E-Systems. For support or help contact +256786747733
          </footer>
      </div>
    </div>
  );
};

export default SalesDetails;