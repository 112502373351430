import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUs.css';

function AboutUs() {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/clinic-registration');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className="about-us-container">
      <h1>About Clinic Pro</h1>
      <p>
        Welcome to Clinic Pro, your trusted partner in healthcare management. We are a cutting-edge web application designed to streamline the day-to-day operations of clinics, ensuring that healthcare professionals can focus on what they do best – providing top-notch care to their patients.
      </p>

      <h2>Our Mission</h2>
      <p>
        At Clinic Pro, our mission is to empower clinics with the tools they need to manage their operations efficiently and effectively. By integrating all aspects of clinic management into one seamless platform, we help healthcare providers reduce administrative burdens, improve patient care, and enhance overall clinic performance.
      </p>

      <h2>What We Offer</h2>
      <ul>
        <li><strong>Doctor-Lab-Radiology Integration:</strong> Facilitate online lab and radiology requests directly from doctors, streamlining the diagnostic process and reducing wait times for patients.</li>
        <li><strong>Comprehensive Patient Management:</strong> Maintain detailed records of patient information, including medical history, appointment scheduling, and billing information, all in one place.</li>
        <li><strong>Credit Management:</strong> Keep track of patient credits and manage payments with ease, ensuring financial transparency and accuracy.</li>
        <li><strong>Inventory & Sales Management:</strong> Monitor your clinic's inventory levels, track sales history, and manage expenses to keep your operations running smoothly.</li>
        <li><strong>Clinic Statistics:</strong> Gain insights into clinic performance with real-time statistics and analytics, helping you make informed decisions for your practice.</li>
        <li><strong>Appointment Scheduling:</strong> Streamline the process of booking, managing, and tracking patient appointments, reducing no-shows and optimizing your clinic's schedule.</li>
      </ul>

      <h2>Why Choose Clinic Pro?</h2>
      <p>
        Clinic Pro is more than just a software solution; it’s a commitment to excellence in healthcare management. Our platform is designed with the needs of modern clinics in mind, offering a user-friendly interface, powerful features, and the flexibility to adapt to the unique requirements of your practice.
      </p>

      <h2>Contact Us</h2>
      <p>
        Ready to take your clinic to the next level? Contact us today to learn more about how Clinic Pro can help you streamline your operations and improve patient care.
      </p>
      <p>
        <strong>Email:</strong> support@clinicpro.com<br />
        <strong>Phone:</strong> +256703521101<br />
        <strong>Address:</strong> Kampala  Uganda
      </p>

      <div className="about-us-actions">
        <button onClick={handleRegisterClick} className="about-us-button">Register now</button>
        <button onClick={handleLoginClick} className="about-us-button">Login</button>
      </div>
    </div>
  );
}

export default AboutUs;
