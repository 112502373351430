import React, { useState } from 'react';
import './TestResultModal.css';
import { urls } from './config.dev';
import { toast } from 'react-toastify';

function TestResultModal({ patient, clinicDetails, token, onClose, onSubmit, totalLabTests, testsToBeDone = [] }) {
  const [results, setResults] = useState('');
  const [formatting, setFormatting] = useState({
    bold: false,
    italic: false,
    underlined: false,
    fontSize: '14px' // Default font size
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // State to track errors

  const handleInputChange = (event) => {
    setResults(event.target.value);
  };

  const toggleFormatting = (format) => {
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      [format]: !prevFormatting[format]
    }));
  };

  const handleFontSizeChange = (event) => {
    const fontSize = event.target.value;
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      fontSize: fontSize
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(urls.submitlabresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          file_id: patient.file_id,
          contact_id: patient.contact_id,
          results: results,
          formatting: formatting, // Include formatting information
          totalLabTests: totalLabTests, // Include lab test count
          token: token // Include the token
        })
      });
      if (response.ok) {
        onSubmit(); // Call the onSubmit function passed as a prop
        console.log('Lab results submitted successfully.');
        
        // Prompt the user to print the report
        if (window.confirm('Do you want to proceed and print the lab report?')) {
          handlePrint();
        }
      } else {
        throw new Error('Failed to submit lab results');
      }
    } catch (error) {
      console.error('Error submitting lab results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async () => {
    try {
      // Construct the payload with the necessary data
      const printData = {
        clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
        contact: clinicDetails?.owners_contact || 'No Contact Available',
        location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
        patientName: `${patient.first_name} ${patient.last_name}`,
        patientAge: patient.age,
        patientSex: patient.sex,
        laboratoryTests: patient.lab_tests, // Directly use the lab_tests from patient details
        laboratoryResults: results.trim().split('\n').map(result => result.trim()), // Format results
      };
  
      console.log('Print data:', printData);
  
      const response = await fetch(urls.pdflab2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(printData)
      });
  
      if (!response.ok) {
        throw new Error(`Failed to generate PDF: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank'); // Open PDF in a new tab
      toast.success('PDF report generated successfully!');
    } catch (error) {
      setError("Failed to generate PDF");
      console.error("PDF generation error:", error);
      toast.error('Failed to generate PDF report');
    }
  };

  return (
    <div className="modal-overlay">
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(8px);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-content {
            background: white;
            width: 80%;
            max-width: 800px;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            position: relative;
            overflow-y: auto;
            max-height: 90vh;
            box-sizing: border-box;
          }

          .header {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 20px;
          }

          .patient-details, .input-area, .formatting-buttons {
            margin-bottom: 20px;
          }

          .results-input {
            width: 100%;
            min-height: 150px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            resize: vertical;
          }

          .results-input.bold {
            font-weight: bold;
          }

          .results-input.italic {
            font-style: italic;
          }

          .results-input.underlined {
            text-decoration: underline;
          }

          .formatting-buttons button {
            margin-right: 10px;
            padding: 5px 10px;
            border: none;
            cursor: pointer;
            border-radius: 4px;
            background-color: #f0f0f0;
          }

          .formatting-buttons button.active {
            background-color: #007bff;
            color: white;
          }

          .button-area {
            display: flex;
            justify-content: space-between;
          }

          .button-area button {
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            transition: background-color 0.3s ease;
          }

          .button-area button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }

          .button-area button:hover:enabled {
            background-color: #0056b3;
          }

          .button-area button.cancel-button {
            background-color: #dc3545;
          }
        `}
      </style>
      <div className="modal-content">
        <div className="header">
          <h1>{clinicDetails?.clinic_name || 'Clinic Name Unavailable'}</h1>
          <p>{clinicDetails?.sub_county}, {clinicDetails?.district}</p>
          <h2>Laboratory Results</h2>
        </div>
        <div className="patient-details">
          <h3>Patient Details</h3>
          <p><strong>Name:</strong> {`${patient.first_name} ${patient.last_name}`}</p>
          <p><strong>Age:</strong> {patient.age}</p>
          <p><strong>Sex:</strong> {patient.sex}</p>
        </div>
        
        <div className="formatting-buttons">
          <button className={formatting.bold ? 'active' : ''} onClick={() => toggleFormatting('bold')}>Bold</button>
          <button className={formatting.italic ? 'active' : ''} onClick={() => toggleFormatting('italic')}>Italic</button>
          <button className={formatting.underlined ? 'active' : ''} onClick={() => toggleFormatting('underlined')}>Underline</button>
          <select onChange={handleFontSizeChange} value={formatting.fontSize}>
            <option value="12px">Font Size: 12</option>
            <option value="14px">Font Size: 14</option>
            <option value="16px">Font Size: 16</option>
            <option value="18px">Font Size: 18</option>
            <option value="20px">Font Size: 20</option>
          </select>
        </div>
        <div className="input-area">
          <h3>Enter Results</h3>
          <textarea
            className={`results-input ${formatting.bold ? 'bold' : ''} ${formatting.italic ? 'italic' : ''} ${formatting.underlined ? 'underlined' : ''}`}
            value={results}
            onChange={handleInputChange}
            style={{ fontSize: formatting.fontSize }}
          />
        </div>
        <div className="button-area">
          <button onClick={handleSubmit} disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Results'}
          </button>
          <button onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default TestResultModal;
