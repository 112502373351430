import React from 'react';
import './DischargeForm.css';

function DischargeForm() {

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="discharge-form-container">
            <h1 className="centered-heading">FOREVER NURSING HOME SSUMBWE</h1>
            <h2 className="discharge-heading">Discharge Form</h2>

           
         

            <h3>Patient Details</h3>
            <form className="patient-details-form">
                <div className="patient-detail">
                    <label htmlFor="first_name">First Name:</label>
                    <input type="text" id="first_name" name="first_name" />
                </div>

                <div className="patient-detail">
                    <label htmlFor="last_name">Last Name:</label>
                    <input type="text" id="last_name" name="last_name" />
                </div>

                <div className="patient-detail">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input type="text" id="phone_number" name="phone_number" />
                </div>

                <div className="patient-detail">
                    <label htmlFor="age">Age:</label>
                    <input type="text" id="age" name="age" />
                </div>

                <div className="patient-detail">
                    <label htmlFor="sex">Sex:</label>
                    <input type="text" id="sex" name="sex" />
                </div>

                <label htmlFor="signs_and_symptoms">Summary of Signs and Symptoms:</label>
                <textarea id="signs_and_symptoms" name="signs_and_symptoms" />

                <h2>Summary of Investigations</h2>
                <label htmlFor="lab_tests">Lab Tests:</label>
                <input type="text" id="lab_tests" name="lab_tests" />

                <label htmlFor="radiology_exams">Radiology Exams:</label>
                <input type="text" id="radiology_exams" name="radiology_exams" />

                <h2>Diagnosis</h2>
                <label htmlFor="primary_diagnosis">Primary Diagnosis:</label>
                <textarea id="primary_diagnosis" name="primary_diagnosis" />

                <label htmlFor="secondary_diagnosis">Secondary Diagnosis:</label>
                <textarea id="secondary_diagnosis" name="secondary_diagnosis" />

                <h2>Discharge Summary</h2>
                <label htmlFor="date_of_admission">Date of Admission:</label>
                <input type="date" id="date_of_admission" name="date_of_admission" />

                <label htmlFor="date_of_discharge">Date of Discharge:</label>
                <input type="date" id="date_of_discharge" name="date_of_discharge" />

                <label htmlFor="treatment_on_admission">Summary of Treatment on Admission:</label>
                <textarea id="treatment_on_admission" name="treatment_on_admission" />

                <label htmlFor="treatment_on_discharge">Summary of Treatment on Discharge:</label>
                <textarea id="treatment_on_discharge" name="treatment_on_discharge" />

                <label htmlFor="condition_on_discharge">Condition on Discharge:</label>
                <textarea id="condition_on_discharge" name="condition_on_discharge" />
                
                <div className="doctor-signature">
                    <label htmlFor="doctor_signature">Doctor/Nurse's Signature:</label>
                    <input type="text" id="doctor_signature" name="doctor_signature" />
                </div>
            </form>

            <button onClick={handlePrint}>Print</button>
        </div>
    );
}

export default DischargeForm;
