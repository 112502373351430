import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faPills, faShoppingCart, faClipboardList, faChartBar, faUser, faBalanceScale, faSpinner, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal'; // Import your Modal component
import JSEncrypt from 'jsencrypt'; // Ensure you have installed js-encrypt
import { urls } from './config.dev';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'; 
import './SideBar.css';

function Sidebar({ onSelectOption, navigate, setShowAdminModal, publicKey, selectedEmployee, token }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [action, setAction] = useState('');
  const [currentBalance, setCurrentBalance] = useState(0); // State for current balance
  
  

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        if (!token) {
          console.error('Token is missing!');
          return;
        }

        setLoading(true); // Start loading

        const response = await fetch(urls.fetchbalance, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }) // Send token in the request body
        });

        if (response.ok) {
          const data = await response.json();
          setCurrentBalance(data.current_balance); // Assuming the backend response has 'current_balance' field
        } else {
          throw new Error('Failed to fetch current balance');
        }
      } catch (error) {
        console.error('Error fetching current balance:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchBalance();
  }, [token]);


  const handleButtonClick = async (action = null, navPath = null) => {
    if (action) {
      setAction(action);
      setShowModal(true);
    }
    if (navPath) {
      // Check if navPath includes '/generate-drug-order' and append token if true
      if (navPath.includes('/generate-drug-order')) {
        const params = new URLSearchParams();
        params.append('token', token);
        navigate(`${navPath}?${params.toString()}`);
      } else {
        navigate(navPath);
      }
    }
  };

  const handleAdminButtonClick = () => {
    setShowAdminModal(true);
    console.log('Navigating to admin dashboard...');
    // Replace with actual implementation as needed
  };

  const confirmAction = async () => {
    try {
        setLoading(true); // Set loading state to true when the button is clicked

        // Encrypt the security code with the public key
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptedSecurityCode = encrypt.encrypt(securityCode);

        // Fetch token from URL parameter
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        // First request to check security code
        const response = await fetch(urls.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                employee: selectedEmployee,
                action: action,
                securityCode: encryptedSecurityCode, // Send the encrypted security code
                token: token // Include token
            }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.result === 'yes') {
                // Second request to get permit
                const permitResponse = await fetch(urls.permit, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        employee: selectedEmployee,
                        action: action,
                        token: token // Include token
                    }),
                });

                if (permitResponse.ok) {
                    const permitData = await permitResponse.json();
                    if (permitData.result === 'yes') {
                        // Permission granted, navigate to corresponding page with login token
                        const loginToken = permitData.login_token;
                        navigate(`/${action}/?token=${loginToken}`);
                    } else if (permitData.redirectUrl) {
                        // Subscription expired, redirect to payment
                        const confirmed = window.confirm('Sorry, your subscription expired. Please press OK to proceed and make a payment.');

                        if (confirmed) {
                            window.location.href = permitData.redirectUrl; // Redirect to the received URL
                        }
                    } else {
                        // Permission not granted
                        toast.warning('Permission not granted. Please contact the administrator.');
                    }
                } else {
                    throw new Error('Failed to get permit response');
                }
            } else {
                // data.result is not 'yes', handle invalid security code
                toast.error('Invalid security code. Please try again.');
            }
        } else {
            throw new Error('Failed to check security code');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again later.');
    } finally {
        setLoading(false); // Reset loading state after the action is completed
    }
};

  const handleCloseModal = () => {
    setShowModal(false);
    setSecurityCode('');
  };


  const getBalanceButtonClass = () => {
    if (currentBalance <= 30000) {
      return 'blink';
    }
    return '';
  };
  
   // Determine the style for the balance button based on the balance or loading state
   const getBalanceButtonStyle = () => {
    if (loading) {
      return { backgroundColor: 'green' }; // Green color while loading
    } else if (currentBalance !== null && currentBalance > 30001 && currentBalance <= 100000) {
      return { backgroundColor: 'orange' }; // Orange for balance between 30001 and 100000
    } else if (currentBalance !== null && currentBalance > 100000) {
      return { backgroundColor: 'green' }; // Green for balance above 100000
    }
    return {};
  };
  return (
    <div className="sidebar">
      <h2 className="sidebar-heading">Menu</h2>
  {/* Clinic Pro Billing Section */}
  <h3 className="sidebar-subheading">Clinic Pro Billing</h3>
  <button
        className={`sidebar-button ${getBalanceButtonClass()}`}
        style={getBalanceButtonStyle()}
        disabled={loading} // Disable button while loading
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin className="sidebar-icon" />
        ) : (
          <FontAwesomeIcon icon={faBalanceScale} className="sidebar-icon" />
        )}
        <span className="sidebar-text">
          {loading ? 'Loading...' : `Current Balance - UGX ${currentBalance}`}
        </span>
      </button>
      {/*<button className="sidebar-button" onClick={() => handleButtonClick('paymentHistory')}>
        <FontAwesomeIcon icon={faClipboardList} className="sidebar-icon" /> <span className="sidebar-text">Previous Payments History</span>
      </button>*/}
      <button className="sidebar-button" onClick={() => handleButtonClick('makePayment')}>
        <FontAwesomeIcon icon={faCreditCard} className="sidebar-icon" /> <span className="sidebar-text">Make a Payment</span>
      </button>
      <hr className="sidebar-divider" />
      {/* Admin Dashboard Section */}
      <button className="sidebar-button" onClick={handleAdminButtonClick}>
        <FontAwesomeIcon icon={faTachometerAlt} className="sidebar-icon" /> <span className="sidebar-text">Settings(admin only)</span>
      </button>

      <hr className="sidebar-divider" />

      {/* Drugs Section */}
      <h3 className="sidebar-subheading">Drugs</h3>
      <button className="sidebar-button" onClick={() => handleButtonClick('manageDrugs')}>
        <FontAwesomeIcon icon={faPills} className="sidebar-icon" /> <span className="sidebar-text">Manage Drugs Settings</span>
      </button>
      <button className="sidebar-button" onClick={() => handleButtonClick('makeOrderForDrugs')}>
  <FontAwesomeIcon icon={faShoppingCart} className="sidebar-icon" /> <span className="sidebar-text">Drugs Re-Stock Suggestions</span>
</button>


      <button className="sidebar-button" onClick={() => handleButtonClick('manageLaboratory')}>
        <FontAwesomeIcon icon={faClipboardList} className="sidebar-icon" /> <span className="sidebar-text">Manage Scope of Laboratory and Radiology Exams</span>
      </button>

      <hr className="sidebar-divider" />

      {/* Disease Statistics Section */}
      <h3 className="sidebar-subheading">Disease Statistics</h3>
      <button className="sidebar-button" onClick={() => handleButtonClick('malariaBarGraph')}>
        <FontAwesomeIcon icon={faChartBar} className="sidebar-icon" /> <span className="sidebar-text">Malaria Cases Bar Graph</span>
      </button>

      <hr className="sidebar-divider" />

      {/* Employee Participation Section */}
      <h3 className="sidebar-subheading">See Employee Participation</h3>
      <button className="sidebar-button-large" onClick={() => handleButtonClick('employeePerformance')}>
        <FontAwesomeIcon icon={faUser} className="sidebar-icon" /> <span className="sidebar-text">See Each Employee Performance</span>
      </button>

      <hr className="sidebar-divider" />

      {/* Sales Statistics and History Section */}
      <h3 className="sidebar-subheading">Sales Statistics and History</h3>
      <button className="sidebar-button" onClick={() => handleButtonClick('clinicStatistics')}>
        Clinic Statistics
      </button>
      <button className="sidebar-button" onClick={() => handleButtonClick('salesDetails')}>
       Sales history in detail
      </button>
      

      <hr className="sidebar-divider" />

    
      {/* Modal for security code */}
      {showModal && (
        <Modal onClose={handleCloseModal}>
          <input
            type="password"
            placeholder="Enter security code"
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
          />
          <button onClick={confirmAction}>
            {loading ? 'Please wait...' : 'Proceed'}
          </button>
        </Modal>
      )}
    </div>
  );
}

export default Sidebar;
